import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';
import { HttpService } from './invoice-check/http/http.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = '发票检验';
    enum: {
        'xs', 'sm', 'md', 'lg', 'xl', 'xxl'
    }
    cyrObj = {
        "version": "1.0",
        "nsrsbh": "",
        "user_name": "",
        "nsrmc": "",
        "orgid": ""
    };
    ifAdmin = false
    constructor(private modalService: NzModalService, private httpService: HttpService) { }
    ngOnInit() {
        // let date = this.dateFormat(new Date(), 'yyyy-MM-dd');
        // if (date >= '2019-10-25' && date < '2019-11-01') {
        //   this.modalService.warning({
        //     nzTitle: '提示',
        //     nzContent: '尊敬的纳税人，增值税发票管理系统2.0版停机升级进行中，暂时无法查验发票，为您带来的不便请您谅解。',
        //     nzOnOk: () => {
        //     }
        //   });
        // }

        this.httpService.newGetPublicAttr(this.cyrObj);
        console.log(this.cyrObj);
        if (this.cyrObj.user_name === '管理用户') {
            this.ifAdmin = true;
        }
    }

    // dateFormat(timestamp, format) {
    //   let date = timestamp instanceof Date ? timestamp : new Date(timestamp);
    //   const o = {
    //     "y{4}": date.getFullYear(),
    //     "MM": date.getMonth() + 1,
    //     "dd": date.getDate(),
    //     "hh": date.getHours(),
    //     "mm": date.getMinutes(),
    //     "ss": date.getSeconds()
    //   };
    //   for (let i in o) {
    //     if (new RegExp('(' + i + ')').test(format)) {
    //       format = format.replace(RegExp.$1, o[i] < 10 ? '0' + o[i] : o[i]);
    //     }
    //   }
    //   return format;
    // };

}
