import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';
@Component({
  selector: 'app-remove-binding',
  templateUrl: './remove-binding.component.html',
  styleUrls: ['./remove-binding.component.css']
})
export class RemoveBindingComponent implements OnInit {
  isVisible = false;
  tit = '提示';
  checkInvoiceTit: any;
  delFlag = false;
  dataSet = [];
  inputValue: any;
  removeBindingObj = {
    "nsrsbh": "",
    "user_name": "管理用户",
    "orgid": "",
    "reqSubpackageModel": {
      "condition": ""
    },
    "nsrmc": "",
    "areaCode": "110"
  };
  delObj = {
    "nsrsbh": "",
    "user_name": "管理用户",
    "orgid": "",
    "reqSubpackageModel": {
      "id": ""
    },
    "nsrmc": "",
    "areaCode": "110"
  };
  delList: any;

  constructor(
    private httpService: HttpService,
  ) { }

  ngOnInit() {
    this.getRemoveBinding();
  }
  // 获取
  getRemoveBinding() {
    this.httpService.newGetPublicAttr(this.removeBindingObj);
    this.removeBindingObj.reqSubpackageModel.condition = this.inputValue;
    console.log(this.removeBindingObj);
    const jcbdData = new FormData();
    jcbdData.append('headModel', JSON.stringify(this.removeBindingObj));
    this.httpService.removeBinding('/api/selectBindList', jcbdData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        this.dataSet = JSON.parse(res.ResSubpackage).dataList;
      }
    });

  }
  // 搜索 回车
  searchKeyUp(e) {
    console.log(this.inputValue);
    this.removeBindingObj.reqSubpackageModel.condition = this.inputValue;
    if (e.keyCode == '13') {
      this.removeBindingObj.reqSubpackageModel.condition = this.inputValue;
    }
    this.getRemoveBinding();
  }
  // 搜索点击
  searchClick() {
    this.removeBindingObj.reqSubpackageModel.condition = this.inputValue;
    this.getRemoveBinding();
  }
  // 解除绑定
  unboundClick(e) {
    console.log(e);
    this.isVisible = true;
    this.delFlag = true;
    this.tit = '解除绑定';
    this.delList = e;


  }
  // 提示框确定
  handleOk(): void {
    // eval("app.SetWindowConver(false)");
    this.isVisible = false;

    this.tit = '提示';
    if (this.delFlag) {
      this.httpService.newGetPublicAttr(this.delObj);
      this.delObj.reqSubpackageModel.id = this.delList.id;
      console.log(this.removeBindingObj);
      const jcbddelData = new FormData();
      jcbddelData.append('headModel', JSON.stringify(this.delObj));
      this.httpService.removeBinding('/api/unbindPhone', jcbddelData).subscribe(res => {
        console.log(res);
        if (res.returnCode == '00000000') {
          this.delFlag = false;
          this.getRemoveBinding();
        } else if (res.returnCode == '00000002') {
          if (eval("app.ReLogin()")) {
            this.getRemoveBinding();
          } else {
            this.returnCodeDispose('00000003')
          }
        } else {
          this.returnCodeDispose(res.returnCode);
        }
      });
    }
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
  }
  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    this.tit = '提示';
    if (resDataTypeNumber == '02') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
    } else if (resDataTypeNumber == '03') {
      this.isVisible = true;
      this.checkInvoiceTit = '查无此票';
    } else if (resDataTypeNumber == '04') {
      this.isVisible = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
    } else if (resDataTypeNumber == '05') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    } else if (resDataTypeNumber == '06') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    }
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    this.tit = '提示';
    if (returnCodeNumber == '00000001') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票数据错误';
    } else if (returnCodeNumber == '00000002') {
      this.isVisible = true;
      this.checkInvoiceTit = '重新登录';
    } else if (returnCodeNumber == '00000003') {
      this.isVisible = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisible = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisible = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisible = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisible = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }
  }

}
