import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvoiceCheckoutComponent } from './invoice-checkout/invoice-checkout.component';
import { InvoiceCheckoutBatchComponent } from './invoice-checkout-batch/invoice-checkout-batch.component';
import { InspectionRecordsComponent } from './inspection-records/inspection-records.component';
import { FlowRecordComponent } from './flow-record/flow-record.component';
import { MarketBlacklistComponent } from './market-blacklist/market-blacklist.component';
import { ForbidCommodityComponent } from './forbid-commodity/forbid-commodity.component';
import { BuynameSetComponent } from './buyname-set/buyname-set.component';
import { RemoveBindingComponent } from './remove-binding/remove-binding.component';
import { UseAWizardComponent } from './use-a-wizard/use-a-wizard.component';
import { ManageDepartmentComponent } from './manage-department/manage-department.component';
import { EditEmployeeComponent } from './manage-department/edit-employee/edit-employee.component';


// import { InvoiceTypeComponent } from './components/invoice-type/invoice-type.component';

const routes: Routes = [
    // 发票查验-单票
    {
        path: 'invoiceCheckout',
        component: InvoiceCheckoutComponent,
    },
    // 发票查验-批量
    {
        path: 'invoiceCheckoutBatch',
        component: InvoiceCheckoutBatchComponent,
    },
    // 查验记录-发票查验记录
    {
        path: 'inspectionRecords',
        component: InspectionRecordsComponent,
    },
    // 查验记录-发票流水记录
    {
        path: 'flowRecords',
        component: FlowRecordComponent,
    },
    // 发票规则-销方黑名单
    {
        path: 'marketBlacklist',
        component: MarketBlacklistComponent,
    },
    // 发票规则-禁报商品
    {
        path: 'forbidCommodity',
        component: ForbidCommodityComponent,
    },
    // 发票规则-购方名称设置
    {
        path: 'buynameSet',
        component: BuynameSetComponent,
    },
    // 解除绑定
    {
        path: 'removeBinding',
        component: RemoveBindingComponent,
    },
    // 使用向导
    {
        path: 'useAWizard',
        component: UseAWizardComponent,
    },
    // 部门管理
    {
        path: 'manageDepartment',
        component: ManageDepartmentComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    declarations: []
})
export class InvoiceCheckRoutingModule { }
