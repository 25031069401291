import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { HttpService } from '../../http/http.service';

@Component({
    selector: 'app-edit-employee',
    templateUrl: './edit-employee.component.html',
    styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {
    validateForm: FormGroup;
    selectedValue;
    @Input() deptList;
    @Input() defaultDept?;
    @Input() employeeInfo?

    obj: any = {
        "nsrsbh": "",
        "user_name": "管理用户",
        "orgid": "",
        "nsrmc": "",
        "areaCode": "110"
    };

    packageHeader(obj: Object) {
        this.obj.reqSubpackageModel = obj
        this.httpService.newGetPublicAttr(this.obj);
        const formData = new FormData();
        formData.append('headModel', JSON.stringify(this.obj));
        return formData
    }
    constructor(private fb: FormBuilder, private httpService: HttpService, private modalRef: NzModalRef, private message: NzMessageService) { }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            userName: [null, [Validators.required]],
            phone: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
            deptId: [null, [Validators.required]],
            deptName: [null],
            ifadmin: [null, [Validators.required]],
            userId: [null]
        });

        if (this.employeeInfo) {
            this.validateForm.patchValue({ ...this.employeeInfo, userId: this.employeeInfo.id })
        }
        if (this.defaultDept && this.defaultDept != 0) {
            this.deptChange(this.defaultDept)
            this.validateForm.patchValue({ deptId: this.defaultDept })
        }
        console.log('this.deptList :>> ', this.deptList);
        console.log(this.validateForm);
    }

    deptChange(e) {

        const find = this.deptList.find(item => item.id == e)
        if (find) {
            this.validateForm.patchValue({ deptName: find.deptName })
        }
    }
    addUser() {
        let formData = this.packageHeader(this.validateForm.value);
        if (this.validateForm.value.userId) {
            this.httpService.invCheck('/api/deptUser/updateUser', formData)
                .subscribe(res => {
                    if (res.returnCode == '00000000') {
                        this.message.success(res.ResSubpackage)
                        setTimeout(() => this.modalRef.destroy('success'), 300)
                    }else {
                        this.message.warning(res.returnMessage)
                    }
                })
        } else {

            this.httpService.invCheck('/api/deptUser/addUser', formData)
                .subscribe(res => {
                    if (res.returnCode == '00000000') {
                        this.message.success(res.ResSubpackage)
                        setTimeout(() => this.modalRef.destroy('success'), 300)
                    }else {
                        this.message.warning(res.returnMessage)
                    }

                })
        }

    }

    submitForm(): void {
        // 如当前已选择某部门，则自动切换部门到当前选择
        // 姓名和手机号保存时去空格
        // 手机号校验规则1打头，11位数字
        // 部门选择项不展示全公司
        // 角色可选择：管理员和普通员工
        const { userName, phone } = this.validateForm.value;
        if (userName) {
            this.validateForm.patchValue(
                {
                    userName: this.validateForm.value.userName.trim()
                }
            )
        }
        if (phone) {
            this.validateForm.patchValue(
                {
                    phone: this.validateForm.value.phone.trim()
                }
            )
        }
        for (const i in this.validateForm.controls) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
        if (!this.validateForm.valid) return;
        this.addUser()
    }

}
