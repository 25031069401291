import { Injectable, Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { concatMap } from "rxjs/operators";

@Injectable()
export class HttpService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json;application/x-www-form-urlencodeed; charset=utf-8' })
    };

    constructor(private http: HttpClient,) { }

    /**
     * 获取url
     */
    // const url = HostApp.getHostData('serviceUrl');
    /*          --------共通---------               */
    // 公共属性获取

    getPublicAttr(obj) {
        obj.version = eval("HostApp.getHostData('version')");
        obj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
        obj.nsrmc = eval("HostApp.getHostData('nsrmc')");
        obj.orgid = eval("HostApp.getHostData('orgid')");
        obj.user_name = eval("HostApp.getHostData('user_name')");
        obj.areaCode = eval("HostApp.getHostData('area_code')");
        obj.ReqSubpackage.token = eval("HostApp.getHostData('Token')");
    }
    // 新接口
    newGetPublicAttr(obj) {
        // obj.version = undefined;
        // obj.nsrsbh = '123456789066666';
        // obj.nsrmc = '5YyX5Lqs5Y+R56Wo5p+l6aqM5rWL6K+VMQ==';
        // obj.orgid = '976F2E60B30D46A59F74566A2D590E4B';
        // obj.user_name = '管理用户';
        obj.version = eval("HostApp.getHostData('version')");
        obj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
        obj.nsrmc = eval("HostApp.getHostData('nsrmc')");
        obj.orgid = eval("HostApp.getHostData('orgid')");
        obj.user_name = eval("HostApp.getHostData('user_name')");
    }



    /*          --------接口---------               */

    // 单票查验-查验 新增 // 单票查验-查验 // 单票查验-查验 明细
    invCheck(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 手工查验
    manualInspection(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 验证码
    verificationCode(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 发票查验记录
    invoiceCheckoutRecords(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 销方黑名单
    salesBlacklists(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 购方名称设置
    buyersNameSet(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 使用向导
    useAWizard(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 解除绑定
    removeBinding(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }
    // 标注
    markSet(url: string, body: any): Observable<any> {
        return this.http
            .post(url, body)
            .pipe(map((res: Response) => {
                return res;
            }));
    }


}


