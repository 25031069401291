import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HttpService } from '../../http/http.service';
@Component({
  selector: 'app-check-table',
  templateUrl: './check-table.component.html',
  styleUrls: ['./check-table.component.css']
})
export class CheckTableComponent implements OnInit {
  @Input() listOfAllData;
  @Input() allData;
  @Output()  @Output() pageNoChange: EventEmitter<any> = new EventEmitter<any>();
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  ventureBusinessFlag = false;
  fxqyDecryptedResJson: any;
  cfcyDecryptedResJson: any;
  listOfDisplayData: any[] = [];
  endFxqyData: {};
  endCfcyData: any;
  repePopFlag = false;
  cfcyFlag = false;
  cfcyTit: any;
  isVisible = false;
  checkInvoiceTit: any;
  total = 1
  mapOfCheckedId: { [key: string]: boolean } = {};
  noData = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 },]
  buttonItems = [
    { name: '发票正常', bgColor: 'gary', tip: '', },
    { name: '重复查验', bgColor: 'gary', tip: '', },
    { name: '购方查验', bgColor: 'gary', tip: '', },
    { name: '禁报查验', bgColor: 'gary', tip: '', },
    { name: '黑名单查验', bgColor: 'gary', tip: '', },
    { name: '风险企业', bgColor: 'gary', tip: '', },
  ];
  //风险企业
  vbObj = {
    "ReqSubpackage": {
      "token": "",
      "invoiceId": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_FXQYCX",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 重复查验
  repeObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "fpdm": "",
      "fphm": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_CFCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  constructor(
    private httpService: HttpService,
  ) { }

  ngOnInit() {
    console.log(this.allData);

  }
  // 表格 方法
  currentPageDataChange(e): void {
    console.log(e);
    if (this.allData) {
      this.total = this.allData.total;
    }
    this.listOfAllData.map(item => {
      item.btnState = this.buttonItems;
      this.showButtonState(item);
      if (item.result == "1") {
        item.result = '存在发票';
      } else if (item.result == "3") {
        item.result = '不存在发票';
      }
      return item;
    });
    this.listOfDisplayData = e;
    this.refreshStatus('');
  }

  refreshStatus(e): void {
    console.log(e);
    console.log(this.listOfAllData);
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus('');
  }

  butClick(e, cont) {
    console.log(e);
    this.cfcyBtn(e, cont);
    console.log(cont)

    // 风险企业
    if (e.bgColor == 'yellow') {
      if (e.name == '风险企业') {
        this.ventureBusinessFlag = true;
        this.vbObj.serviceId = 'ZXTPLCYFW_FXQYCX';
        this.vbObj.ReqSubpackage.invoiceId = cont.invoiceId;
        this.httpService.getPublicAttr(this.vbObj);
        console.log(this.vbObj);
        const fxqyObj = JSON.stringify(this.vbObj);
        const fxqyEncryptedJson = eval("app.EncryptReqJson(fxqyObj)");
        console.log(fxqyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', fxqyEncryptedJson).subscribe(fxqyRes => {
          console.log(fxqyRes);
          console.log(4444444);
          if (fxqyRes.returnCode == '00000000') {
            console.log(6666);
            const fxqyResres = JSON.stringify(fxqyRes);
            this.fxqyDecryptedResJson = eval("app.DecryptResJson(fxqyResres)");
            console.log(this.fxqyDecryptedResJson);
            this.endFxqyData = JSON.parse(JSON.parse(this.fxqyDecryptedResJson).ResSubpackage);
            console.log(this.endFxqyData);
          } else if (fxqyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';
            if (eval("app.ReLogin()")) { }

          } else {
            this.returnCodeDispose(fxqyRes.returnCode);
          }

        });
      }
    } else {
      this.ventureBusinessFlag = false;
    }
  }

  // 重复查验按钮
  cfcyBtn(e, cont) {
    if (e.bgColor == 'yellow') {
      if (e.name == '重复查验') {
        this.repePopFlag = true;
        this.repeObj.serviceId = 'ZXTPLCYFW_CFCY';
        this.httpService.getPublicAttr(this.repeObj);
        if (cont) {
          this.repeObj.ReqSubpackage.fpdm = cont.fpdm;
          this.repeObj.ReqSubpackage.fphm = cont.fphm;
        }


        console.log(this.repeObj);
        const cfcyObj = JSON.stringify(this.repeObj);
        const cfcyEncryptedJson = eval("app.EncryptReqJson(cfcyObj)");
        console.log(cfcyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', cfcyEncryptedJson).subscribe(cfcyRes => {
          console.log(cfcyRes);
          console.log(333333);
          if (cfcyRes.returnCode == '00000000') {
            console.log(6666);

            this.cfcyFlag = false;
            const cfcyResres = JSON.stringify(cfcyRes);
            this.cfcyDecryptedResJson = eval("app.DecryptResJson(cfcyResres)");
            console.log(this.cfcyDecryptedResJson);
            this.endCfcyData = JSON.parse(JSON.parse(JSON.parse(this.cfcyDecryptedResJson).ResSubpackage).cfcyList);
            console.log(this.endCfcyData);
            this.endCfcyData.map(item => {
              if (item.cyfs == '1') {
                item.cyfsZn = '客户端';
              } else if (item.cyfs == '2') {
                item.cyfsZn = '手机端';
              } else if (item.cyfs == '3') {
                item.cyfsZn = '发票查验API';
              }
            })
          } else if (cfcyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';

          } else if (cfcyRes.returnCode == '00000002') {
            if (eval("app.ReLogin()")) {
              if (e) {
                this.cfcyBtn(e, cont);
              }
            } else {
              this.returnCodeDispose('00000003')
            }
          } else {
            this.returnCodeDispose(cfcyRes.returnCode);
          }

        });
      }
    } else {
      this.repePopFlag = false;
    }
  }

  // 风险企业弹框取消
  vbhandleCancel() {
    this.ventureBusinessFlag = false;
  }
  vbhandleOk() {
    this.ventureBusinessFlag = false;
  }
  // 提示框确定
  handleOk(): void {
    // eval("app.SetWindowConver(false)");
    this.isVisible = false;
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
  }
  // 重复查验弹框取消
  pophandleCancel(): void {
    this.repePopFlag = false;
  }
  // 重复查验弹框确定
  pophandleOk(): void {
    this.repePopFlag = false;
  }

  // 按钮
  showButtonState(item) {
    item.btnState = [
      { name: '发票正常', bgColor: 'gary', tip: '', },
      { name: '重复查验', bgColor: 'gary', tip: '', },
      { name: '购方查验', bgColor: 'gary', tip: '', },
      { name: '禁报查验', bgColor: 'gary', tip: '', },
      { name: '黑名单查验', bgColor: 'gary', tip: '', },
      { name: '风险企业', bgColor: 'gary', tip: '', },
    ];
    if (item.result == '0' || item.result == '4' || item.result == '5' || item.result == '7') {

    } else if (item.result == '3') {
      item.btnState[0].bgColor = 'red';
    } else {
      if (item.sjcy == 0) {
        item.btnState[0].bgColor = 'red';
      } else {
        item.btnState[0].bgColor = 'blue';
      };
      if (item.cfcy == 1) {
        item.btnState[1].bgColor = 'yellow';
      };
      if (item.cfcy == 0) {
        item.btnState[1].bgColor = 'blue';
      };

      if (item.gfcy == 1) {
        item.btnState[2].bgColor = 'blue';
      } else {
        item.btnState[2].bgColor = 'red';
      };

      if (item.jbcy == 1) {
        item.btnState[3].bgColor = 'red';
      } else {
        item.btnState[3].bgColor = 'blue';
      };
      if (item.hmdcy == 1) {
        item.btnState[4].bgColor = 'red';
      } else {
        item.btnState[4].bgColor = 'blue';
      };
      if (item.fxqy == 1) {
        item.btnState[5].bgColor = 'yellow';
      } else {
        item.btnState[5].bgColor = 'blue';
      };
      return item;
    }
  }

  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    if (resDataTypeNumber == '02') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
    } else if (resDataTypeNumber == '03') {
      this.isVisible = true;
      this.checkInvoiceTit = '查无此票';
    } else if (resDataTypeNumber == '04') {
      this.isVisible = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
    } else if (resDataTypeNumber == '05') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    } else if (resDataTypeNumber == '06') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    }
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    if (returnCodeNumber == '00000001') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票数据错误';
    } else if (returnCodeNumber == '00000002') {
      this.isVisible = true;
      this.checkInvoiceTit = '重新登录';
    } else if (returnCodeNumber == '00000003') {
      this.isVisible = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisible = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisible = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisible = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisible = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }
  }

  // 页码点击
  pageIndexChange(e) {
    console.log(e);
    // this.pageNoChange.emit(e);
  }


}
