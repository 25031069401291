import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { HttpService } from './invoice-check/http/http.service';
import { InvoiceCheckoutComponent } from './invoice-check/invoice-checkout/invoice-checkout.component';
import { InvoiceCheckoutBatchComponent } from './invoice-check/invoice-checkout-batch/invoice-checkout-batch.component';
import { InvoiceTypeComponent } from './invoice-check/components/invoice-type/invoice-type.component';
import { ExcelTableComponent } from './invoice-check/components/excel-table/excel-table.component';
import { InspectionRecordsComponent } from './invoice-check/inspection-records/inspection-records.component';
import { CheckTableComponent } from './invoice-check/components/check-table/check-table.component';
import { FlowRecordComponent } from './invoice-check/flow-record/flow-record.component';
import { MarketBlacklistComponent } from './invoice-check/market-blacklist/market-blacklist.component';
import { ForbidCommodityComponent } from './invoice-check/forbid-commodity/forbid-commodity.component';
import { BuynameSetComponent } from './invoice-check/buyname-set/buyname-set.component';
import { RemoveBindingComponent } from './invoice-check/remove-binding/remove-binding.component';
import { UseAWizardComponent } from './invoice-check/use-a-wizard/use-a-wizard.component';
import { ManageDepartmentComponent } from './invoice-check/manage-department/manage-department.component';
import { EditEmployeeComponent } from './invoice-check/manage-department/edit-employee/edit-employee.component';
registerLocaleData(zh);

@NgModule({
    declarations: [
        AppComponent,
        InvoiceCheckoutComponent,
        InvoiceCheckoutBatchComponent,
        InvoiceTypeComponent,
        ExcelTableComponent,
        InspectionRecordsComponent,
        CheckTableComponent,
        FlowRecordComponent,
        MarketBlacklistComponent,
        ForbidCommodityComponent,
        BuynameSetComponent,
        RemoveBindingComponent,
        UseAWizardComponent,
        ManageDepartmentComponent,
        EditEmployeeComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgZorroAntdModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
    ],
    entryComponents: [EditEmployeeComponent],
    providers: [{ provide: NZ_I18N, useValue: zh_CN }, HttpService],
    bootstrap: [AppComponent]
})
export class AppModule { }
