import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvoiceCheckRoutingModule } from './invoice-check/invoice-check.routing.module';
const routes: Routes = [
  { path: '', redirectTo: '/invoiceCheckout', pathMatch: 'full' },
  // 查验发票
  {
    path: 'invoiceCheckout',
    loadChildren: './invoice-check/invoice-check.routing.module#InvoiceCheckRoutingModule'
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes),
  imports: [RouterModule.forRoot(routes, { useHash: true }), InvoiceCheckRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
