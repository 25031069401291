import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';

@Component({
  selector: 'app-market-blacklist',
  templateUrl: './market-blacklist.component.html',
  styleUrls: ['./market-blacklist.component.css']
})
export class MarketBlacklistComponent implements OnInit {

  dataSet = [
  ];
  tit = '本单位别字';
  isVisible = false;
  isVisibleTip = false;
  editFlag = false;
  delFlag = false;
  delList: any;
  nameEditValue: any;
  inputValue: any;
  checkInvoiceTit: any;
  xfshValue: any;
  xfmcValue: any;
  xfhmdcxObj = {
    "ReqSubpackage": {
      "xfhmdId": null,
      "xfsh": null,
      "xfmc": null,
      "token": "",
      "createDate": null,
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null,
      "condition": '',
    },
    "version": "",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "",
    "jm": "1",
    "orgid": "",
    "areaCode": "",
    "lxfs": ""
  };
  xfhmdaddObj = {
    "ReqSubpackage": {
      "xfhmdId": null,
      "xfsh": "",
      "xfmc": "",
      "token": "",
      "createDate": null,
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null
    },
    "version": "1.3.0.1811",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "",
    "areaCode": "500000",
    "lxfs": ""
  };
  xfhmdeditObj = {
    "ReqSubpackage": {
      "xfhmdId": "",
      "xfsh": "",
      "xfmc": "",
      "token": "",
      "createDate": null,
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null
    },
    "version": "1.3.0.1811",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "",
    "areaCode": "500000",
    "lxfs": ""
  };
  xfhmddelObj = {
    "ReqSubpackage": {
      "xfhmdId": "",
      "xfsh": null,
      "xfmc": null,
      "token": "",
      "createDate": null,
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null
    },
    "version": "1.3.0.1811",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "",
    "areaCode": "500000",
    "lxfs": ""
  }



  xfhmdDecryptedResJson: any;
  xfhmdaddDecryptedResJson: any;
  xfhmdList: any;

  constructor(
    private httpService: HttpService,
  ) { }
  ngOnInit() {
    this.aliasInquire();
  }
  // 搜索
  searchClick() {
    console.log(this.inputValue);
    this.xfhmdcxObj.ReqSubpackage.condition = this.inputValue;
    this.aliasInquire();
  }
  // 回车
  searchKeyUp(e) {
    this.inputValue = this.inputValue.replace(/^\s+|\s+$/g, '');
    switch (this.inputValue) {
      case '%':
        this.xfhmdcxObj.ReqSubpackage.condition = '\\%';
        break;
      case '\'':
        this.xfhmdcxObj.ReqSubpackage.condition = '\\\'';
        break;
      case '_':
        this.xfhmdcxObj.ReqSubpackage.condition = '\\_';
        break;
      case '\\':
        this.xfhmdcxObj.ReqSubpackage.condition = '\\\\\\\\';
        break;
      default: this.xfhmdcxObj.ReqSubpackage.condition = this.inputValue;
    }

    this.aliasInquire();

    if (e.keyCode == 13) {
      console.log('回车');
      console.log(this.inputValue);
      this.aliasInquire();
    }
  }
  // 销方黑名单查询
  aliasInquire() {
    this.xfhmdcxObj.serviceId = 'ZXTPLCYFW_XFHMDQYCX';
    this.httpService.getPublicAttr(this.xfhmdcxObj);
    console.log(this.xfhmdcxObj);
    // 加密请求json子节点 加密后的obj
    // 发票查验 add
    const xfhmdcxObjLs = JSON.stringify(this.xfhmdcxObj);
    const xfhmdcxEncryptedJson = eval("app.EncryptReqJson(xfhmdcxObjLs)");
    console.log(xfhmdcxEncryptedJson);
    // 使用加密后的json进行网络请求
    this.httpService.salesBlacklists('/api/plfpcy.controller', xfhmdcxEncryptedJson).subscribe(res => {
      // 解密响应的json
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(111);
        const xfhmdRes = JSON.stringify(res);
        this.xfhmdDecryptedResJson = eval('app.DecryptResJson(xfhmdRes)');
        console.log(this.xfhmdDecryptedResJson);
        console.log(JSON.parse(JSON.parse(JSON.parse(this.xfhmdDecryptedResJson).ResSubpackage).xfhmdList));
        this.xfhmdList = JSON.parse(JSON.parse(JSON.parse(this.xfhmdDecryptedResJson).ResSubpackage).xfhmdList);
        this.dataSet = this.xfhmdList;
        this.isVisible = false;

      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.aliasInquire();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }


  // 添加
  aliasAddClick() {
    this.xfshValue = '';
    this.xfmcValue = '';
    this.isVisible = true;
    this.tit = '添加(销方黑名单)';
    this.editFlag = false;
    this.delFlag = false;
  }
  // 修改
  nameEdit(e) {
    console.log(e);
    this.isVisible = true;
    this.tit = '修改(销方黑名单)';
    this.xfmcValue = e.xfmc;
    this.xfshValue = e.xfsh;
    this.nameEditValue = e;
    this.editFlag = true;
    this.delFlag = false;
  }
  // 删除
  nameDel(e) {
    this.isVisible = true;
    this.tit = '删除(销方黑名单)';
    this.delFlag = true;
    this.delList = e;
  }
  // 添加 接口
  addMarketBlacklist() {
    this.xfhmdaddObj.serviceId = 'ZXTPLCYFW_XFHMDQYTJ';
    this.httpService.getPublicAttr(this.xfhmdaddObj);
    this.xfhmdaddObj.ReqSubpackage.xfmc = this.xfmcValue;
    this.xfhmdaddObj.ReqSubpackage.xfsh = this.xfshValue;
    console.log(this.xfhmdaddObj);
    // 加密请求json子节点 加密后的obj
    // 发票查验 add
    const xfhmdaddObjLs = JSON.stringify(this.xfhmdaddObj);
    const xfhmdaddEncryptedJson = eval("app.EncryptReqJson(xfhmdaddObjLs)");
    console.log(xfhmdaddEncryptedJson);
    // 使用加密后的json进行网络请求
    this.httpService.salesBlacklists('/api/plfpcy.controller', xfhmdaddEncryptedJson).subscribe(res => {
      // 解密响应的json
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(222);
        this.aliasInquire();
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.aliasInquire();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }

  // 修改 接口
  editMarketBlacklist() {
    console.log(this.nameEditValue);
    this.xfhmdeditObj.serviceId = 'ZXTPLCYFW_XFHMDXG';
    this.httpService.getPublicAttr(this.xfhmdeditObj);
    this.xfhmdeditObj.ReqSubpackage.xfhmdId = this.nameEditValue.xfhmdId;
    this.xfhmdeditObj.ReqSubpackage.xfmc = this.xfmcValue;
    this.xfhmdeditObj.ReqSubpackage.xfsh = this.xfshValue;
    console.log(this.xfhmdeditObj);
    // 加密请求json子节点 加密后的obj
    // 发票查验 add
    const xfhmdeditObjLs = JSON.stringify(this.xfhmdeditObj);
    const xfhmdeditEncryptedJson = eval("app.EncryptReqJson(xfhmdeditObjLs)");
    console.log(xfhmdeditEncryptedJson);
    // 使用加密后的json进行网络请求
    this.httpService.salesBlacklists('/api/plfpcy.controller', xfhmdeditEncryptedJson).subscribe(res => {
      // 解密响应的json
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(333);
        this.aliasInquire();
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.aliasInquire();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }
  // 删除  接口
  delMarketBlacklist() {
    this.xfhmddelObj.serviceId = 'ZXTPLCYFW_XFHMDSC';
    this.xfhmddelObj.ReqSubpackage.xfhmdId = this.delList.xfhmdId;
    this.httpService.getPublicAttr(this.xfhmddelObj);
    console.log(this.xfhmddelObj);
    // 加密请求json子节点 加密后的obj
    // 发票查验 add
    const xfhmddelObjLs = JSON.stringify(this.xfhmddelObj);
    const xfhmddelEncryptedJson = eval("app.EncryptReqJson(xfhmddelObjLs)");
    console.log(xfhmddelEncryptedJson);
    // 使用加密后的json进行网络请求
    this.httpService.salesBlacklists('/api/plfpcy.controller', xfhmddelEncryptedJson).subscribe(res => {
      // 解密响应的json
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(444);
        this.aliasInquire();
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.aliasInquire();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }
  // 识别号keyup
  xfshKeyup() {
    this.xfshValue = this.xfshValue.replace(/^\s+|\s+$/g, '').replace(/[\W]/g, '');
  }
  // 名称keyup
  xfmcKeyup() {
    this.xfmcValue = this.xfmcValue.replace(/^\s+|\s+$/g, '');
    if (this.xfmcValue.length > 20) {
      this.xfmcValue = this.xfmcValue.slice(0, 20);
    }
  }
  // 提示框确定
  handleOk(): void {

    if (this.delFlag) {
      this.delMarketBlacklist();
      this.aliasInquire();
    } else {
      if (this.xfmcValue != '') {
        console.log(this.xfmcValue);
        console.log(this.xfshValue);
        if (this.xfshValue != '') {
          if (this.xfshValue.length == 15 || this.xfshValue.length == 18 || this.xfshValue.length == 20) {
            console.log(this.dataSet);
            const res = this.dataSet.find(k => k.xfmc == this.xfmcValue);
            const resres = this.dataSet.find(k => k.xfsh == this.xfshValue);
            console.log(res);
            console.log(resres);
            if (res != undefined && resres != undefined) {
              this.isVisibleTip = true;
              this.checkInvoiceTit = '销方税号或销方名称已存在';

            } else {
              this.isVisible = false;
              if (this.editFlag) {
                this.editMarketBlacklist();
              } else {
                this.addMarketBlacklist();
              }

            }
          } else {
            this.isVisibleTip = true;
            this.checkInvoiceTit = '请输入正确税方号码';
          }
        } else {
          this.isVisibleTip = true;
          this.checkInvoiceTit = '请输入销方税号';
        }

      } else {
        this.isVisibleTip = true;
        this.checkInvoiceTit = '请输入销方名称';
      }
    }

  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
  }
  tipHandleCancel() {
    this.isVisibleTip = false;
  }
  tipHandleOk() {
    this.isVisibleTip = false;
  }
  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    if (resDataTypeNumber == '02') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
    } else if (resDataTypeNumber == '03') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查无此票';
    } else if (resDataTypeNumber == '04') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
    } else if (resDataTypeNumber == '05') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查询失败，请重试';
    } else if (resDataTypeNumber == '06') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查询失败，请重试';
    }
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    if (returnCodeNumber == '00000001') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '发票数据错误';
    } else if (returnCodeNumber == '00000002') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '重新登录';
    } else if (returnCodeNumber == '00000003') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }
  }

}
