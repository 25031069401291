import { Component, OnInit, ViewChild } from '@angular/core';
import { NzFormatEmitEvent, NzMessageService, NzModalRef, NzModalService, NzTreeComponent } from 'ng-zorro-antd';
import { HttpService } from '../http/http.service';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';


@Component({
    selector: 'app-manage-department',
    templateUrl: './manage-department.component.html',
    styleUrls: ['./manage-department.component.css']
})
export class ManageDepartmentComponent implements OnInit {
    @ViewChild("nzTreeComponent") nzTreeComponent: NzTreeComponent;

    obj: any = {
        "nsrsbh": "",
        "user_name": "管理用户",
        "orgid": "",
        "nsrmc": "",
        "areaCode": "110"
    };

    nodes: any = [
        {
            id: "0",
            deptName: "全公司",
            orgId: "976F2E60B30D46A59F74566A2D590E4B",
            children: []
        }
    ];
    listOfData = [];
    selectId = 0;
    selectedKeys = [];
    pageNo = 1;
    searchPhoneOrUserName = "";
    tableLoading = false
    total = 0
    packageHeader(obj: Object) {
        this.obj.reqSubpackageModel = obj
        this.httpService.newGetPublicAttr(this.obj);
        const formData = new FormData();
        formData.append('headModel', JSON.stringify(this.obj));
        return formData
    }

    addDept(deptName) {
        let formData = this.packageHeader({ deptName, deptId: '0' });
        this.httpService.invCheck('/api/deptUser/addDept', formData)
            .subscribe(res => {
                if (res.returnCode == '00000000') {
                    this.message.success(res.ResSubpackage)
                    this.getTreeData()
                } else {
                    this.message.warning(res.returnMessage)
                }
            })
    }

    updateDept({ deptName, deptId }) {
        let formData = this.packageHeader({ deptName, deptId });
        this.httpService.invCheck('/api/deptUser/updateDept', formData)
            .subscribe(res => {
                if (res.returnCode == '00000000') {
                    this.message.success(res.ResSubpackage)
                    this.getDeptUser();
                    this.getTreeData()
                }else {
                    this.message.warning(res.returnMessage)
                }
            })
    }

    delDept(deptId) {
        let formData = this.packageHeader({ deptId });
        return this.httpService.invCheck('/api/deptUser/delDept', formData).toPromise()
    }


    getDeptUser() {
        this.tableLoading = true
        let params = {
            searchPhoneOrUserName: this.searchPhoneOrUserName, deptId: this.selectId, pageNo: this.pageNo, pageSize: 10
        }
        let formData = this.packageHeader(params);
        this.httpService.invCheck('/api/deptUser/getDeptUser', formData)
            .subscribe(res => {

                if (res.returnCode == '00000000') {
                    const data = JSON.parse(res.ResSubpackage).pageInfo; console.log('res :>> ', JSON.parse(res.ResSubpackage));
                    this.listOfData = data.list
                    this.pageNo = data.pageNum
                    this.total = data.total
                }
                setTimeout(() => {
                    this.tableLoading = false
                }, 300);


            })
    }

    delUser(userId) {
        let formData = this.packageHeader({ userId });
        return this.httpService.invCheck('/api/deptUser/delUser', formData).toPromise()
    }

    constructor(private modal: NzModalService, private message: NzMessageService, private httpService: HttpService) {
    }
    ngOnInit() {
        this.getTreeData()
    }


    nzClick(event: NzFormatEmitEvent): void {
        console.log(event)
        this.selectId = event.node.origin.id;
        this.selectedKeys = [this.selectId];
        this.getDeptUser()
    }

    editDepartment() {
        if (this.selectId == 0 || !this.selectId) {
            this.message.warning('请选择要编辑的部门');
            return
        }
        let arr = this.nodes[0].children;
        arr.forEach((item, i) => {
            if (item.id == this.selectId) {
                arr[i].edit = true
            }
        })

        this.selectionText()

    }

    addDepartment() {
        const hasEdit = this.nodes[0].children.find((item) => item.edit)
        if (hasEdit) return;
        if (this.nodes[0].children.length == 0) {
            this.nzTreeComponent.getTreeNodes()[0].isLeaf = false
            this.nzTreeComponent.getTreeNodes()[0].isExpanded = true
        }
        this.nzTreeComponent.getTreeNodes()[0].addChildren([
            {
                deptName: " ",
                edit: true,
                isLeaf: true,
                isSelected: true
            }
        ]);
        this.selectionText()
    }

    delDepartment() {
        if (this.selectId == 0 || !this.selectId) {
            this.message.warning('请选择要删除的部门');
            return
        }
        const find = this.nodes[0].children.find((item) => item.id == this.selectId)
        console.log('find :>> ', find);
        if (find) {
            this.modal.confirm({
                nzTitle: '提示',
                nzContent: `确认要删除部门${find.deptName}吗？`,
                nzOnOk: async () => {
                    const delRes = await this.delDept(this.selectId);
                    if (delRes.returnCode == '00000000') {
                        this.message.success(delRes.ResSubpackage)
                        this.getTreeData()
                    } else {
                        this.message.warning(delRes.returnMessage)
                    }
                }
            })
        }

    }

    selectionText() {
        setTimeout(() => {
            const docEditInput: any = document.getElementById("editInput");
            if (!docEditInput) return;
            docEditInput.focus();
            docEditInput.selectionStart = 0;
            docEditInput.selectionEnd = docEditInput.value.length;
        }, 0)
    }

    inputBlur(event, node) {
        const { id, deptName } = node.origin;
        const value = event.target.value;
        if (!id) {
            if (value.trim()) {
                // 新增部门    
                this.addDept(value)
            } else {
                // 内容为空，删除新增项
                this.nodes[0].children = this.nodes[0].children.slice(0, this.nodes[0].children.length - 1);
                this.nodes = this.nodes.slice(0);
            }
        } else {
            if (value.trim()) {
                //  修改
                this.updateDept({ deptName, deptId: id })
            } else {
                this.message.warning('部门名称不能为空')
            }
        }
    }

    getTreeData() {
        let formData = this.packageHeader({})
        this.httpService.invCheck('/api/deptUser/getDeptTree', formData)
            .subscribe(res => {
                let trees = JSON.parse(res.ResSubpackage)
                this.nodes = this.formatTreeData([trees])
            })
    }

    formatTreeData(trees: any[]) {
        trees.forEach(item => {
            item.key = item.id
            if (item.children && item.children.length > 0) {
                this.formatTreeData(item.children);
            } else {
                item.isLeaf = true;
            }
        });
        return trees
    }

    addEmployee() {
        const modal: NzModalRef = this.modal.create({
            nzTitle: '新增员工',
            nzContent: EditEmployeeComponent,
            nzComponentParams: {
                deptList: this.nodes[0].children.slice(),
                defaultDept: this.selectId
            },
            nzFooter: null
        })

        modal.afterClose.subscribe(result => {
            if (result === 'success') {
                this.getDeptUser()
            }
        })
    }

    editEmployee(employeeInfo) {
        console.log('employeeInfo :>> ', employeeInfo);
        const modal: NzModalRef = this.modal.create({
            nzTitle: '修改员工',
            nzContent: EditEmployeeComponent,
            nzComponentParams: {
                deptList: this.nodes[0].children.slice(),
                employeeInfo
            },
            nzFooter: null
        })

        modal.afterClose.subscribe(result => {
            if (result === 'success') {
                this.getDeptUser()
            }
        })
    }

    delEmployee(employee) {
        this.modal.confirm({
            nzTitle: '提示',
            nzContent: `是否删除${employee.userName}？`,
            nzOnOk: async () => {
                const delRes = await this.delUser(employee.id);
                if (delRes.returnCode == '00000000') {
                    this.message.success(delRes.ResSubpackage)
                    this.getDeptUser()
                }
            }
        })
    }

    pageIndexChange(pageNo) {
        this.pageNo = pageNo;
        this.getDeptUser()
    }

}





