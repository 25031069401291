import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../http/http.service';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Router, NavigationEnd, ActivationStart } from '@angular/router';
import { UploadXHRArgs } from 'ng-zorro-antd';
import { UploadFile } from 'ng-zorro-antd';
import { webSocket } from 'rxjs/webSocket';
import { ElementRef } from '@angular/core';
import { Buffer } from 'buffer';
import { DomSanitizer } from '@angular/platform-browser';
import { element } from '@angular/core/src/render3';
import { containsElement } from '@angular/animations/browser/src/render/shared';

@Component({
  selector: 'app-invoice-checkout-batch',
  templateUrl: './invoice-checkout-batch.component.html',
  styleUrls: ['./invoice-checkout-batch.component.css']
})
/**
 * 发票查验-批量查验
 */
export class InvoiceCheckoutBatchComponent implements OnInit {
  // 双向绑定的obj
  // 扫描枪/手工
  checkObj = {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  // 手工
  handCheckObj = {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  // 手机
  telCheckObj = {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  // pdf
  pdfCheckObj = {
    name: 'pdf',
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税电子普通发票',
  };
  // 扫描枪
  smqObj = {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  // 传给后台的Obj
  // 扫描枪/手工
  obj = {
    'ReqSubpackage': {
      'token': '',// token 客户端登录成功标志
      'info_id': '', // 批次id
      'fpdm': '', //发票代码
      'fphm': '', // 发票号码
      'kprq': '', // 开票日期
      'fplx': '', // 发票类型
      'fplx_mc': '', // 发票类型名称
      'fplx_jc': '', // 发票类型简称
      'jelx_mc': '',// 类型名称
      'je': '', // 金额不含税，发票类型是01，02，03则必输
      'jym': '', // 校验码后六位，发票类型是04，10，11则必输
      'invoice_id': '', // 发票标识
      'cyfs': '1', // 查验方式
      'resultType': null, // 查询结果: 02查询不一致
      'ResCode': '',
      'ResMessage': ''
    },
    'version': '1.2.0.1803', // 版本
    'serviceId': '', // 接口名称
    'nsrsbh': '', // 纳税人识别号
    'nsrmc': '', // 纳税人名称
    'user_name': '',
    'jm': '1', // 加密
    'orgid': '', // 企业标识
    'areaCode': '', // 地区编码
    'lxfs': '' // 联系方式
  };
  // 发票查验
  fpcyObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": "C739B1E82B1748FE9690BA038D4A8A0A",
      "pcClientBZ": null,
      "kprq": "2018-11-19",
      "jejym": "205293",
      "cyfs": null
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_DPCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 发票查验明细
  fpcymxObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": "C739B1E82B1748FE9690BA038D4A8A0A",
      "pcClientBZ": null,
      "kprq": null,
      "jejym": null,
      "cyfs": "1"
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_FPCYMX",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 手机
  telObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": "C739B1E82B1748FE9690BA038D4A8A0A",
      "pcClientBZ": null,
      "kprq": "2018-11-19",
      "jejym": "205293",
      "cyfs": null
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_DPCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 重复查验
  repeObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "fpdm": "",
      "fphm": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_CFCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  //风险企业
  vbObj = {
    "ReqSubpackage": {
      "token": "",
      "invoiceId": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_FXQYCX",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };

  // 设备型号
  unitTypeObj = {
    "ReqSubpackage": {
      "token": "OWI2ZDQ3ZWMtOTMyYS00ZGQyLWFlMWEtZDdiYWM0YjJlMDcz",
      "devType": "2"
    },
    "version": "1.3.0.1811",
    "serviceId": "ZXTPLCYFW_SBXHJGM",
    "nsrsbh": "ZHANGWANPING201901",
    "nsrmc": "5byg5LiH5bmz5pyJ6ZmQ6LSj5Lu75YWs5Y+4",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "D91BCBB2AB4A47519D1F977F0BC002FA",
    "areaCode": "110",
    "lxfs": ""
  };
  // 验证码
  yzmObj = {
    "ReqSubpackage": {
      "token": "",
      "invoice_id": "",
      "pcClientBZ": null,
      "kprq": null,
      "jejym": null,
      "cyfs": null
    },
    "version": "1.3.0.1811",
    "serviceId": "ZXTPLCYFW_HQYZM",
    "nsrsbh": "ZHANGWANPING201901",
    "nsrmc": "5byg5LiH5bmz5pyJ6ZmQ6LSj5Lu75YWs5Y+4",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "D91BCBB2AB4A47519D1F977F0BC002FA",
    "areaCode": "110",
    "lxfs": ""
  }
  ifYzmObj = {
    "ReqSubpackage": {
      "yzm_id": "AB38DE4DB12D440596184F2E8DE7643A_2",
      "yzm_ts": null,
      "yzm_image": null,
      "token": "ODhiZDQ1Y2UtNDI1YS00MTQwLWE1MDAtN2NjYjdlOTNlZjI5",
      "invoice_id": "03F5350AACF546ACA47D355653581BAB",
      "yzm_info": "VkczSEtJ",
      "kprq": "2018-11-18",
      "jejym": "339210",
      "ResCode": null,
      "ResMessage": null
    },
    "version": "1.3.0.1811",
    "serviceId": "ZXTPLCYFW_TJYZM",
    "nsrsbh": "ZHANGWANPING201901",
    "nsrmc": "5byg5LiH5bmz5pyJ6ZmQ6LSj5Lu75YWs5Y+4",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "D91BCBB2AB4A47519D1F977F0BC002FA",
    "areaCode": "110",
    "lxfs": ""
  }

  // 批量查验
  plcyObj = {
    "version": "",
    "reqSubpackageModel": { "pageNo": 1, "pageSize": 5, "condition": "" },
    "nsrsbh": "",
    "user_name": "",
    "orgid": "",
    "nsrmc": ""
  }
  // 次数
  numObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": null,
      "pcClientBZ": null,
      "kprq": null,
      "jejym": null,
      "cyfs": null
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_XFSQ",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };



  // 按钮
  buttonItems = [
    { name: '发票正常', bgColor: 'gary', tip: '', },
    { name: '重复查验', bgColor: 'gary', tip: '', },
    { name: '购方查验', bgColor: 'gary', tip: '', },
    { name: '禁报查验', bgColor: 'gary', tip: '', },
    { name: '黑名单查验', bgColor: 'gary', tip: '', },
    { name: '风险企业', bgColor: 'gary', tip: '', },
  ];
  today = new Date();
  dateFormat = 'yyyy-MM-dd';
  decryptedResJson: any;
  fpcyDecryptedResJson: any;
  fpcymxDecryptedResJson: any;
  cfcyDecryptedResJson: any;
  sbxhDecryptedResJson: any;
  fxqyDecryptedResJson: any;
  yzmDecryptedResJson: any;
  yzmmxDecryptedResJson: any;
  endCfcyData: any;
  endSbxhData: {};
  url: '';
  isVisible = false;
  checkInvoiceCodeFlag = false;
  checkInvoiceDateFlag = false;
  checkInvoiceNumberFlag = false;
  checkInvoiceMoneyFlag = false;
  checkInvoiceTit: any;
  checkInvoiceType: any;
  dNew: any;
  myDateNew: any;
  aDate: any;
  bDate: any;
  day: any;
  navFlag = 0;
  endData = [];
  residueArray = [];
  endYear: any;
  endMonth: any;
  endDay: any;
  repePopFlag = false;
  cfcyFlag = false;
  cfcyTit: any;
  fileList: any;
  pdfObj = {
    "nsrsbh": "",
    "orgid": "",
    "user_name": "",
    "nsrmc": ''
  };
  excelObj = {
    "nsrsbh": "",
    "orgid": "",
    "user_name": "",
    "nsrmc": ''
  };
  excelDownObj = {
    "nsrsbh": "",
    "orgid": "",
    "user_name": "",
    "nsrmc": '',
    "areaCode": ''
  };
  exportInvoiceObj = {
    "nsrsbh": "",
    "orgid": "",
    "user_name": "",
    "nsrmc": '',
    "areaCode": '',
    "reqSubpackageModel": { condition: '' },
  };
  pdfEndData: any;
  file: any;
  explainTit: any;
  explainFlag = false;
  numData: any;
  smqItem: any;
  disFlag = true;
  checkDisFlag = true;
  preDate: any;
  ws: WebSocket;
  xsdIndex;
  smqValue: any;
  smqText = "";
  ventureBusinessFlag = false;
  endFxqyData: {};
  tipFlag = false;
  yamValue: any;
  ewmImgUlr: any;
  ewmsrc: any;
  ewmsrcTwo: any;
  showFileList = []
  yzmImg: any;
  imgColorTit: any;
  imgcolorYs: any;
  searchFpValue: any;
  inpDelMarkFlag = false;
  listOfData: any[] = [];
  summaryData = {
    total: '',
    checkedCount: '',
    invalidCount: '',
    errorCount: ''
  };
  isVisiblesx = false;
  isVisiblerggy = false;
  excelTableFlag = false;
  isSpinning = false;
  getVerResLists: any;
  yzmImgUrl: any;
  currentImgNumber = 1;
  imgTotal: any;
  refreshList: any;
  pdfNumberShow: 0;
  excelisVisible = false;
  titList: any;
  pdfisVisible = false;
  pdfTitList = [];
  excelFile: any;
  plcyResData: any;
  moreLoadButFlag = false;
  pdfIsSpinning = false;
  isVisiblePdf = false;
  pdfName = [];
  // 标注
  isMarkVisible = false;
  remarkValue = '';
  remarkData: any;
  clickIndex: any;
  constructor(
    private el: ElementRef,
    private httpService: HttpService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.router = router;
    console.log(router);
    // 订阅路由事件
    router.events.subscribe((event: NavigationEnd) => {
      // 判断路由的事件类型（也就是路由的生命周期）
      if (event instanceof ActivationStart) { // 当导航成功结束时执行

        this.ws.close();
      }
    });
  }

  ngOnInit() {

    eval('app.setVersionCode("1.3.0.1906")') //版本号
    this.batchCheck('');
    this.connectWs();
    this.selectSummary();
    console.log(this.listOfData);
    // 手机二维码图片处理
    // const ewmimg = eval('HostApp.getHostData("PhoneCheckGuide")');
    const ewmimg = eval('HostApp.getHostData("BatchPhoneCheckGuide")');
    this.ewmImgUlr = JSON.parse(ewmimg).xxList;
    console.log(this.ewmImgUlr);
    this.ewmsrc = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/JPEG;base64,' + this.ewmImgUlr[0].imageInfo);
    console.log(this.ewmsrc);
    //
    this.ewmsrcTwo = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.ewmImgUlr[1].imageInfo);

    const curDate = new Date();
    const d = new Date(curDate.getTime() - 24 * 60 * 60 * 1000);
    this.preDate = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
      '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
    console.log(this.preDate);
    this.checkObj.checkInvoiceDate = this.preDate;
  }

  // 不可选择的时间
  disabledDate = (current: Date) => {
    const pre = new Date();
     //  业务逻辑调整 可查询五年内数据 20200206
    pre.setFullYear(pre.getFullYear() - 5);

    return current > this.today || current < pre;
  }

  excelExplain() {
    this.isVisible = true;
    this.explainFlag = true;
    this.checkInvoiceTit = '说明：' + '\n' + '1.支持导入后缀为.xlsx文件格式；' + '\n' + '2.每个导入文件最大支持2M；'
      + '\n' + '3.每个导入文件最多支持20000条发票信息；' + '\n' + '4.导入的文件将自动识别的表头进行确认，识别有误请手动修改确认'
      + '\n' + '5.推荐使用标准模板'
  }

  // 点击tab
  navClick(e) {
    if (e.target.innerText == '手工查验') {
      this.handCheckObj.checkInvoiceDate = this.preDate;

    }

    if (e.target.innerText == '手机查验') {

    }
    if (e.target.innerText == '扫描枪/手工查验') {
      this.checkObj.checkInvoiceDate = this.preDate;

    }
    if (e.target.innerText == 'PDF导入查验') {

    }

    if (e.target.innerText == 'Excel导入查验') {

    }


  }

  //扫描枪
  smqClick() {
    this.smqValue = '';
    this.smqItem = [];
    const resBoolean = eval('app.CheckScanningGun()');
    console.log(resBoolean);
    if (!resBoolean) {
      this.isVisible = true;
      this.checkInvoiceTit = '未检测到支持的扫描枪，请插入扫描枪或联系服务商解决';
    } else {
      this.isVisible = false;
      console.log('start scanning!');
      //      console.log('01,01,1100182130,13860411,87758.63,20180625,C62D\\r\\n');
    }
  }

 // 扫描枪
 setKeyCode(keycode: any) {
  switch (keycode) {
    case 8:
      this.smqText = this.smqText.substring(0, this.smqText.length - 1);
      break;
    case 48:
      this.smqText += "0";
      break;
    case 49:
      this.smqText += "1";
      break;
    case 50:
      this.smqText += "2";
      break;
    case 51:
      this.smqText += "3";
      break;
    case 52:
      this.smqText += "4";
      break;
    case 53:
      this.smqText += "5";
      break;
    case 54:
      this.smqText += "6";
      break;
    case 55:
      if (this.lastKeyCode == 16) {
        this.smqText += "&";
      } else {
        this.smqText += "7";
      }
      break;
    case 56:
      this.smqText += "8";
      break;
    case 57:
      this.smqText += "9";
      break;
    case 65:
      this.smqText += "A";
      break;
    case 66:
      this.smqText += "B";
      break;
    case 67:
      this.smqText += "C";
      break;
    case 68:
      this.smqText += "D";
      break;
    case 69:
      this.smqText += "E";
      break;
    case 70:
      this.smqText += "F";
      break;
    case 71:
      this.smqText += "G";
      break;
    case 72:
      this.smqText += "H";
      break;
    case 73:
      this.smqText += "I";
      break;
    case 74:
      this.smqText += "J";
      break;
    case 75:
      this.smqText += "K";
      break;
    case 76:
      this.smqText += "L";
      break;
    case 77:
      this.smqText += "M";
      break;
    case 78:
      this.smqText += "N";
      break;
    case 79:
      this.smqText += "O";
      break;
    case 80:
      this.smqText += "P";
      break;
    case 81:
      this.smqText += "Q";
      break;
    case 82:
      this.smqText += "R";
      break;
    case 83:
      this.smqText += "S";
      break;
    case 84:
      this.smqText += "T";
      break;
    case 85:
      this.smqText += "U";
      break;
    case 86:
      this.smqText += "V";
      break;
    case 87:
      this.smqText += "W";
      break;
    case 88:
      this.smqText += "X";
      break;
    case 89:
      this.smqText += "Y";
      break;
    case 90:
      this.smqText += "Z";
      break;
    case 188:
      this.smqText += ",";
      break;
    case 187:
      this.smqText += "=";
      break;
    case 110:
      this.smqText += ".";
      break;
    case 190:
      this.smqText += ".";
      break;
    case 189:
      if (this.lastKeyCode == 16) {
        this.smqText += "_";
      } else {
        this.smqText += "-";
      }
      break;
    case 191:
      if (this.lastKeyCode == 16) {
        this.smqText += "?";
      } else {
        this.smqText += "/";
      }
      break;
    case 186:
      this.smqText += ":";
      break;
    default:
      break;
  }
}
lastKeyCode = 0;
  // 扫描枪
  smqKeyUp(e) {
    console.log(e);
    console.log(this.smqText);
    console.log(this.smqValue);
    if (e.keyCode != 13) {
      this.setKeyCode(e.keyCode);
      this.lastKeyCode = e.keyCode;
      console.log("key:" + e.keyCode + " char:" + e.charCode);
      console.log(this.smqText);
      this.smqValue = '';
    } else {  
        // 深圳区块链发票
      if (/https:\/\/.*shenzhen.chinatax.gov.cn\//.test(this.smqText.toLowerCase())) {
       this.getQklInvoiceInfo(this.smqText.toLowerCase());
       return;
     }

      // alert("拿到结果：" + this.smqText + "\n开始单票查验");
      this.smqValue = '';
      // 去掉日期中的-
      this.smqText = this.smqText.replace(/-/g, "");
      console.log(this.smqText);
      this.smqItem = this.smqText.split(',');
      console.log(this.smqItem);
      if (this.smqItem.length >= 7) {
        this.smqObj.checkInvoiceCode = this.smqItem[2];
        const onedate = this.smqItem[5].slice(0, 4) + '-' + this.smqItem[5].slice(4, 6) + '-' + this.smqItem[5].slice(6, 8);
        // /  console.log( this.smqItem[5].slice(7, 2) + '-');
        console.log(onedate);
        console.log(this.smqItem[5]);
        this.smqObj.checkInvoiceNumber = this.smqItem[3];
        this.smqObj.checkInvoiceDate = onedate;
        this.codeChangeCheck(this.smqObj);
        if (this.smqObj.checkInvoiceType == '01' || this.smqObj.checkInvoiceType == '02' || this.smqObj.checkInvoiceType == '03'
          || this.smqObj.checkInvoiceType == '15' || this.smqObj.checkInvoiceType == '08'|| this.smqObj.checkInvoiceType == '09') {
          this.smqObj.checkInvoiceMoney = this.smqItem[4];
        } else {
          this.smqObj.checkInvoiceMoney = this.smqItem[6].slice(this.smqItem[6].length - 6);
        }
        console.log(this.smqObj.checkInvoiceMoney);
        console.log(this.smqObj);
        this.checkObj = this.smqObj;
        this.smqValue = '';
        this.smqText = '';
        this.smqItem = [];
        this.plcyObj.reqSubpackageModel.pageNo = 1;
        this.plcyObj.reqSubpackageModel.pageSize = 5;
        this.dataTreating(this.smqObj);

      } else {
        this.isVisible = true;
        this.explainFlag = false;
        this.checkInvoiceTit = '文件解读失败，请重试';
      }

    }
  }

  getQklInvoiceInfo(url: string) {
    this.isSpinning = true;
    this.smqValue = "";
    this.smqText = "";
    const fpcyQklObj = {
      ReqSubpackage: btoa(JSON.stringify({ key6: url })),
      version: "1.2.0.1803",
      serviceId: "ZXTPLCYFW_QRCODE_SZ",
      nsrsbh: "",
      nsrmc: "",
      user_name: "",
      jm: "0",
      orgid: "",
      areaCode: "",
      lxfs: "",
      cyfs: "5",
    };
    this.httpService.newGetPublicAttr(fpcyQklObj);
    this.httpService.invCheck('/api/plfpcy.controller', JSON.stringify(fpcyQklObj)).subscribe((res) => {
      console.log(res);
      this.isSpinning = false
      if (res.returnCode == "00000000") {
        const smqObj = JSON.parse(res.ResSubpackage);
        smqObj.checkInvoiceCode = smqObj.fpdm;
        smqObj.checkInvoiceType = smqObj.fplx;
        smqObj.checkInvoiceNumber = smqObj.fphm;
        smqObj.checkInvoiceMoney = smqObj.je;
        smqObj.checkInvoiceDate = smqObj.kprq;
        this.checkObj = smqObj;
        this.dataTreating(smqObj);
      }
    });
  }

  ////////////////  查验  ///////////////////
  // 发票代码change校验
  codeChangeCheck(e) {
    if (e.checkInvoiceCode.length == 12) {
      if (e.checkInvoiceCode == '144031539110' || e.checkInvoiceCode == '131001570151' ||
        e.checkInvoiceCode == '133011501118' || e.checkInvoiceCode == '111001571071') {
        e.checkInvoiceType = '10';
      } else if (e.checkInvoiceCode.startsWith('0')) {
        if (e.checkInvoiceCode.substring(10) == '11') {
          e.checkInvoiceType = '10';
        } else if (e.checkInvoiceCode.substring(10) == '12')//通行费
        {
          e.checkInvoiceType = '14';
        } else if (e.checkInvoiceCode.substring(10) == '17')//二手车
        {
          e.checkInvoiceType = '15';
        }else if (e.checkInvoiceCode.substring(10) == '13')//增值税电子专票 lusz--
        {
          e.checkInvoiceType = '08';
        } else if (e.checkInvoiceCode.substring(10) == '06' ||
          e.checkInvoiceCode.substring(10) == '07') {
          e.checkInvoiceType = '11';
        } else if (e.checkInvoiceCode.substring(10) == '04' ||
          e.checkInvoiceCode.substring(10) == '05') {
          e.checkInvoiceType = '04';
        }
      }  else if (e.checkInvoiceCode.startsWith("14403") && e.checkInvoiceCode.substring(8, 9) == "9") {
        e.checkInvoiceType = "S1";
      } else {
        if (e.checkInvoiceCode.substring(7, 8) == '2') {
          e.checkInvoiceType = '03';
        } else {
          e.checkInvoiceType = '66';
        }
      }
    } else {
      if(e.checkInvoiceCode.length == 0){
        e.checkInvoiceType = '09';
      }else{
        if (e.checkInvoiceCode.substring(7, 8) == '1' || e.checkInvoiceCode.substring(7, 8) == '5') {
          e.checkInvoiceType = '01';
        } else if (e.checkInvoiceCode.substring(7, 8) == '3' ||
          e.checkInvoiceCode.substring(7, 8) == '6') {
          e.checkInvoiceType = '04';
        } else if (e.checkInvoiceCode.substring(7, 8) == '2' ||
          e.checkInvoiceCode.substring(7, 8) == '7') {
          e.checkInvoiceType = '02';
        }
      }
    }

    if (e.checkInvoiceType == '01') {// 增值税专票// e.spanTit是前端文本框前的提示文字
      e.spanTit = '金额(不含税) ：';// 不含税
      e.invoiceName = '增值税专用发票';
    } else if (e.checkInvoiceType == '02') { // 增值税货物运输
      e.spanTit = '金额/校验码 ：'; // 含税
      e.invoiceName = '增值税普通发票';
    } else if (e.checkInvoiceType == '03') {// 机动车销售
      e.spanTit = '不含税价 ：';
      e.invoiceName = '机动车销售统一发票';
    } else if (e.checkInvoiceType == '15') {// 二手车
      e.spanTit = '车价合计 ：';
      e.invoiceName = '二手车销售统一发票';
    } else if (e.checkInvoiceType == '04') {// 增值税普票
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税普通发票';
    } else if (e.checkInvoiceType == '10') {// 增值税电子普票
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税电子普通发票';
    } else if (e.checkInvoiceType == '08') {// 增值税电子专票  需求修改标识 lusz--
      e.spanTit = '金额(不含税)：';
      e.invoiceName = '增值税电子专用发票';
    } else if (e.checkInvoiceType == '11') {// 增值税卷票
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税普通发票（卷票）';
    } else if (e.checkInvoiceType == '14') {// 通行费
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税电子普通发票';
    } else if (e.checkInvoiceType == '66') {// 开头不是0 的
      e.spanTit = '金额/校验码 ：';
      e.invoiceName = '增值税电子普通发票';
    } else if (e.checkInvoiceType == '09') {// 全电

        e.spanTit = '价税合计：';
        e.invoiceName = '电子发票';
      
    } else if (e.checkInvoiceType == "S1") {
      // 深圳电子普通发票
      e.spanTit = "金额 ：";
      e.invoiceName = "深圳电子普通发票"; // 发票种类名称变更
    } else {
      e.spanTit = '金额/校验码 ：';
      e.invoiceName = '增值税普通发票';
    }
  }
  // 发票代码校验
  checkCode(e) {
    if (e.checkInvoiceCode) {
      let codeReg = /^[0-9]*$/;
      if (codeReg.test(e.checkInvoiceCode)) {
        if (e.checkInvoiceCode.length == 10 || e.checkInvoiceCode.length == 12) {
          console.log(e.checkInvoiceCode)

          if (e.checkInvoiceType) {

            if (e.checkInvoiceType == '01' || //增值税专用发票
              e.checkInvoiceType == '03' || //机动车销售统一发票
              e.checkInvoiceType == '04' || //增值税普通发票
              e.checkInvoiceType == '10' || //增值税电子普通发票
              e.checkInvoiceType == '11' ||//增值税普通发票（卷票）
              e.checkInvoiceType == '14' || //通行费
              e.checkInvoiceType == '15' || //二手车
              e.checkInvoiceType == '08'||
              e.checkInvoiceType == "09" || // 全电
              e.checkInvoiceType == 'S1'  )// 增值税电子专票  需求修改标识 lusz--
            {
              this.checkInvoiceCodeFlag = true;
            } else if (e.checkInvoiceType == '66') {
              this.checkInvoiceCodeFlag = false;
              this.isVisible = true;
              this.checkInvoiceTit = '暂不支持该类发票查验';
            } else {
              this.checkInvoiceCodeFlag = false;
              this.isVisible = true;
              this.checkInvoiceTit = '暂不支持该类发票查验';
            }
          } else {
            this.checkInvoiceCodeFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '暂不支持该类发票查验';
          }
        } else {
          this.checkInvoiceCodeFlag = false;
          this.isVisible = true;
          this.checkInvoiceTit = '发票代码有误，请输入10位或者12位发票代码';
        }
      } else {
        this.checkInvoiceCodeFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '发票代码有误，请输入10位或者12位发票代码';
      }
    } else {
      this.checkInvoiceCodeFlag = true;

      // this.checkInvoiceCodeFlag = false;
      // this.isVisible = true;
      // this.checkInvoiceTit = '请输入发票代码';
    }


  }
  // 发票日期校验
  checkDate(e) {

    if (e.checkInvoiceDate) {
      console.log(e.checkInvoiceDate);
      // 开票日期 相差天数
      const d = new Date(e.checkInvoiceDate);
      this.dNew = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
        '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
      const myDate = new Date();
      this.myDateNew = myDate.getFullYear() + '-' + ((myDate.getMonth() + 1) < 10 ? '0' + (myDate.getMonth() + 1) :
        (myDate.getMonth() + 1)) + '-' + ((myDate.getDate()) < 10 ? '0' + (myDate.getDate()) : (myDate.getDate()));


      console.log(this.dNew);
      // if (this.dNew == this.myDateNew) {
      //   this.checkInvoiceDateFlag = false;
      //   this.isVisible = true;
      //   this.checkInvoiceTit = '当天开具的发票，请在24小时后查验';

      // } else {
      //   this.aDate = new Date(this.dNew);
      //   this.bDate = new Date(this.myDateNew);
      //   this.day = (this.bDate - this.aDate) / (24 * 60 * 60 * 1000);
      //   if (this.day <= 365) {
      //     this.checkInvoiceDateFlag = true;
      //     e.checkInvoiceDate = this.dNew;
      //   } else {
      //     this.checkInvoiceDateFlag = false;
      //     this.isVisible = true;
      //     this.checkInvoiceTit = '仅支持一年内开具的发票查验';
      //     this.isSpinning = false;
      //   }
      // }
      this.aDate = new Date(this.dNew);
      this.bDate = new Date(this.myDateNew);
      this.day = (this.bDate - this.aDate) / (24 * 60 * 60 * 1000);
      //  业务逻辑调整 可查询五年内数据 20200206
      if (this.day <= 365*5) {
        this.checkInvoiceDateFlag = true;
        e.checkInvoiceDate = this.dNew;
      } else {
        this.checkInvoiceDateFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '仅支持五年内开具的发票查验';
        this.isSpinning = false;
      }

    } else {
      this.checkInvoiceDateFlag = false;
      this.isVisible = true;
      this.checkInvoiceTit = '请输入发票日期';
    }
  }

  // 发票号码校验
  checkNumber(e) {
    if (e.checkInvoiceNumber) {

      if(e.checkInvoiceCode.length == 0){
        e.checkInvoiceType = '09';
      }

      let numReg = /^[0-9]*$/;
      if (numReg.test(e.checkInvoiceNumber)) {

        if(e.checkInvoiceType == '09'){
          if (e.checkInvoiceNumber.length == 20 ) {
            this.checkInvoiceNumberFlag = true;
          } else {
            this.checkInvoiceNumberFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '发票号码有误，请输入20位发票号码';
          }

   
            e.spanTit = '价税合计：';
            e.invoiceName = '电子发票';
          
          
        }else{

          if (e.checkInvoiceNumber.length == 8) {
            this.checkInvoiceNumberFlag = true;
          } else {
            this.checkInvoiceNumberFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '发票号码有误，请输入8位发票号码';
          }

        }

      } else {
        this.checkInvoiceNumberFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '发票号码有误，请输入8位发票号码';
      }

    } else {
      this.checkInvoiceNumberFlag = false;
      this.isVisible = true;
      this.checkInvoiceTit = '请输入发票号码';
    }
  }

  // 金额、校验码等校验
  checkMoneyMore(e) {
    // let monReg = /^[0-9]*$/;
    let monReg = /^\d+(\.\d+)?$/;

    if (e.checkInvoiceType == '01' || e.checkInvoiceType == '02' || e.checkInvoiceType == '03' || e.checkInvoiceType == '15'
      || e.checkInvoiceType == '08'|| e.checkInvoiceType == '09'  ||
      e.checkInvoiceType == "S1") {  //lusz--
      if (e.checkInvoiceMoney) {
        if (monReg.test(e.checkInvoiceMoney)) {
          this.checkInvoiceMoneyFlag = true;
        } else {
          this.checkInvoiceMoneyFlag = false;
          this.isVisible = true;
          this.checkInvoiceTit = '请输入金额';
        }

      } else {
        this.checkInvoiceMoneyFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '请输入金额';
      }
    } else {
      if (e.checkInvoiceMoney) {

        if (monReg.test(e.checkInvoiceMoney)) {

          if (String(e.checkInvoiceMoney).length == 6) {
            this.checkInvoiceMoneyFlag = true;
          } else {

            this.checkInvoiceMoneyFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '校验码位数有误，请输入校验码后6位';
          }
        } else {

          this.checkInvoiceMoneyFlag = false;
          this.isVisible = true;
          this.checkInvoiceTit = '校验码位数有误，请输入校验码后6位';
        }

      } else {

        this.checkInvoiceMoneyFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '请输入校验码后6位';
      }
    }

  }
  // 发票代码change
  checkCodeChange() {
    // 011001700111
    this.codeChangeCheck(this.checkObj);

  }
  // 手工查验发票代码change
  handworkCheckCodeChange() {
    this.codeChangeCheck(this.handCheckObj);
  }


  //手工 号码 失去焦点
  handCheckNubChange(){
    this.codeChangeCheck(this.handCheckObj);    
    this.checkSMBtnCanCheck(this.handCheckObj);

  }

  //扫码/手工 号码 失去焦点
  checkNubChange(){
    this.codeChangeCheck(this.checkObj);    
    this.checkSMBtnCanCheck(this.checkObj);

  }


  sjcyState(item) {
    // 0 等待查验 0未查询,1成功,2不一致,3查无此票,4查验超限,5查询失败,6发票代码有误
    if (item.result == 0) {
      item.sjcyState = '等待查验';
    } else if (item.result == 1) {
      item.sjcyState = '已查验完成'
    } else if (item.result == 2) {
      item.sjcyState = '不一致';
    } else if (item.result == 3) {
      item.sjcyState = '查无此票'
    } else if (item.result == 4) {
      item.sjcyState = '查验超限'
    } else if (item.result == 5) {
      item.sjcyState = '查询失败'
    } else if (item.result == 6) {
      item.sjcyState = '发票代码有误'
    } else if (item.result == 7) {
      item.sjcyState = '授权失败'
    } else if (item.result == 8) {
      item.sjcyState = '等待查验'
    }


  }
  // 按钮
  showButtonState(item) {
    item.btnState = [
      { name: '发票正常', bgColor: 'gary', tip: '', },
      { name: '重复查验', bgColor: 'gary', tip: '', },
      { name: '购方查验', bgColor: 'gary', tip: '', },
      { name: '禁报查验', bgColor: 'gary', tip: '', },
      { name: '黑名单查验', bgColor: 'gary', tip: '', },
      { name: '风险企业', bgColor: 'gary', tip: '', },
    ];
    if (item.result == '1') {
      if (item.fpzt == 2) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '发票作废';
      } else if (item.fpzt == 3) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '发票红冲';
      } else if (item.fpzt == 7) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '部分红冲';
      } else if (item.fpzt == 8) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '全额红冲';
      } else {
        item.btnState[0].bgColor = 'blue';
        item.btnState[0].name = '发票正常';
      };
      if (item.cfcy == 1) {
        item.btnState[1].bgColor = 'red';
      };
      if (item.cfcy == 0) {
        item.btnState[1].bgColor = 'blue';
      };

      if (item.gfcy == 1) {
        item.btnState[2].bgColor = 'blue';
      } else {
        item.btnState[2].bgColor = 'red';
        item.btnState[2].name = "购方查验、非本单位购方名称";
        //  item.btnState[2].name = item.btnState[2].name.replace("\\n", ",")
      };

      if (item.jbcy == 1) {
        item.btnState[3].bgColor = 'red';
      } else {
        item.btnState[3].bgColor = 'blue';
      };
      if (item.hmdcy == 1) {
        item.btnState[4].bgColor = 'red';
      } else {
        item.btnState[4].bgColor = 'blue';
      };
      if (item.fxqy == 1) {
        item.btnState[5].bgColor = 'red';
      } else {
        item.btnState[5].bgColor = 'blue';
      };
      return item;
    }
  }
  sjcyColor(item) {
    if (item.result == 1) {
      item.sjcyColor = 'titBlack';
    } else if (item.result == 0) {
      item.sjcyColor = 'titGary';
    } else {
      item.sjcyColor = 'titRed';
    }
  }

  // 接口、数据处理
  dataTreating(e) {
    this.isSpinning = true;
    this.checkCode(e);
    if (this.checkInvoiceCodeFlag) {
      this.checkNumber(e);
      if (this.checkInvoiceNumberFlag) {
        this.checkDate(e);
        if (this.checkInvoiceDateFlag) {
          this.checkMoneyMore(e);
          if (this.checkInvoiceMoneyFlag) {
            console.log('我是查验方法');
            console.log(e);
            const sgcyObj:any = {
              "version": "1.2.0.1803",
              "nsrsbh": "500102010002163",
              "user_name": "管理用户",
              "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
              "reqSubpackageModel": {
                'fpdm': "1100182130",
                'fphm': "19312159",
                'kprq': '2018-10-22',
                'jejym': '5660.38'
              }
            }
            this.httpService.newGetPublicAttr(sgcyObj);
            sgcyObj.reqSubpackageModel.fpdm = e.checkInvoiceCode;
            sgcyObj.reqSubpackageModel.fphm = e.checkInvoiceNumber;
            sgcyObj.reqSubpackageModel.kprq = e.checkInvoiceDate;
            sgcyObj.reqSubpackageModel.jejym = e.checkInvoiceMoney;
            if (e.checkInvoiceType == "S1") {
              sgcyObj.reqSubpackageModel.je = e.checkInvoiceMoney;
              sgcyObj.reqSubpackageModel.jym = e.jym;
              sgcyObj.reqSubpackageModel.jejym = e.checkInvoiceMoney + "," + e.jym;
            } else {
              sgcyObj.reqSubpackageModel.jejym = e.checkInvoiceMoney;
            }
            console.log(sgcyObj);
            console.log(JSON.stringify(sgcyObj));
            const formData = new FormData();
            formData.append('headModel', JSON.stringify(sgcyObj));
            this.httpService.manualInspection('/api/autoInvoiceBatchCheck', formData).subscribe(res => {
              console.log(res);
              if (res.returnCode == '00000000') {
                this.batchCheck(e);

              } else if (res.returnCode == '00000002') {
                if (eval("app.ReLogin()")) {
                  this.dataTreating(e);
                } else {
                  this.returnCodeDispose('00000003')
                }
              } else {
                this.returnCodeDispose(res.returnCode);
              }
            })

          }
        }
      }
    }
  }
  // 批量查验
  batchCheck(e) {
    if (this.searchFpValue) {
      this.plcyObj.reqSubpackageModel.condition = this.searchFpValue;
    }
    if (this.searchFpValue == '') {
      this.plcyObj.reqSubpackageModel.condition = '';
    }

    this.httpService.newGetPublicAttr(this.plcyObj);
    console.log(this.plcyObj)
    const plcyData = new FormData();
    plcyData.append('headModel', JSON.stringify(this.plcyObj)); //selectBatchCheckRecord
    this.httpService.manualInspection('/api/selectBatchCheckRecordPage', plcyData).subscribe(plcyRes => {
      console.log(plcyRes)
      if (plcyRes['returnCode'] == '00000000') {
        this.isSpinning = false;
        this.selectSummary();

        eval("app.setCheckRemain()");
        //  this.summaryData = JSON.parse(plcyRes.ResSubpackage).summaryData
        //  console.log(this.summaryData);
        console.log(JSON.parse(plcyRes.ResSubpackage).pageInfo);
        this.plcyResData = JSON.parse(plcyRes.ResSubpackage).pageInfo;
        this.endData = JSON.parse(plcyRes.ResSubpackage).pageInfo.list;
        console.log(this.endData)
        if (this.plcyResData.pageNum < this.plcyResData.pages) {
          this.moreLoadButFlag = true;
        } else {
          this.moreLoadButFlag = false;
        }

        this.endData.forEach((item) => {

          item.btnState = this.buttonItems;
          this.sjcyColor(item);
          this.sjcyState(item);
          this.showButtonState(item);
          item.sjcyTitColor = '';
          if (item.yzmSj && item.yzmSj != '0') {
            item.yzmSj = item.yzmSj.slice(0, 10);
          }
        })

        console.log(this.endData);
        if (this.endData.length < 5) {
          this.residueArray.length = 5 - this.endData.length;
          console.log(this.residueArray.length);
        } else if (this.endData.length == 0) {
          this.residueArray.length = 5;
        }

      } else if (plcyRes.returnCode = '00000002') {

        if (eval("app.ReLogin()")) {
          if (e) {
            this.dataTreating(e);
          }
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(plcyRes.returnCode);
      }


    });
  }

  // 加载更多
  moreLoadClick() {
    if (this.plcyResData.pageNum < this.plcyResData.pages) {
      this.moreLoadButFlag = true;
    } else {
      this.moreLoadButFlag = false;
    }
    this.plcyObj.reqSubpackageModel.pageNo++;
    this.plcyObj.reqSubpackageModel.pageSize = 5;
    this.httpService.newGetPublicAttr(this.plcyObj);
    console.log(this.plcyObj);
    const plcyData = new FormData();
    plcyData.append('headModel', JSON.stringify(this.plcyObj));
    this.httpService.manualInspection('/api/selectBatchCheckRecordPage', plcyData).subscribe(plcyRes => {
      console.log(plcyRes)
      if (plcyRes['returnCode'] == '00000000') {
        this.selectSummary();
        //  this.summaryData = JSON.parse(plcyRes.ResSubpackage).summaryData
        //  console.log(this.summaryData);
        console.log(JSON.parse(plcyRes.ResSubpackage).pageInfo);
        this.plcyResData = JSON.parse(plcyRes.ResSubpackage).pageInfo;
        const newArray = JSON.parse(plcyRes.ResSubpackage).pageInfo.list;
        eval("app.setCheckRemain()");

        if (this.plcyResData.pageNum < this.plcyResData.pages) {
          this.moreLoadButFlag = true;
        } else {
          this.moreLoadButFlag = false;
        }

        console.log(this.endData);
        console.log(newArray);
        this.endData = [...this.endData, ...newArray];
        this.endData.forEach((item) => {
          item.btnState = this.buttonItems;
          this.sjcyColor(item);
          this.sjcyState(item);
          this.showButtonState(item);
          item.sjcyTitColor = '';
          if (item.yzmSj && item.yzmSj != '0') {
            item.yzmSj = item.yzmSj.slice(0, 10);
          }
        })

        console.log(this.endData);

      } else if (plcyRes.returnCode = '00000002') {
        eval("app.ReLogin()")
      } else {
        this.returnCodeDispose(plcyRes.returnCode);
      }


    });


  }
  selectSummary() {

    const summaryObj = {
      "version": eval("HostApp.getHostData('version')"),
      "reqSubpackageModel": { "condition": "" },
      "nsrsbh": eval("HostApp.getHostData('nsrsbh')"),
      "user_name": eval("HostApp.getHostData('user_name')"),
      "orgid": eval("HostApp.getHostData('orgid')"),
      "nsrmc": eval("HostApp.getHostData('nsrmc')")
    }

    const selectSummaryData = new FormData();
    selectSummaryData.append('headModel', JSON.stringify(summaryObj));

    this.http.post('/api/selectBatchCheckRecordSummary', selectSummaryData).subscribe(summaryRes => {
      console.log(summaryRes)
      if (summaryRes['returnCode'] == '00000000') {
        this.summaryData = JSON.parse(summaryRes['ResSubpackage']).summaryData;
        console.log(this.summaryData);
      }
    });
  }


  // socket 连接 手机
  connectWs() {
    if (this.ws != null) { this.ws.close() };
    const userID = eval("HostApp.getHostData('nsrsbh')");
    const user_name = eval("HostApp.getHostData('user_name')");
    // 修改为域名访问 20200224   47.95.228.72 sjcy.dqyfapiao.com
    const socket = "ws://sjcy.dqyfapiao.com/api/websocketHandler?id=" + userID + "&userName=" + user_name;
    // const socket = "ws://172.18.0.115:8081/api/websocketHandler?id=" + userID + "&userName=" + user_name;
    // const socket = "ws://172.18.0.147:8081/api/websocketHandler?id=" + userID + "&userName=" + user_name;
    // const socket = "ws://172.18.2.80:8080/api/websocketHandler?id=" + userID + "&userName=" + user_name;
    // const socket = "ws://172.18.0.147:8080/api/websocketHandler?id=" + userID + "&userName=" + user_name;

    this.ws = new WebSocket(socket);
    let that = this;
    this.ws.onopen = (e) => {
      //socket 开启后执行，可以向后端传递信息
      this.ws.send('sonmething');
      console.log('开启');
    }
    this.ws.onmessage = (event) => {
      //socket 获取后端传递到前端的信息
      that.ws.send('sonmething');
      console.log('获取xinxi');
      console.log(event);
      // socketType":"PHONE_CHECK
      const en = JSON.parse(event.data);
      const ev = JSON.parse(JSON.parse(event.data).ResSubpackage);
      console.log(en);
      console.log(ev);

      if (en['socketType'] == 'PHONE_CHECK') {

      } else {
        console.log('endData展示');
        console.log(this.endData);
        this.endData.unshift(ev);
        console.log(this.endData);

        this.isSpinning = false;
        const resIndex = this.endData.findIndex(m => m.id == ev.id && m.sjcyState == '等待查验');
        if (resIndex != -1) {
          this.endData.splice(resIndex, 1);
        }
        this.endData.forEach((item) => {
          item.btnState = this.buttonItems;
          this.sjcyColor(item);
          this.showButtonState(item);
          this.sjcyState(item);
          if (item.yzmSj && item.yzmSj != '0') {
            item.yzmSj = item.yzmSj.slice(0, 10)
            if (item.fplx == '31' || item.fplx == '32' || item.fplx == '09') {
              item.fphm = item.fpdm + item.fphm;
              item.fpdm = ''
            }
          }
        })
        this.selectSummary();
        eval("app.setCheckRemain()");
      }

    }
    this.ws.onerror = (e) => {
      //socket error信息
    }
    this.ws.onclose = (event) => {
      //socket 关闭后执行

    }
  }

  // 重复查验按钮
  cfcyBtn(e, cont) {
    if (e.bgColor == 'red') {
      if (e.name == '重复查验') {
        this.repePopFlag = true;
        this.repeObj.serviceId = 'ZXTPLCYFW_CFCY';
        this.httpService.getPublicAttr(this.repeObj);
        if (cont) {
          this.repeObj.ReqSubpackage.fpdm = cont.fpdm;
          this.repeObj.ReqSubpackage.fphm = cont.fphm;
        }


        console.log(this.repeObj);
        const cfcyObj = JSON.stringify(this.repeObj);
        const cfcyEncryptedJson = eval("app.EncryptReqJson(cfcyObj)");
        console.log(cfcyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', cfcyEncryptedJson).subscribe(cfcyRes => {
          console.log(cfcyRes);
          console.log(333333);
          if (cfcyRes.returnCode == '00000000') {
            console.log(6666);

            this.cfcyFlag = false;
            const cfcyResres = JSON.stringify(cfcyRes);
            this.cfcyDecryptedResJson = eval("app.DecryptResJson(cfcyResres)");
            console.log(this.cfcyDecryptedResJson);
            this.endCfcyData = JSON.parse(JSON.parse(JSON.parse(this.cfcyDecryptedResJson).ResSubpackage).cfcyList);
            console.log(this.endCfcyData);
            this.endCfcyData.map(item => {
              if (item.cyfs == '1') {
                item.cyfsZn = '客户端';
              } else if (item.cyfs == '2') {
                item.cyfsZn = '手机端';
              } else if (item.cyfs == '3') {
                item.cyfsZn = '发票查验API';
              }
            })
          } else if (cfcyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';

          } else if (cfcyRes.returnCode == '00000002') {
            if (eval("app.ReLogin()")) {
              if (e) {
                this.cfcyBtn(e, cont);
              }
            } else {
              this.returnCodeDispose('00000003')
            }
          } else {
            this.returnCodeDispose(cfcyRes.returnCode);
          }

        });
      }
    } else {
      this.repePopFlag = false;
    }
  }
  // but点击
  butClick(e, cont) {
    console.log(e);
    // this.cfcyFlag = false;
    this.cfcyBtn(e, cont);
    console.log(cont)

    // 风险企业
    if (e.bgColor == 'red') {
      if (e.name == '风险企业') {
        this.ventureBusinessFlag = true;
        this.vbObj.serviceId = 'ZXTPLCYFW_FXQYCX';
        this.vbObj.ReqSubpackage.invoiceId = cont.invoiceId;
        this.httpService.getPublicAttr(this.vbObj);
        console.log(this.vbObj);
        const fxqyObj = JSON.stringify(this.vbObj);
        const fxqyEncryptedJson = eval("app.EncryptReqJson(fxqyObj)");
        console.log(fxqyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', fxqyEncryptedJson).subscribe(fxqyRes => {
          console.log(fxqyRes);
          console.log(4444444);
          if (fxqyRes.returnCode == '00000000') {
            console.log(6666);
            const fxqyResres = JSON.stringify(fxqyRes);
            this.fxqyDecryptedResJson = eval("app.DecryptResJson(fxqyResres)");
            console.log(this.fxqyDecryptedResJson);
            this.endFxqyData = JSON.parse(JSON.parse(this.fxqyDecryptedResJson).ResSubpackage);
            console.log(this.endFxqyData);
          } else if (fxqyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';
            if (eval("app.ReLogin()")) { }

          }

        });
      }
    } else {
      this.ventureBusinessFlag = false;
    }
  }

  // 发票代码回车
  checkKeyUp(e) {
    this.checkObj.checkInvoiceCode = this.checkObj.checkInvoiceCode.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    this.checkSMBtnCanCheck(this.checkObj);
    if (e.keyCode == '13') {
      const oneEnter = document.getElementById('fphm');
      oneEnter.focus();
    }
  }
  // 发票号码回车
  checkTwoKeyUp(e) {

    this.checkObj.checkInvoiceNumber = this.checkObj.checkInvoiceNumber.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    this.checkSMBtnCanCheck(this.checkObj);
    if (e.keyCode == '13') {
      const twoEnter = document.getElementById('kprq');
      const inp = twoEnter.querySelector('input');
      inp.focus();
    }
  }
  // 日期回车
  checkThreeKeyUp(e) {
    console.log(e);
    if (!e) {
      const jejym = document.getElementById('jejym');
      jejym.focus();
    }

  }

  // 手/扫描枪 回车功能
  keyUp(e) {
    this.checkSMBtnCanCheck(this.checkObj);
    this.checkObj.checkInvoiceMoney = this.checkObj.checkInvoiceMoney.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    console.log(this.checkObj.checkInvoiceType);
    if (this.checkObj.checkInvoiceType == '01' || this.checkObj.checkInvoiceType == '02' ||
      this.checkObj.checkInvoiceType == '03' || this.checkObj.checkInvoiceType == '15'|| this.checkObj.checkInvoiceType == '08'|| this.checkObj.checkInvoiceType == '09') {
      console.log('金額')
      this.checkObj.checkInvoiceMoney =
        this.checkObj.checkInvoiceMoney.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    } else {
      console.log('驗證碼');
      if (this.checkObj.checkInvoiceMoney.length > 6) {
        this.checkObj.checkInvoiceMoney = this.checkObj.checkInvoiceMoney.slice(0, 6);
      }
    }
    if (e.keyCode == 13) {
      console.log('回车');
      this.checkButClick();
    }
  }
  // 手工-发票代码 回车
  handfpdmEnter(e) {
    this.checkSMBtnCanCheck(this.handCheckObj);
    this.handCheckObj.checkInvoiceCode = this.handCheckObj.checkInvoiceCode.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    if (e.keyCode == '13') {
      const handfphm = document.getElementById('handfphm');
      handfphm.focus();
    }
  }
  // 手工-发票号码回车
  handfphmEnter(e) {

    this.checkSMBtnCanCheck(this.handCheckObj);
    this.handCheckObj.checkInvoiceNumber = this.handCheckObj.checkInvoiceNumber.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    if (e.keyCode == '13') {
      const handkprq = document.getElementById('handkprq');
      const inpu = handkprq.querySelector('input');
      inpu.focus();
    }
  }
  // 手工日期回车
  handkprqEnter(e) {
    console.log(e);
    if (!e) {
      const handjejym = document.getElementById('handjejym');
      handjejym.focus();
    }
  }

  
  // 手工查验 回车功能
  keyUpHand(e) {

    this.checkSMBtnCanCheck(this.handCheckObj);
    this.handCheckObj.checkInvoiceMoney = this.handCheckObj.checkInvoiceMoney.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    console.log(this.handCheckObj.checkInvoiceType);
    if (this.handCheckObj.checkInvoiceType == '01' || this.handCheckObj.checkInvoiceType == '02' ||
      this.handCheckObj.checkInvoiceType == '03' || this.handCheckObj.checkInvoiceType == '15' || this.handCheckObj.checkInvoiceType == '08' || this.handCheckObj.checkInvoiceType == '09') {
      console.log('金額')
      this.handCheckObj.checkInvoiceMoney =
        this.handCheckObj.checkInvoiceMoney.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    } else {
      console.log('驗證碼');
      if (this.handCheckObj.checkInvoiceMoney.length > 6) {
        this.handCheckObj.checkInvoiceMoney = this.handCheckObj.checkInvoiceMoney.slice(0, 6);
      }
    }
    if (e.keyCode == 13) {
      console.log('回车2');
      this.handworkCheckButClick();
    }
  }

  // 查验按钮
  checkButClick() {
    // 011001700111
    // 1400162130
    this.plcyObj.reqSubpackageModel.pageNo = 1;
    this.plcyObj.reqSubpackageModel.pageSize = 5;

    this.dataTreating(this.checkObj);
  }
  // 手工查验按钮
  handworkCheckButClick() {
    this.plcyObj.reqSubpackageModel.pageNo = 1;
    this.plcyObj.reqSubpackageModel.pageSize = 5;


    this.dataTreating(this.handCheckObj);
  }




  // 说明
  explainClick() {
    this.explainTit = eval("HostApp.getHostData('Notice')");
    //  eval("app.SetWindowConver(true)");
    console.log(this.explainTit);
    this.isVisible = true;
    this.explainFlag = true;
    this.checkInvoiceTit = this.explainTit;
  }

  // 设备型号及购买
  unitTypeClick() {
    this.unitTypeObj.serviceId = 'ZXTPLCYFW_SBXHJGM';
    this.httpService.getPublicAttr(this.unitTypeObj);

    const sbxhObj = JSON.stringify(this.unitTypeObj);
    const sbxhEncryptedJson = eval("app.EncryptReqJson(sbxhObj)");
    console.log(sbxhEncryptedJson);
    this.httpService.invCheck('/api/plfpcy.controller', sbxhEncryptedJson).subscribe(sbxhRes => {
      console.log(sbxhRes);
      if (sbxhRes['returnCode'] == '00000000') {
        console.log(333);
        const sbxhResres = JSON.stringify(sbxhRes)
        this.sbxhDecryptedResJson = eval("app.DecryptResJson(sbxhResres)");
        console.log(this.sbxhDecryptedResJson);
        this.endSbxhData = JSON.parse(JSON.parse(this.sbxhDecryptedResJson).ResSubpackage);
        console.log(this.endSbxhData['address']);
        window.open(this.endSbxhData['address'], '_blank')

      } else if (sbxhRes['returnCode'] == '00000002') {

        if (eval("app.ReLogin()")) {
          this.unitTypeClick();
        } else {
          this.returnCodeDispose('00000003')
        }
      }

    });

  }

  // pdf
  customReq = (item: UploadXHRArgs) => {
    console.log(item);
    console.log(item.file);
    console.log(item.file.name)
    this.pdfName.push(item.file.name);
    console.log(this.pdfName);
    // 构建一个 FormData 对象，用于存储文件或其他参数
    this.pdfObj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
    this.pdfObj.orgid = eval("HostApp.getHostData('orgid')");
    this.pdfObj.user_name = eval("HostApp.getHostData('user_name')");
    this.pdfObj.nsrmc = eval("HostApp.getHostData('nsrmc')");
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('files', item.file as any);
    console.log(formData['files'])
    formData.append('headModel', JSON.stringify(this.pdfObj));
    console.log(item.action)
    console.log(formData);
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress: true,
      withCredentials: true
    });
    // 始终返回一个 `Subscription` 对象，nz-upload 会在适当时机自动取消订阅
    return this.http.request(req).subscribe((event: HttpEvent<{}>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total > 0) {
          // tslint:disable-next-line:no-any
          (event as any).percent = event.loaded / event.total * 100;
        }
        // 处理上传进度条，必须指定 `percent` 属性来表示进度
        item.onProgress(event, item.file);
      } else if (event instanceof HttpResponse) {
        // 处理成功
        item.onSuccess(event.body, item.file, event);
        console.log(event.body);

        console.log(item.file);
        console.log(event);

        console.log(this.showFileList);
        this.isSpinning = true;
        console.log(this.endData);
        if (event.body['returnCode'] == '00000000') {
          this.summaryData['total'] = this.summaryData['total'] + JSON.parse(event.body['ResSubpackage']).invoiceCount;
          this.summaryData['checkedCount'] = this.summaryData.checkedCount + JSON.parse(event.body['ResSubpackage']).invoiceCount;
          console.log('pdf返回数据');

          if (this.endData.length < 5) {
            this.residueArray.length = 5 - this.endData.length;
            console.log(this.residueArray.length);
          } else if (this.endData.length == 0) {
            this.residueArray.length = 5;
          }

          this.batchCheck('');

          this.showFileList.push(item.file);

          this.checkInvoiceTit = '共导入发票' + this.showFileList.length + '张';
        } else if (event.body['returnCode'] == '00000002') {

          if (eval("app.ReLogin()")) {
            this.customReq(item);
          } else {
            this.returnCodeDispose('00000003')
          }
        } else if (event.body['returnCode'] == '00000001') {

          console.log(JSON.parse(event.body['ResSubpackage'])[0]);
          if (JSON.parse(event.body['ResSubpackage'])[0].errorMsg == '该文件非标准发票格式，无法导入') {
            this.isVisible = true;
            this.explainFlag = false;
            this.checkInvoiceTit = JSON.parse(event.body['ResSubpackage'])[0].errorMsg;
          } else {
            console.log(JSON.parse(event.body['ResSubpackage'])[0].fileName);
            /*     this.pdfName = JSON.parse(event.body['ResSubpackage'])[0];
                console.log(this.pdfName); */
            console.log(JSON.parse(JSON.parse(event.body['ResSubpackage'])[0].errorMsg).errorMsg);

            this.pdfisVisible = true;
            this.isVisible = false;
            if (JSON.parse(event.body['ResSubpackage']).length > 0) {
              console.log(this.pdfTitList);
              this.pdfTitList.push(JSON.parse(JSON.parse(event.body['ResSubpackage'])[0].errorMsg).errorMsg);


              console.log(this.pdfTitList);
            }
          }

        } else {

          this.returnCodeDispose(event.body['returnCode']);
        }

        console.log(this.showFileList);

      }
    }, (err) => {
      // 处理失败
      item.onError(err, item.file);
    });
  }
  pdfTitHandleCancel() {
    this.pdfName = [];
    this.pdfTitList = [];
    this.pdfisVisible = false;
    this.isSpinning = false;
  }
  pdfTitHandleOk() {
    this.pdfName = [];
    this.pdfTitList = [];
    this.pdfisVisible = false;
    this.isSpinning = false;
  }

  handleCancelPdf() {
    this.isVisiblePdf = false;
    this.isSpinning = false;
  }
  handleOkPdf() {
    this.isVisiblePdf = false;
    this.isSpinning = false;
  }

  // 标准excel 下载
  downExcel() {
    //  / this.excelDownObj
    this.excelDownObj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
    this.excelDownObj.orgid = eval("HostApp.getHostData('orgid')");
    this.excelDownObj.user_name = eval("HostApp.getHostData('user_name')");
    this.excelDownObj.nsrmc = eval("HostApp.getHostData('nsrmc')");
    this.excelDownObj.areaCode = eval("HostApp.getHostData('area_code')");
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('headModel', JSON.stringify(this.excelDownObj));
    this.http.post('/api/downloadBatchCheckExcelTemplate', formData, { 'responseType': 'blob' }).subscribe(res => {
      console.log(res);
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      const fileName = 'Excel导入查验模板' + '.xlsx';

      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', objectUrl);
      a.setAttribute('download', fileName);
      a.click();
      URL.revokeObjectURL(objectUrl);
    })
  }
  // excel
  customReqExcel = (item: UploadXHRArgs) => {

    console.log(item);

    // 构建一个 FormData 对象，用于存储文件或其他参数
    this.excelObj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
    this.excelObj.orgid = eval("HostApp.getHostData('orgid')");
    this.excelObj.user_name = eval("HostApp.getHostData('user_name')");
    this.excelObj.nsrmc = eval("HostApp.getHostData('nsrmc')");
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('files', item.file as any);
    formData.append('headModel', JSON.stringify(this.excelObj));
    console.log(item.action)
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress: true,
      withCredentials: true
    });
    // 始终返回一个 `Subscription` 对象，nz-upload 会在适当时机自动取消订阅
    return this.http.request(req).subscribe((event: HttpEvent<{}>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total > 0) {
          // tslint:disable-next-line:no-any
          (event as any).percent = event.loaded / event.total * 100;
        }
        // 处理上传进度条，必须指定 `percent` 属性来表示进度
        item.onProgress(event, item.file);
      } else if (event instanceof HttpResponse) {
        // 处理成功
        item.onSuccess(event.body, item.file, event);
        console.log(event.body);
        console.log(item.file);
        console.log(event);
        console.log(this.endData);
        this.excelFile = item.file;
        this.isSpinning = true;

        if (event.body['returnCode'] == '00000000') {
          this.summaryData['total'] = this.summaryData['total'] + JSON.parse(event.body['ResSubpackage']).invoiceCount;
          this.summaryData['checkedCount'] = this.summaryData.checkedCount + JSON.parse(event.body['ResSubpackage']).invoiceCount;

          this.batchCheck('')
          if (JSON.parse(event.body['ResSubpackage']).invoiceCount == '0') {
            this.isVisible = true;
            this.checkInvoiceTit = '请输入发票信息';
          } else {
            this.isVisible = true;
            this.checkInvoiceTit = '共导入发票' + JSON.parse(event.body['ResSubpackage']).invoiceCount + '张';

          }
          setTimeout(() => {
            this.isSpinning = false;
          }, 2000)
          console.log('看到这里，我不得不说，兄der你成功了')

          if (this.endData.length < 5) {
            this.residueArray.length = 5 - this.endData.length;
            console.log(this.residueArray.length);
          } else if (this.endData.length == 0) {
            this.residueArray.length = 5;
          }
        } else if (event.body['returnCode'] == '00000002') {

          if (eval("app.ReLogin()")) {
            this.customReqExcel(item);
          } else {
            this.returnCodeDispose('00000003')
          }
        } else if (event.body['returnCode'] == '00000001') {
          console.log('最好看的你，别慌，这不是bug~~~')
          if (JSON.parse(event.body['ResSubpackage'])[0].errorMsg == '超过最大导入数据量：20000') {
            this.isVisible = true;
            this.checkInvoiceTit = '超过最大导入数据量：20000';
          }
          console.log(JSON.parse(event.body['ResSubpackage']));
          console.log(JSON.parse(JSON.parse(event.body['ResSubpackage'])[0].errorMsg)[0].errorMsg)
          if (JSON.parse(event.body['ResSubpackage'])) {
            this.isVisible = true;
            this.explainFlag = false;
            this.checkInvoiceTit = JSON.parse(JSON.parse(event.body['ResSubpackage'])[0].errorMsg)[0].errorMsg;
            // this.titList = JSON.parse(event.body['ResSubpackage']);
          }
          setTimeout(() => {
            this.isSpinning = false;
          }, 2000)
        } else if (event.body['returnCode'] == '00000011') {
          this.listOfData = JSON.parse(event.body['ResSubpackage']);
          this.excelTableFlag = true;
          setTimeout(() => {
            this.isSpinning = false;
          }, 2000)
        } else {
          this.returnCodeDispose(event.body['returnCode']);
        }



      }
    }, (err) => {
      // 处理失败
      item.onError(err, item.file);
    });
  }
  excelTitHandleCancel() {

    this.excelisVisible = false;
  }
  excelTitHandleOk() {

    this.excelisVisible = false;
  }

  //excelUpChange
  excelUpChange(e) {
    console.log(e);
    if (e == true) {
      this.batchCheck('');
    } else {
      this.excelTableFlag = false;
    }
  }

  // 清除按钮
  clearButClick() {

    this.smqValue = '';
    this.smqItem = [];
    this.checkDisFlag = true;
    console.log('清除按钮');
    this.checkObj = {
      checkInvoiceCode: '',
      checkInvoiceDate: '',
      checkInvoiceNumber: '',
      checkInvoiceMoney: '',
      spanTit: '金额/校验码 ：',
      checkInvoiceType: '02',
      invoiceName: '增值税普通发票',

    };
    console.log(this.checkObj);
  }
  // 手工 清除
  handworkClearButClick() {
    this.smqValue = '';
    this.smqItem = [];
    this.handCheckObj = {
      checkInvoiceCode: '',
      checkInvoiceDate: '',
      checkInvoiceNumber: '',
      checkInvoiceMoney: '',
      spanTit: '金额/校验码 ：',
      checkInvoiceType: '02',
      invoiceName: '增值税普通发票',

    };
    this.disFlag = true;
  }


  // 搜索框
  searchFocus() {
    console.log(1);
    console.log(this.searchFpValue);

    this.inpDelMarkFlag = true;
  }
  searchBlur() {
    if (this.searchFpValue) {
      this.inpDelMarkFlag = true;
    } else {
      this.inpDelMarkFlag = false;
    }
  }

  searchInput(e) {
    console.log(this.searchFpValue);
    this.searchFpValue = String(this.searchFpValue).replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');

    console.log(this.endData);
    if (e.keyCode == '13') {
      this.plcyObj.reqSubpackageModel.pageNo = 1;
      this.plcyObj.reqSubpackageModel.pageSize = 5;
      this.batchCheck('');
    } else {
      this.batchCheck('');
    } /* else if (this.searchFpValue == '') {
      this.batchCheck('')
    } */
  }

  searchValueDel() {
    console.log(666666666666);
    this.searchFpValue = '';
    this.inpDelMarkFlag = false;
    this.batchCheck('');
  }
  /* // 剩余次数
  residueNumber() {
    this.httpService.getPublicAttr(this.numObj);
    this.numObj.serviceId = 'ZXTPLCYFW_XFSQ';
    console.log(this.numObj);
    const csObj = JSON.stringify(this.numObj);
    const csEncryptedJson = eval("app.EncryptReqJson(csObj)");
    this.httpService.invCheck('/api/plfpcy.controller', csEncryptedJson).subscribe(csRes => {
      console.log(csRes);
      const csResres = JSON.stringify(csRes);
      const csDecryptedResJson = eval("app.DecryptResJson(csResres)");
      console.log(csDecryptedResJson);
      this.numData = JSON.parse(JSON.parse(csDecryptedResJson).ResSubpackage);
      console.log(this.numData);
      console.log(this.numData['authlavetime']);
      const time = this.numData['authlavetime'];
      console.log(time);
      if (time) {
        eval("app.setCheckRemain()");
      }
    });
  } */


  // 刷新
  refreshBtn() {
    console.log(this.endData);

    const time = eval("HostApp.getHostData('authlavetime')");
    console.log(this.numData);
    setTimeout(() => {

      if (time == '0') {
        this.isVisible = true;
        this.explainFlag = false;
        this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';

      } else {

        // 有 8 的时候验证码破解失败
        const res = this.endData.filter(item => item.result == 8);
        console.log(res);
        if (res.length > 0) {
          this.isVisiblesx = true;
          this.isVisible = false;
          // this.checkInvoiceTit = '当前发票已全部查验完成';
        } else {
          this.endData.forEach(i => {
            if (i.result == '0') {
              this.isVisible = true;
              this.isVisiblesx = false;
              this.checkInvoiceTit = '当前发票正在进行中，请稍后';
              this.isSpinning = false;
            } else if (i.result != 0 && i.result != 8) {
              this.isVisible = true;
              this.isVisiblesx = false;
              this.checkInvoiceTit = '当前发票已全部查验完成';
              this.isSpinning = false;
            }

          })
        }
      }
    }, 2000);




  }
  // 导出发票
  exportInvoiceBtn() {
    if (this.endData) {
      this.exportInvoiceObj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
      this.exportInvoiceObj.orgid = eval("HostApp.getHostData('orgid')");
      this.exportInvoiceObj.user_name = eval("HostApp.getHostData('user_name')");
      this.exportInvoiceObj.nsrmc = eval("HostApp.getHostData('nsrmc')");
      this.exportInvoiceObj.areaCode = eval("HostApp.getHostData('area_code')");
      this.exportInvoiceObj.nsrmc = eval("HostApp.getHostData('nsrmc')");
      if (this.searchFpValue != '') {
        this.exportInvoiceObj.reqSubpackageModel.condition = this.searchFpValue;
      } else {
        this.exportInvoiceObj.reqSubpackageModel.condition = '';
      }
      const formData = new FormData();
      // tslint:disable-next-line:no-any
      formData.append('headModel', JSON.stringify(this.exportInvoiceObj));
      this.http.post('/api/downloadBatchCheckRecord', formData, { 'responseType': 'blob' }).subscribe(res => {
        console.log(res);
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        const fileName = '发票记录' + '.xlsx';
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', fileName);
        a.click();
        console.log(a);
        URL.revokeObjectURL(objectUrl);
        /*     return;
            setTimeout(() => {
              this.isVisible = true;
              this.explainFlag = false;
              this.checkInvoiceTit = 'Excel导出成功';
            }, 600) */

      })
    } else {
      this.isVisible = true;
      this.explainFlag = false;
      this.checkInvoiceTit = '请先导入Excel';
    }

  }
  // 点击发票代码
  fpdmClick(e) {
    console.log(e);
    if (e.sjcyState == '已查验完成') {


      const footerUrl = new Buffer(e.detailUrl, 'base64').toString();
      console.log(footerUrl);

      window.open(footerUrl, '_blank');
    }
  }
  // 提示框确定
  handleOk(): void {
    // eval("app.SetWindowConver(false)");
    this.isVisible = false;
    this.explainFlag = false;
    this.isSpinning = false;
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
    this.explainFlag = false;
    this.isSpinning = false;
  }
  // 重复查验弹框取消
  pophandleCancel(): void {
    this.repePopFlag = false;
    this.explainFlag = false;
  }
  // 重复查验弹框确定
  pophandleOk(): void {
    this.repePopFlag = false;
    this.explainFlag = false;
  }
  // 风险企业弹框取消
  vbhandleCancel() {
    this.ventureBusinessFlag = false;
  }
  vbhandleOk() {
    this.ventureBusinessFlag = false;
  }
  // 继续等待
  sxhandleOk() {
    const jxddObj = {
      "version": eval("HostApp.getHostData('version')"),
      "reqSubpackageModel": { "condition": this.searchFpValue },
      "nsrsbh": eval("HostApp.getHostData('nsrsbh')"),
      "user_name": eval("HostApp.getHostData('user_name')"),
      "orgid": eval("HostApp.getHostData('orgid')"),
      "nsrmc": eval("HostApp.getHostData('nsrmc')"),
    }
    const formData = new FormData();
    formData.append('headModel', JSON.stringify(jxddObj));

    this.httpService.verificationCode('/api/addToInvoiceCheckQueue', formData).subscribe(res => {
      console.log(res);
      if (res['returnCode'] == '00000000') {
        this.isVisiblesx = false;
        this.isSpinning = false;
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {

        } else {
          this.returnCodeDispose('00000003')
        }
      }
    })


  }
  // ×取消
  sxhandleCancels() {
    this.isVisiblesx = false;
    this.isSpinning = false;
  }
  // 人工干预按钮
  sxhandleCancel() {
    this.isSpinning = true;
    console.log(this.endData);

    //  等于0的时候还差，等于8的时候 可以人工干预的继续等待  已完成 没有0 没有8
    // /api/hasVerificationCodeToManual

    const pdrggyObj = {
      nsrmc: eval("HostApp.getHostData('nsrmc')"),
      nsrsbh: eval("HostApp.getHostData('nsrsbh')"),
      user_name: eval("HostApp.getHostData('user_name')"),
      version: eval("HostApp.getHostData('version')"),
      reqSubpackageModel: { "condition": this.searchFpValue },
      orgid: eval("HostApp.getHostData('orgid')"),
    }
    console.log(pdrggyObj);

    const formData = new FormData();
    formData.append('headModel', JSON.stringify(pdrggyObj));

    this.httpService.verificationCode('/api/hasVerificationCodeToManual', formData).subscribe(res => {
      console.log(res);
      if (JSON.parse(res.ResSubpackage).manualFlag == '1') {
        this.currentImgNumber = 1;
        this.isVisiblesx = false;
        this.isVisiblerggy = true;

        if (!this.yzmImgUrl) {
          this.isVisible = true;
          this.explainFlag = false;
          this.checkInvoiceTit = '正在获取验证码请稍后';

          const getVerObj = {
            nsrmc: eval("HostApp.getHostData('nsrmc')"),
            nsrsbh: eval("HostApp.getHostData('nsrsbh')"),
            user_name: eval("HostApp.getHostData('user_name')"),
            version: eval("HostApp.getHostData('version')"),
            reqSubpackageModel: { "condition": this.searchFpValue },
            orgid: eval("HostApp.getHostData('orgid')")
          }
          console.log(getVerObj);
          const getVerData = new FormData();
          getVerData.append('headModel', JSON.stringify(getVerObj));
          this.httpService.verificationCode('/api/selectVerificationCodeToManual', getVerData).subscribe(getVerRes => {
            console.log(getVerRes);
            if (getVerRes.returnCode == '00000000') {
              console.log(JSON.parse(getVerRes.ResSubpackage));
              this.imgTotal = JSON.parse(getVerRes.ResSubpackage).dataCount;
              this.getVerResLists = JSON.parse(getVerRes.ResSubpackage).dataList;
              console.log(this.getVerResLists);
              if (this.getVerResLists.length > 0) {
                this.isSpinning = false;
                this.captchaChange(this.getVerResLists[0]);
              }

            }
          })
        }


      } else if (JSON.parse(res.ResSubpackage).manualFlag == '0') {
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {

        } else {
          this.returnCodeDispose('00000003')
        }
      } else {

      }
    });
  }
  // 获取验证码
  captchaChange(e) {
    this.yzmImgUrl = 'data:image/JPEG;base64,' + e.YZM_IMAGE;
    console.log(this.yzmImgUrl);
    this.imgcolorYs = e.YZM_TS;
    if (this.imgcolorYs == '00') {
      this.imgColorTit = '黑色';
    } else if (this.imgcolorYs == '01') {
      this.imgColorTit = '红色';
    } else if (this.imgcolorYs == '02') {
      this.imgColorTit = '黄色';
    } else if (this.imgcolorYs == '03') {
      this.imgColorTit = '蓝色';
    } else {
      this.imgColorTit = '黑色';
    }
  }

  // 验证码刷新
  yzmImgClick() {
    //    this.verificationCode();
    const yzmImgRefreshObj = {
      nsrsbh: eval("HostApp.getHostData('nsrsbh')"),
      user_name: eval("HostApp.getHostData('user_name')"),
      version: eval("HostApp.getHostData('version')"),
      orgid: eval("HostApp.getHostData('orgid')"),
      reqSubpackageModel: {
        batchId: this.getVerResLists[0].batchId,
        condition: this.searchFpValue
      }
    }
    console.log(yzmImgRefreshObj)
    const yzmImgRefreshData = new FormData();
    yzmImgRefreshData.append('headModel', JSON.stringify(yzmImgRefreshObj));
    this.httpService.verificationCode('/api/refreshVerificationCodeToManual', yzmImgRefreshData).subscribe(yzmImgRefreshRes => {
      console.log(yzmImgRefreshRes)
      this.refreshList = JSON.parse(yzmImgRefreshRes.ResSubpackage);
      console.log(this.refreshList);
      this.getVerResLists.shift();
      this.getVerResLists.unshift(this.refreshList.dataResult);
      this.captchaChange(this.getVerResLists[0])
      console.log(this.getVerResLists);
    });
  }

  // 人工干预
  rggyhandleOk() {
    console.log(this.yamValue);
    console.log(this.getVerResLists[0])

    if (this.yamValue) {
      const encode = encodeURI(this.yamValue);
      const base64 = btoa(encode);
      const rggyOkObj = {
        nsrmc: eval("HostApp.getHostData('nsrmc')"),
        nsrsbh: eval("HostApp.getHostData('nsrsbh')"),
        user_name: eval("HostApp.getHostData('user_name')"),
        version: eval("HostApp.getHostData('version')"),
        orgid: eval("HostApp.getHostData('orgid')"),
        areaCode: eval("HostApp.getHostData('area_code')"),
        reqSubpackageModel: {
          batchId: this.getVerResLists[0].batchId,
          yzm_id: this.getVerResLists[0].YZM_ID,
          invoice_id: this.getVerResLists[0].invoice_id,
          key6: this.getVerResLists[0].key6,
          yzm_info: base64
        }
      }
      const rggyOkData = new FormData();
      rggyOkData.append('headModel', JSON.stringify(rggyOkObj));
      this.httpService.verificationCode('/api/submitVerificationCode', rggyOkData).subscribe(rggyOkRes => {
        console.log(rggyOkRes)
        if (this.currentImgNumber >= this.imgTotal) {
          this.currentImgNumber = this.imgTotal;
        } else {
          this.currentImgNumber += 1;
        }

        const newList = this.getVerResLists.shift();
        console.log(newList);
        console.log(this.getVerResLists);
        if (this.getVerResLists.length > 0) {
          this.captchaChange(this.getVerResLists[0]);
        }
        this.yamValue = '';
        if (this.getVerResLists.length == 0) {
          this.isVisiblerggy = false;
        }
      })
    } else {
      this.isVisible = true;
      this.checkInvoiceTit = '请输入验证码';
    }

  }
  rggyhandleCancel() {
    this.isVisiblerggy = false;
    this.isSpinning = false;
  }


  // 悬停
  hoverTip() {
    this.tipFlag = true;
  }
  outTip() {
    this.tipFlag = false;
  }

  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    if (resDataTypeNumber == '02') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
    } else if (resDataTypeNumber == '03') {
      this.isVisible = true;
      this.checkInvoiceTit = '查无此票';
    } else if (resDataTypeNumber == '04') {
      this.isVisible = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
      this.fileList = [];
    } else if (resDataTypeNumber == '05') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    } else if (resDataTypeNumber == '06') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    }
    this.isSpinning = false;
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    if (returnCodeNumber == '00000001') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票数据错误';
    } else if (returnCodeNumber == '00000002') {
      // app.ReLogin()
      this.isVisible = true;
      this.checkInvoiceTit = '重新登录';

    } else if (returnCodeNumber == '00000003') {
      this.isVisible = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisible = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisible = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisible = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisible = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }

    this.isSpinning = false;
  }

  setRemark(e, index) {
    this.remarkData = {};
    this.isMarkVisible = true;
    this.remarkValue = e.remark ? e.remark : '';
    this.remarkData = e;
    this.clickIndex = index;
  }

  markKeyUp() {
    this.remarkValue = this.remarkValue.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im, '').replace(/[, ]/g, '');

  }

  handleMarkOk() {
    let object = {
      "nsrsbh": eval("HostApp.getHostData('nsrsbh')"),
      "user_name": eval("HostApp.getHostData('user_name')"),
      "orgid": eval("HostApp.getHostData('orgid')"),
      "reqSubpackage": {
        "remark": this.remarkValue ? this.remarkValue : '',
        "id": this.remarkData['id'],
        // "invoiceId": this.remarkData['invoiceId'],
        "fpdm": this.remarkData['fpdm'],//021021900104
        "fphm": this.remarkData['fphm'],//00171727
        "jejym": this.remarkData['jejym'],//846624
      },
      "nsrmc": eval("HostApp.getHostData('nsrmc')"),
      "areaCode": '',
    }
    console.log(object);

    const data = new FormData();
    data.append('headModel', JSON.stringify(object));
    this.httpService.markSet('/api/updateBatchTagRecord', data).subscribe(yzmxqRes => {
      this.isMarkVisible = false;
      // this.batchCheck('');// 更新
      this.endData.forEach((p, i) => {
        if (i === this.clickIndex) {
          p.remark = this.remarkValue;
        }
      })
      this.endData = [...this.endData];
    })


  }

  handleMarkCancel() {
    this.isMarkVisible = false;
    this.clickIndex = '';
  }



  //扫描
  checkSMBtnCanCheck(e: any) {
    console.log(' checkSMBtnCanCheck e :>> ', e);
    if (e.checkInvoiceNumber.length > 0 && e.checkInvoiceMoney.length > 0) {
      if (
        (e.checkInvoiceNumber.length == 8 && (e.checkInvoiceCode.length == 10 || e.checkInvoiceCode.length == 12)) ||
        (e.checkInvoiceNumber.length == 20 && (e.checkInvoiceCode.length == 0 || !e.checkInvoiceCode))
      ) {
        if (e.checkInvoiceType == "S1") {
          if (e.jym) {
            this.checkDisFlag = false;
          } else {
            this.checkDisFlag = true;
          }
        } else {
          this.checkDisFlag = false;
        }
      } else {
        this.checkDisFlag = true;
      }
    } else {
      this.checkDisFlag = true;
    }
  }


}
