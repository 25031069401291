import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
@Component({
  selector: 'app-buyname-set',
  templateUrl: './buyname-set.component.html',
  styleUrls: ['./buyname-set.component.css']
})
export class BuynameSetComponent implements OnInit {
  dataSet = [
  ];
  setArray = [1];
  gfmcList: any;
  nameValue: any;
  tit = '本单位别名';
  isVisible = false;
  isVisibleTip = false;
  editFlag = false;
  delFlag = false;
  delList: any;
  nameEditValue: any;
  gfmcObj = {
    "version": "1.0",
    "nsrsbh": "",
    "user_name": "管理用户",
    "nsrmc": "",
    "orgid": ""
  };
  addObj = {
    "nsrsbh": "",
    "user_name": "管理用户",
    "orgid": "",
    "reqSubpackageModel": {
      "nsrmc": "",
      "alias": ""
    },
    "nsrmc": "",
    "areaCode": "110"
  };
  delObj = {
    "nsrsbh": "",
    "user_name": "管理用户", "orgid": "",
    "reqSubpackageModel": {
      "id": ""
    },
    "nsrmc": "",
    "areaCode": "110"
  };
  editObj = {
    "nsrsbh": "",
    "user_name": "管理用户",
    "orgid": "",
    "reqSubpackageModel": {
      "id": "",
      "nsrmc": ""
    },
    "nsrmc": "", "areaCode": "110"
  }

  constructor(
    private httpService: HttpService,
  ) { }
  ngOnInit() {
    this.getBuyersName();

  }
  getBuyersName() {
    this.httpService.newGetPublicAttr(this.gfmcObj);
    console.log(this.gfmcObj);
    const gfmcData = new FormData();
    gfmcData.append('headModel', JSON.stringify(this.gfmcObj));
    this.httpService.invoiceCheckoutRecords('/api/selectTaxpayerAliasList', gfmcData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(JSON.parse(res.ResSubpackage).dataList);
        this.gfmcList = JSON.parse(res.ResSubpackage).dataList.filter(item => !item.id);
        console.log(this.gfmcList);
        this.dataSet = JSON.parse(res.ResSubpackage).dataList.filter(item => item.id);
      }
    });
  }
  // 别名
  nameValueKeyup() {
    console.log(this.nameValue);
    this.nameValue = this.nameValue.replace(/^\s+|\s+$/g, '');
    if (this.nameValue.length > 20) {
      this.nameValue = this.nameValue.slice(0, 20);
    }
  }
  // 别名添加
  aliasAddClick() {
    this.tit = '本单位别名';
    this.delFlag = false;
    this.nameValue = '';
    this.isVisible = true;
    this.editFlag = false;
  }
  // 修改
  nameEdit(e) {
    console.log(e);
    this.isVisible = true;
    this.tit = '本单位别名';
    this.nameValue = e.nsrmc;
    this.nameEditValue = e;
    this.editFlag = true;
    this.delFlag = false;
  }
  // 删除
  nameDel(e) {
    this.tit = '提示';
    this.isVisible = true;
    this.delFlag = true;
    this.delList = e;
  }
  // 提示框确定
  handleOk(): void {

    console.log(this.delFlag);
    console.log(this.delList);
    if (this.delFlag) {
      console.log(this.dataSet);
      this.delAlias();
      this.getBuyersName();
    } else {
      if (this.nameValue != '') {
        console.log(this.dataSet);
        console.log(this.nameValue);
        const res = this.dataSet.find(k => k.nsrmc == this.nameValue);
        console.log(res);
        if (res) {
          this.isVisibleTip = true;
          this.tit = '该别名已存在';
        } else {
          this.isVisible = false;
          if (this.editFlag) {
            this.editAlias();
          } else {
            this.addAlias();
          }
        }
      } else {
        this.isVisibleTip = true;
        this.tit = '请输入别名';
      }
    }

    //  this.isVisible = false;
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
  }
  // 添加
  addAlias() {
    this.httpService.newGetPublicAttr(this.addObj);
    console.log(this.addObj);
    this.addObj.reqSubpackageModel.nsrmc = this.nameValue;
    const addData = new FormData();
    addData.append('headModel', JSON.stringify(this.addObj));
    this.httpService.invoiceCheckoutRecords('/api/insertTaxpayerAlias', addData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        this.isVisibleTip = true;
        console.log(this.editFlag);

        this.tit = res.ResSubpackage;

        this.getBuyersName();
      } else {
        this.isVisibleTip = true;
        this.tit = res.ResSubpackage;
      }
    });
  }
  // 删除
  delAlias() {
    console.log(this.delList);
    this.httpService.newGetPublicAttr(this.delObj);
    console.log(this.delObj);
    this.delObj.reqSubpackageModel.id = this.delList.id;
    const delData = new FormData();
    delData.append('headModel', JSON.stringify(this.delObj));
    this.httpService.invoiceCheckoutRecords('/api/deleteTaxpayerAlias', delData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        this.isVisibleTip = true;
        this.tit = '删除成功';
        this.isVisible = false;
        this.getBuyersName();
      } else {
        this.isVisibleTip = true;
        this.tit = '删除数据失败';
      }
    });
  }
  // 修改
  editAlias() {
    this.httpService.newGetPublicAttr(this.editObj);
    console.log(this.nameValue);
    this.editObj.reqSubpackageModel.nsrmc = this.nameValue;
    this.editObj.reqSubpackageModel.id = this.nameEditValue.id;
    console.log(this.editObj);
    const editData = new FormData();
    editData.append('headModel', JSON.stringify(this.editObj));
    this.httpService.invoiceCheckoutRecords('/api/updateTaxpayerAlias', editData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        this.isVisibleTip = true;
        this.tit = '修改成功';
        this.getBuyersName();
      } else {
        this.isVisibleTip = true;
        this.tit = '修改数据失败';
      }
    });
  }

  tipHandleCancel() {
    this.isVisibleTip = false;
  }
  tipHandleOk() {
    this.isVisibleTip = false;
  }

}
