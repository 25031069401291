import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Buffer } from 'buffer';
import * as moment from 'moment';

@Component({
  selector: 'app-flow-record',
  templateUrl: './flow-record.component.html',
  styleUrls: ['./flow-record.component.css']
})
export class FlowRecordComponent implements OnInit {
  allCheckedFlag = false;
  normalCheckedFlag = false;
  newEndPre: any;
  newkpEndPre: any;
  listOfAllData: any[] = [];
  cyrList: any;
  checkresult = "";
  isVisible = false;
  checkInvoiceTit = '';
  gfName = '全部';
  allData: any;
  timer: any;
  isTrue = false;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  delBtnShow = false;
  ventureBusinessFlag = false;
  delInvoiceVisible = false;
  fxqyDecryptedResJson: any;
  cfcyDecryptedResJson: any;
  listOfDisplayData: any[] = [];
  endFxqyData: {};
  endCfcyData: any;
  repePopFlag = false;
  cfcyFlag = false;
  cfcyTit: any;
  total = 1;
  idsArray: any[] = [];
  cyrValue = '全部';
  dateFormat = 'yyyy-MM-dd';
  disFlag = false;
  checkFlag = false;
  dataFlag = true;
  isSpinning = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  noData = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 },]
  buttonItems = [
    { name: '发票正常', bgColor: 'gary', tip: '', },
    { name: '重复查验', bgColor: 'gary', tip: '', },
    { name: '购方查验', bgColor: 'gary', tip: '', },
    { name: '禁报查验', bgColor: 'gary', tip: '', },
    { name: '黑名单查验', bgColor: 'gary', tip: '', },
    { name: '风险企业', bgColor: 'gary', tip: '', },
  ];
  //风险企业
  vbObj = {
    "ReqSubpackage": {
      "token": "",
      "invoiceId": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_FXQYCX",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 重复查验
  repeObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "fpdm": "",
      "fphm": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_CFCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  checkboxStateList = [
    { name: '重复查验', value: '重复查验', ngModelFlag: false, },
    { name: '非购方发票', value: '非购方发票', ngModelFlag: false, },
    { name: '禁报商品', value: '禁报商品', ngModelFlag: false, },
    { name: '销方黑名单', value: '销方黑名单', ngModelFlag: false, },
    { name: '风险企业', value: '风险企业', ngModelFlag: false, },
  ];
  checkRecordsObj = {
    "nsrsbh": "", "user_name": "管理用户", "orgid": "",
    "reqSubpackageModel": {
      "invoiceCheckDateStart": "",
      "invoiceCheckDateEnd": "",
      "cfcyChecked": "",
      "fgfChecked": "",
      "jbspChecked": "",
      "xfhmdChecked": "",
      "fxqyChecked": "",
      "result": "",
      "fpdm": "",
      "fphm": "",
      "cyr": "",
      "pageNo": "1",
      "pageSize": "10"
    }
  };
  cyrObj = {
    "version": "1.0",
    "nsrsbh": "",
    "user_name": "",
    "nsrmc": "",
    "orgid": ""
  };

  delObj = {
    "nsrsbh": "",
    "user_name": "管理用户",
    "orgid": "",
    "reqSubpackageModel": { "ids": [] },
    "nsrmc": "",
    "areaCode": "110"
  };


  constructor(
    private httpService: HttpService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    let moth = moment().subtract(3, "months").format("YYYY-MM-DD"); //当前时间的前3个月时间
    let now = moment().format("YYYY-MM-DD");
    console.log(moth);
    console.log(now);
    this.getCheckPerson();
    console.log(this.listOfAllData);
    // const d = new Date();
    // this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd =
    //   d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
    //   '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
    // this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart =
    //   d.getFullYear() + '-' + ((d.getMonth() - 2) < 10 ? '0' + (d.getMonth() - 2) : (d.getMonth() - 2)) +
    //   '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));)
    this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart = moth;
    this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd = now;
  }

  // 不可选择的时间 -- 开始时间
  disabledcheckStartDate = (current: Date) => {
    const pre = new Date();
    //  业务逻辑调整 可查询五年内数据 20200206
    pre.setFullYear(pre.getFullYear() - 5);
    const ymData = new Date();
    return current >= ymData || current < pre;
  }
  // 不可选择的时间 -- 结束时间
  disabledcheckEndDate = (current: Date) => {
    const pre = new Date();
    //  业务逻辑调整 可查询五年内数据 20200206
    pre.setFullYear(pre.getFullYear() - 5);
    const ymData = new Date();
    return current >= ymData || current < pre;
  }

  // 查验时间开始
  checkDateStartChange(e) {
    /*     const d = new Date(e);
        this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart =
          d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
          '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())); */
  }
  // 查验时间结束
  checkDateEndChange(e) {
    /*    const d = new Date(e);
       this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd =
         d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
         '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())); */
  }

  // 查验人
  getCheckPerson() {

    this.httpService.newGetPublicAttr(this.cyrObj);
    console.log(this.cyrObj);
    if (this.cyrObj.user_name === '管理用户') {
      this.delBtnShow = true;
    }
    const cyrData = new FormData();
    cyrData.append('headModel', JSON.stringify(this.cyrObj));
    this.httpService.invoiceCheckoutRecords('/api/getInspectors', cyrData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(JSON.parse(res.ResSubpackage).dataList);
        this.cyrList = JSON.parse(res.ResSubpackage).dataList;
      }
    });

  }
  // 查验人change
  cyrChange() {
    console.log(this.cyrValue);
    if (this.checkFlag) {
      this.recordsCheckClick();
    }
  }

  cyztChange() {
    console.log(this.checkresult);

    if (this.checkresult == '3') {
      this.checkboxStateList.forEach(item => {
        item.ngModelFlag = false;
      });
      this.disFlag = true;
    } else {
      this.disFlag = false;
    }
    if (this.checkFlag) {
      this.recordsCheckClick();
    }
  }

  // 筛选 -- 全部
  allChange(e) {
    console.log(e);
    if (this.checkFlag) {
      if (e == true) {
        this.normalCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });

        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.recordsCheckClick();
        console.log(this.checkboxStateList);
      } else {
        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.recordsCheckClick();
      }
    } else {
      if (e == true) {
        this.normalCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });
      }
    }

  }
  // 筛选 -- 正常
  normalChange(e) {
    console.log(e);
    if (this.checkFlag) {
      if (e == true) {
        this.allCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });
        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.checkresult = '1';
        this.recordsCheckClick();
      } else {
        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.checkresult = '';
        this.recordsCheckClick();
      }
    } else {
      if (e == true) {
        this.allCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });
      }
    }
  }
  // 筛选 -- 细则
  sxCheckbox(e) {
    console.log(e);
    console.log(this.checkboxStateList);
    if (e.length > 0) {
      this.allCheckedFlag = false;
      this.normalCheckedFlag = false;
    }
  }
  // 筛选细则 点击事件
  labelChange(e) {
    console.log(e);
    this.checkboxStateList.forEach(item => {
      if (item.name == e.name) {
        item.ngModelFlag = !item.ngModelFlag;
        if (this.checkFlag) {
          if (this.checkRecordsObj.reqSubpackageModel.cfcyChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.fgfChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.jbspChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.fxqyChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.xfhmdChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
          }
          switch (item.name) {
            case '重复查验':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '非购方发票':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.fgfChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '禁报商品':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.jbspChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '销方黑名单':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '风险企业':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
              }
              this.recordsCheckClick();
              break;
            default: break;
          }
        }
      }
    });
    console.log(this.checkboxStateList);

  }
  // 查询按钮
  recordsCheckClick() {
    // fpdmValue fphmValue
    this.checkFlag = true;
    this.isSpinning = true;
    if (this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart && this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd) {
      // const d = new Date(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart);
      // this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart =
      //   d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
      //   '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));

      this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart = this.dateDo(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart, "yyyy-MM-dd");
      this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd = this.dateDo(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd, "yyyy-MM-dd");
      console.log(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd)
      if (this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart <= this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd) {
        if (this.cyrValue == '全部') {
          this.checkRecordsObj.reqSubpackageModel.cyr = '';
        } else {
          this.checkRecordsObj.reqSubpackageModel.cyr = this.cyrValue;
        }
        this.checkRecordsObj.reqSubpackageModel.result = this.checkresult;
        this.checkFn();
      } else {
        this.isVisible = true;
        this.checkInvoiceTit = '请选择正确查验时间';
      }


    } else {
      this.isVisible = true;
      this.checkInvoiceTit = '查验时间不能为空';
    }
  }
  checkFn() {
    this.httpService.newGetPublicAttr(this.checkRecordsObj);
    console.log(this.checkRecordsObj);
    const formData = new FormData();
    formData.append('headModel', JSON.stringify(this.checkRecordsObj));
    this.httpService.invoiceCheckoutRecords('/api/selectInvoiceCheckRecordPage', formData).subscribe(res => {
      console.log(JSON.parse(res.ResSubpackage).pageInfo);
      if (res.returnCode == '00000000') {
        this.listOfAllData = JSON.parse(res.ResSubpackage).pageInfo.list;
        if (this.listOfAllData.length == 0) {
          this.dataFlag = false;
        } else {
          this.dataFlag = true;
        }
        if (this.listOfAllData) {
          this.isSpinning = false;
        }
        this.allData = JSON.parse(res.ResSubpackage).pageInfo;
        this.total = this.allData.total;
        this.listOfDisplayData = this.listOfAllData;
        this.refreshStatus('');
        this.listOfAllData.map(item => {
          item.btnState = this.buttonItems;
          console.log(item);
          item.yzmSj = item.yzmSj.slice(0, 10);
          this.showButtonState(item);
          if (item.result == "1") {
            item.result = '存在发票';
            item.sjcyColor = 'titBlack';
          } else if (item.result == "3") {
            item.result = '不存在发票';
            item.sjcyColor = 'titRed';
          } else if (item.result == '0') {
            item.result = '等待查验';
            item.sjcyColor = 'titGary';
          } else if (item.result == '9') {
            item.result = '查验异常';
            item.sjcyColor = 'titRed';
          }
          return item;
        });
        console.log(this.listOfAllData);
      } else if (res.returnCode == '00000002') {

        if (eval("app.ReLogin()")) {

        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }

  // 导出发票
  exportInvoiceBtn() {
    if (this.listOfAllData.length != 0) {
      this.httpService.newGetPublicAttr(this.checkRecordsObj);
      this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
      this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
      this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
      this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
      this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
      this.checkRecordsObj.reqSubpackageModel.result = '';
      this.checkRecordsObj.reqSubpackageModel.cyr = '';
      console.log(this.checkRecordsObj);
      const formData = new FormData();
      formData.append('headModel', JSON.stringify(this.checkRecordsObj));
      this.http.post('/api/downloadInvoiceCheckRecord', formData, { 'responseType': 'blob' }).subscribe(res => {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        const fileName = '发票流水记录' + '.xlsx';

        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', fileName);
        a.click();
        URL.revokeObjectURL(objectUrl);

      });
    } else {
      this.isVisible = true;
      this.checkInvoiceTit = '请查询发票记录';
    }

  }
  // 删除发票流水  一次只允许删除一条
  delFlowBtn() {
    if (this.delObj.reqSubpackageModel.ids.length === 1) {
      this.delInvoiceVisible = true;
    } else if (this.delObj.reqSubpackageModel.ids.length > 1 ) {
      this.isVisible = true;
      this.checkInvoiceTit = ' 不允许同时删除多条记录';
    } else {
      this.isVisible = true;
      this.checkInvoiceTit = ' 请选择一条发票记录';
    }
  }
  // 删除发票流水  一次只允许删除一条
  delInvoiceFlow() {
    this.httpService.newGetPublicAttr(this.delObj);
    console.log(this.delObj);
    const formData = new FormData();
    formData.append('headModel', JSON.stringify(this.delObj));
    this.http.post('/api/delInvoiceFlow', formData).subscribe(res => {
      console.log(res)
      if (res["returnCode"] === '00000000') {
        this.delObj.reqSubpackageModel.ids = [];
        this.idsArray = [];
        console.log(this.delObj.reqSubpackageModel.ids);
        console.log(this.idsArray);
        this.isVisible = true;
        this.checkInvoiceTit = '删除成功';
        this.recordsCheckClick();
      } else {
        this.isVisible = true;
        this.checkInvoiceTit = '删除失败，请刷新后重试';
        this.recordsCheckClick();
      }

    });
  }
  // 点击发票号码
  fphmDetailClick(e) {
    console.log(e);
    const footerUrl = new Buffer(e.detailUrl, 'base64').toString();
    console.log(footerUrl);

    window.open(footerUrl, '_blank');
  }

  // 提示框确定
  handleOk(): void {
    // eval("app.SetWindowConver(false)");
    this.isVisible = false;
    this.isSpinning = false;
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
    this.isSpinning = false;
  }

  // table


  // 表格 方法
  refreshStatus(e): void {
    console.log(e);
    console.log(this.listOfAllData);
    this.listOfAllData.forEach(item => {
      console.log(this.mapOfCheckedId[item.id]);

      if (this.mapOfCheckedId[item.id]) {
        item.mapOfCheckedId = true;
      } else {
        item.mapOfCheckedId = false;
      }
    });
    console.log(this.listOfDisplayData);
    console.log(this.mapOfCheckedId);
    this.idsArray = this.listOfAllData.filter(k => k.mapOfCheckedId);
    console.log(this.idsArray);
    this.delObj.reqSubpackageModel.ids = [];
    this.idsArray.forEach(l => {
      console.log(l);
      this.delObj.reqSubpackageModel.ids.push(l.id);
    });
    console.log(this.delObj.reqSubpackageModel.ids);
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus('');
  }
  pageIndexChange(e) {
    console.log(e);
    this.checkRecordsObj.reqSubpackageModel.pageNo = e;
    this.checkFn();
  }



  butClick(e, cont) {
    console.log(e);
    this.cfcyBtn(e, cont);
    console.log(cont)

    // 风险企业
    if (e.bgColor == 'red') {
      if (e.name == '风险企业') {
        this.ventureBusinessFlag = true;
        this.vbObj.serviceId = 'ZXTPLCYFW_FXQYCX';
        this.vbObj.ReqSubpackage.invoiceId = cont.invoiceId;
        this.httpService.getPublicAttr(this.vbObj);
        console.log(this.vbObj);
        const fxqyObj = JSON.stringify(this.vbObj);
        const fxqyEncryptedJson = eval("app.EncryptReqJson(fxqyObj)");
        console.log(fxqyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', fxqyEncryptedJson).subscribe(fxqyRes => {
          console.log(fxqyRes);
          console.log(4444444);
          if (fxqyRes.returnCode == '00000000') {
            console.log(6666);
            const fxqyResres = JSON.stringify(fxqyRes);
            this.fxqyDecryptedResJson = eval("app.DecryptResJson(fxqyResres)");
            console.log(this.fxqyDecryptedResJson);
            this.endFxqyData = JSON.parse(JSON.parse(this.fxqyDecryptedResJson).ResSubpackage);
            console.log(this.endFxqyData);
          } else if (fxqyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';
            if (eval("app.ReLogin()")) { }

          } else {
            this.returnCodeDispose(fxqyRes.returnCode);
          }

        });
      }
    } else {
      this.ventureBusinessFlag = false;
    }
  }

  // 重复查验按钮
  cfcyBtn(e, cont) {
    if (e.bgColor == 'red') {
      if (e.name == '重复查验') {
        this.repePopFlag = true;
        this.repeObj.serviceId = 'ZXTPLCYFW_CFCY';
        this.httpService.getPublicAttr(this.repeObj);
        if (cont) {
          this.repeObj.ReqSubpackage.fpdm = cont.fpdm;
          this.repeObj.ReqSubpackage.fphm = cont.fphm;
        }


        console.log(this.repeObj);
        const cfcyObj = JSON.stringify(this.repeObj);
        const cfcyEncryptedJson = eval("app.EncryptReqJson(cfcyObj)");
        console.log(cfcyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', cfcyEncryptedJson).subscribe(cfcyRes => {
          console.log(cfcyRes);
          console.log(333333);
          if (cfcyRes.returnCode == '00000000') {
            console.log(6666);

            this.cfcyFlag = false;
            const cfcyResres = JSON.stringify(cfcyRes);
            this.cfcyDecryptedResJson = eval("app.DecryptResJson(cfcyResres)");
            console.log(this.cfcyDecryptedResJson);
            this.endCfcyData = JSON.parse(JSON.parse(JSON.parse(this.cfcyDecryptedResJson).ResSubpackage).cfcyList);
            console.log(this.endCfcyData);
            this.endCfcyData.map(item => {
              if (item.cyfs == '1') {
                item.cyfsZn = '客户端';
              } else if (item.cyfs == '2') {
                item.cyfsZn = '手机端';
              } else if (item.cyfs == '3') {
                item.cyfsZn = '发票查验API';
              }
            })
          } else if (cfcyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';

          } else if (cfcyRes.returnCode == '00000002') {
            if (eval("app.ReLogin()")) {
              if (e) {
                this.cfcyBtn(e, cont);
              }
            } else {
              this.returnCodeDispose('00000003')
            }
          } else {
            this.returnCodeDispose(cfcyRes.returnCode);
          }

        });
      }
    } else {
      this.repePopFlag = false;
    }
  }

  // 风险企业弹框取消
  vbhandleCancel() {
    this.ventureBusinessFlag = false;
  }
  vbhandleOk() {
    this.ventureBusinessFlag = false;
  }
  // 重复查验弹框取消
  pophandleCancel(): void {
    this.repePopFlag = false;
  }
  // 重复查验弹框确定
  pophandleOk(): void {
    this.repePopFlag = false;
  }
  // 删除发票流水弹出框
  delHandleCancel(): void {
    this.delInvoiceVisible = false;
  }
  // 删除发票流水弹出框
  delHandleOk(): void {
    this.delInvoiceVisible = false;
    // 删除发票流水记录
    this.delInvoiceFlow();
  }


  // 按钮
  showButtonState(item) {
    item.btnState = [
      { name: '发票正常', bgColor: 'gary', tip: '', },
      { name: '重复查验', bgColor: 'gary', tip: '', },
      { name: '购方查验', bgColor: 'gary', tip: '', },
      { name: '禁报查验', bgColor: 'gary', tip: '', },
      { name: '黑名单查验', bgColor: 'gary', tip: '', },
      { name: '风险企业', bgColor: 'gary', tip: '', },
    ];
    if (item.result == '1') {
      if (item.fpzt == 2) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '发票作废';
      } else if (item.fpzt == 3) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '发票红冲';
      } else if (item.fpzt == 7) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '部分红冲';
      } else if (item.fpzt == 8) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '全额红冲';
      } else {
        item.btnState[0].bgColor = 'blue';
        item.btnState[0].name = '发票正常';
      }
      if (item.cfcy == 1) {
        item.btnState[1].bgColor = 'red';
      }
      if (item.cfcy == 0) {
        item.btnState[1].bgColor = 'blue';
      }

      if (item.gfcy == 1) {
        item.btnState[2].bgColor = 'blue';
      } else {
        item.btnState[2].bgColor = 'red';
        item.btnState[2].name = '购方查验、非本单位购方名称';
      }

      if (item.jbcy == 1) {
        item.btnState[3].bgColor = 'red';
      } else {
        item.btnState[3].bgColor = 'blue';
      }
      if (item.hmdcy == 1) {
        item.btnState[4].bgColor = 'red';
      } else {
        item.btnState[4].bgColor = 'blue';
      }
      if (item.fxqy == 1) {
        item.btnState[5].bgColor = 'red';
      } else {
        item.btnState[5].bgColor = 'blue';
      }
      return item;
    }
  }

  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    if (resDataTypeNumber == '02') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
    } else if (resDataTypeNumber == '03') {
      this.isVisible = true;
      this.checkInvoiceTit = '查无此票';
    } else if (resDataTypeNumber == '04') {
      this.isVisible = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
    } else if (resDataTypeNumber == '05') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    } else if (resDataTypeNumber == '06') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    }
    this.isSpinning = false;
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    if (returnCodeNumber == '00000001') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票数据错误';
    } else if (returnCodeNumber == '00000002') {
      this.isVisible = true;
      this.checkInvoiceTit = '重新登录';
    } else if (returnCodeNumber == '00000003') {
      this.isVisible = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisible = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisible = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisible = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisible = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }
    this.isSpinning = false;
  }

  dateDo(timestamp, format) {
    let date = timestamp instanceof Date ? timestamp : new Date(timestamp);
    const o = {
      "y{4}": date.getFullYear(),
      "MM": date.getMonth() + 1,
      "dd": date.getDate(),
      "hh": date.getHours(),
      "mm": date.getMinutes(),
      "ss": date.getSeconds()
    };
    for (let i in o) {
      if (new RegExp('(' + i + ')').test(format)) {
        format = format.replace(RegExp.$1, o[i] < 10 ? '0' + o[i] : o[i]);
      }
    }
    return format;
  };

}
