import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { balancePreviousStylesIntoKeyframes } from '@angular/animations/browser/src/util';
import { isTemplateRef } from 'ng-zorro-antd';
import { Buffer } from 'buffer';
import * as moment from 'moment';
@Component({
  selector: 'app-inspection-records',
  templateUrl: './inspection-records.component.html',
  styleUrls: ['./inspection-records.component.css']
})
export class InspectionRecordsComponent implements OnInit {
  allCheckedFlag = false;
  normalCheckedFlag = false;
  newEndPre: any;
  newkpEndPre: any;
  today = new Date();
  listOfAllData: any[] = [];
  gfNameList: any;
  checkresult = "";
  isVisible = false;
  checkInvoiceTit = '';
  gfName = '全部';
  fplx = '全部';
  allData: any;
  timer = null;
  isTrue = false;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  ventureBusinessFlag = false;
  fxqyDecryptedResJson: any;
  cfcyDecryptedResJson: any;
  listOfDisplayData: any[] = [];
  endFxqyData: {};
  endCfcyData: any;
  repePopFlag = false;
  cfcyFlag = false;
  cfcyTit: any;
  total = 1;
  mapOfCheckedId: { [key: string]: boolean } = {};
  idsArray: any;
  disFlag = false;
  dataFlag = true;
  checkFlag = false;
  noData = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }];
  dateFormat = 'yyyy-MM-dd';
  day1: any;
  day2: any;
  isSpinning = false;
  resiNumber: any;
  buttonItems = [
    { name: '发票正常', bgColor: 'gary', tip: '', },
    { name: '重复查验', bgColor: 'gary', tip: '', },
    { name: '购方查验', bgColor: 'gary', tip: '', },
    { name: '禁报查验', bgColor: 'gary', tip: '', },
    { name: '黑名单查验', bgColor: 'gary', tip: '', },
    { name: '风险企业', bgColor: 'gary', tip: '', },
  ];
  //风险企业
  vbObj = {
    "ReqSubpackage": {
      "token": "",
      "invoiceId": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_FXQYCX",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 重复查验
  repeObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "fpdm": "",
      "fphm": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_CFCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  checkboxStateList = [
    { name: '重复查验', value: '重复查验', ngModelFlag: false, },
    { name: '非购方发票', value: '非购方发票', ngModelFlag: false, },
    { name: '禁报商品', value: '禁报商品', ngModelFlag: false, },
    { name: '销方黑名单', value: '销方黑名单', ngModelFlag: false, },
    { name: '风险企业', value: '风险企业', ngModelFlag: false, },
  ];
  checkRecordsObj = {
    "nsrsbh": "",
    "user_name": "",
    "orgid": "",
    "reqSubpackageModel": {
      "invoiceCheckDateStart": "",
      "invoiceCheckDateEnd": "",
      "issuedDateStart": "",
      "issuedDateEnd": "",
      "amountStart": "",
      "amountEnd": "",
      "jshjAmountStart":"",
      "jshjAmountEnd":"",
      "cfcyChecked": "",
      "fgfChecked": "",
      "jbspChecked": "",
      "xfhmdChecked": "",
      "fxqyChecked": "",
      "sfName": "",
      "gfName": "",
      "result": "",
      "pageNo": "1",
      "pageSize": "10",
      "fplx":""
    },
    "nsrmc": "",
    "areaCode": "110"
  };
  gfNameObj = {
    "version": "1.0",
    "nsrsbh": "",
    "user_name": "",
    "nsrmc": "",
    "orgid": ""
  };
  cxcyObj = {
    "nsrsbh": "",
    "user_name": "管理用户",
    "orgid": "",
    "reqSubpackageModel": { "ids": [] },
    "nsrmc": "",
    "areaCode": "110"
  };
  numData: {};
  // 次数
  numObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": null,
      "pcClientBZ": null,
      "kprq": null,
      "jejym": null,
      "cyfs": null
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_XFSQ",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  xfmcValue: any;
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    let moth = moment().subtract(3, "months").format("YYYY-MM-DD"); //当前时间的前3个月时间
    let now = moment().format("YYYY-MM-DD");
    console.log(moth);
    console.log(now);
    this.getgfName();
    console.log(this.listOfAllData);
    // const d = new Date();
    // this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd =
    //   d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
    //   '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
    // this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart =
    //   d.getFullYear() + '-' + ((d.getMonth() - 2) < 10 ? '0' + (d.getMonth() - 2) : (d.getMonth() - 2)) +
    //   '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
    /*     this.checkRecordsObj.reqSubpackageModel.issuedDateStart =
          d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
          '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
        this.checkRecordsObj.reqSubpackageModel.issuedDateEnd =
          d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
          '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())); */
    this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart = moth;
    this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd = now;
  }

  // 不可选择的时间 -- 开始时间
  disabledcheckStartDate = (current: Date) => {
    const pre = new Date();
    //  业务逻辑调整 可查询五年内数据 20200206
    pre.setFullYear(pre.getFullYear() - 5);
    const ymData = new Date();
    return current >= ymData || current < pre;
  }
  // 不可选择的时间 -- 结束时间
  disabledcheckEndDate = (current: Date) => {
    const pre = new Date();
    //  业务逻辑调整 可查询五年内数据 20200206
    pre.setFullYear(pre.getFullYear() - 5);
    const ymData = new Date();
    return current >= ymData || current < pre;
  }

  // 不可选择的时间 -- 结束时间
  disabledkpEndDate = (current: Date) => {
    const pre = new Date();
    //  业务逻辑调整 可查询五年内数据 20200206
    pre.setFullYear(pre.getFullYear() - 5);
    const ymData = new Date();
    return current >= ymData || current < pre;
  }
  // 日期转换
  dataFu(e, ev) {
  }
  // 查验时间开始
  checkDateStartChange(e) {
    /*     const d = new Date(e);
        this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart =
          d.getFullYear() + '-' + ((d.getMonth() + 2) < 10 ? '0' + (d.getMonth() + 2) : (d.getMonth() + 2)) +
          '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())); */
  }
  // 查验时间结束
  checkDateEndChange(e) {
    /*     const d = new Date(e);
        this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd =
          d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
          '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())); */
  }
  // 开票日期开始
  kpDateStartChange(e) {
    /*     const d = new Date(e);
        this.checkRecordsObj.reqSubpackageModel.issuedDateStart =
          d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
          '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())); */
  }

  // 开票日期结束
  kpDateEndChange(e) {
    console.log(e);
    /*     const d = new Date(e);
        this.checkRecordsObj.reqSubpackageModel.issuedDateEnd =
          d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
          '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())); */
  }

  // 金额限制
  jeStartKeyUp(e) {
    console.log(e);
    this.checkRecordsObj.reqSubpackageModel.amountStart =
      this.checkRecordsObj.reqSubpackageModel.amountStart.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
  }
  jeEndKeyUp(e) {
    console.log(e);
    this.checkRecordsObj.reqSubpackageModel.amountEnd =
      this.checkRecordsObj.reqSubpackageModel.amountEnd.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
  }

    // 价税合计限制
    jshjStartKeyUp(e) {
      console.log(e);
      this.checkRecordsObj.reqSubpackageModel.jshjAmountStart =
        this.checkRecordsObj.reqSubpackageModel.jshjAmountStart.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    }
    jshjEndKeyUp(e) {
      console.log(e);
      this.checkRecordsObj.reqSubpackageModel.jshjAmountEnd =
        this.checkRecordsObj.reqSubpackageModel.jshjAmountEnd.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    }

  // 获取购方名称
  getgfName() {
    this.httpService.newGetPublicAttr(this.gfNameObj);
    console.log(this.gfNameObj);
    const gfNameData = new FormData();
    gfNameData.append('headModel', JSON.stringify(this.gfNameObj));
    this.httpService.invoiceCheckoutRecords('/api/getBuyers', gfNameData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(JSON.parse(res.ResSubpackage).dataList);
        this.gfNameList = JSON.parse(res.ResSubpackage).dataList;
      }
    });
  }

  // 销方名称
  xfmcKeyUp() {
    console.log(this.xfmcValue);
    if (this.checkFlag) {
      this.checkRecordsObj.reqSubpackageModel.sfName = this.xfmcValue;
      console.log(this.checkRecordsObj.reqSubpackageModel.sfName);
      this.recordsCheckClick();
      if (this.listOfAllData.length == 0) {
        if (this.xfmcValue != '') {
          this.dataFlag = false;
        } else {
          this.dataFlag = true;
        }
      } else {
        this.dataFlag = true;
      }
    }

  }
  // 购方名称change
  gfNameChange() {
    console.log(this.gfName);
    if (this.checkFlag) {
      this.recordsCheckClick();
    }
  }

  // 发票类型change
  fplxChange() {
    console.log(this.fplx);
    if (this.checkFlag) {
      this.recordsCheckClick();
    }
  }
  // 查验状态 change
  cyztChange() {

    console.log(this.checkresult);
    if (this.checkresult == '3') {
      this.checkboxStateList.forEach(item => {
        item.ngModelFlag = false;
      });
      console.log(this.checkboxStateList);
      this.disFlag = true;
    } else {
      this.disFlag = false;
    }
    if (this.checkFlag) {
      this.recordsCheckClick();
    }
  }

  // 筛选 -- 全部
  allChange(e) {
    console.log(e);
    if (this.checkFlag) {
      if (e == true) {
        this.normalCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });
        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.recordsCheckClick();
      } else {
        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.recordsCheckClick();
      }
      console.log(this.checkboxStateList);
    } else {
      if (e == true) {
        this.normalCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });
      }
    }
  }
  // 筛选 -- 正常
  normalChange(e) {
    console.log(e);
    if (this.checkFlag) {
      if (e == true) {
        this.allCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });
        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.checkresult = '1';
        this.recordsCheckClick();
      } else {
        this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.checkresult = '';
        this.recordsCheckClick();
      }
    } else {
      if (e == true) {
        this.allCheckedFlag = false;
        this.checkboxStateList.forEach(item => {
          item.ngModelFlag = false;
        });
      }
    }
  }
  // 筛选 -- 细则
  sxCheckbox(e) {
    console.log(e);
    console.log(this.checkboxStateList);
    if (e.length > 0) {
      this.allCheckedFlag = false;
      this.normalCheckedFlag = false;
    }
  }
  // 筛选细则 点击事件
  labelChange(e) {
    console.log(e);
    /*     this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
        this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
        this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
        this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
        this.checkRecordsObj.reqSubpackageModel.result = ''; */
    this.checkboxStateList.forEach(item => {
      if (item.name == e.name) {
        item.ngModelFlag = !item.ngModelFlag;
        if (this.checkFlag) {
          if (this.checkRecordsObj.reqSubpackageModel.cfcyChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.fgfChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.jbspChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.fxqyChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
          }
          if (this.checkRecordsObj.reqSubpackageModel.xfhmdChecked == '0') {
            this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
          }
          switch (item.name) {
            case '重复查验':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '非购方发票':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.fgfChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '禁报商品':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.jbspChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '销方黑名单':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
              }
              this.recordsCheckClick();
              break;
            case '风险企业':
              console.log(this.checkRecordsObj);
              if (item.ngModelFlag) {
                this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '1';
              } else {
                this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
              }
              this.recordsCheckClick();
              break;
            default: break;
          }
        }

        console.log(this.checkRecordsObj);

      }
    });
    console.log(this.checkboxStateList);

  }
  // 查询按钮
  recordsCheckClick() {
    this.checkFlag = true;
    this.isSpinning = true;
    if (this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart && this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd) {
      console.log(this.checkresult);
      // console.log(this.gfName);
      const d = new Date(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart);
      // this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart =
      //   d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
      //   '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
      // const de = new Date(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd);
      // this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd =
      //   de.getFullYear() + '-' + ((de.getMonth() + 1) < 10 ? '0' + (de.getMonth() + 1) : (de.getMonth() + 1)) +
      //   '-' + ((de.getDate()) < 10 ? '0' + (de.getDate()) : (de.getDate()));
      // console.log(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart);
      // console.log(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd);
      // this.day1 = new Date(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart);
      // this.day2 = new Date(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd);
      // console.log((this.day2 - this.day1) / (1000 * 60 * 60 * 24));
      this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart = this.dateDo(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart, "yyyy-MM-dd");
      this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd = this.dateDo(this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd, "yyyy-MM-dd");

      if (this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateStart <= this.checkRecordsObj.reqSubpackageModel.invoiceCheckDateEnd) {

        // if (this.gfName == '全部') {
        //   this.checkRecordsObj.reqSubpackageModel.gfName = '';
        // } else {
        //   this.checkRecordsObj.reqSubpackageModel.gfName = this.gfName;
        // }
        if (this.fplx == '全部') {
          this.checkRecordsObj.reqSubpackageModel.fplx = '';
        } else {
          this.checkRecordsObj.reqSubpackageModel.fplx = this.fplx;
        }
        if (this.checkRecordsObj.reqSubpackageModel.amountEnd && this.checkRecordsObj.reqSubpackageModel.amountStart) {
          if (this.checkRecordsObj.reqSubpackageModel.amountEnd < this.checkRecordsObj.reqSubpackageModel.amountStart) {
            this.isVisible = true;
            this.checkInvoiceTit = '请填写正确的金额区间';
          } else {
            this.checkRecordsObj.reqSubpackageModel.result = this.checkresult;
            this.fliterkprqCheck();
          }
        } else {
          this.checkRecordsObj.reqSubpackageModel.result = this.checkresult;
          this.fliterkprqCheck();
        }

        if (this.checkRecordsObj.reqSubpackageModel.jshjAmountEnd && this.checkRecordsObj.reqSubpackageModel.jshjAmountStart) {
          if (this.checkRecordsObj.reqSubpackageModel.jshjAmountEnd < this.checkRecordsObj.reqSubpackageModel.jshjAmountStart) {
            this.isVisible = true;
            this.checkInvoiceTit = '请填写正确的价税合计区间';
          } else {
            this.checkRecordsObj.reqSubpackageModel.result = this.checkresult;
            this.fliterkprqCheck();
          }
        } else {
          this.checkRecordsObj.reqSubpackageModel.result = this.checkresult;
          this.fliterkprqCheck();
        }

      } else {
        this.isVisible = true;
        this.checkInvoiceTit = '请选择正确查验时间';
      }

    } else {
      this.isVisible = true;
      this.checkInvoiceTit = '查验时间不能为空';
    }
  }
  // 过滤方法
  fliterkprqCheck() {

    const d = new Date(this.checkRecordsObj.reqSubpackageModel.issuedDateEnd);
    this.checkRecordsObj.reqSubpackageModel.issuedDateEnd =
      d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
      '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
    const da = new Date(this.checkRecordsObj.reqSubpackageModel.issuedDateStart);
    this.checkRecordsObj.reqSubpackageModel.issuedDateStart =
      da.getFullYear() + '-' + ((da.getMonth() + 1) < 10 ? '0' + (da.getMonth() + 1) : (da.getMonth() + 1)) +
      '-' + ((da.getDate()) < 10 ? '0' + (da.getDate()) : (da.getDate()));
    console.log(this.checkRecordsObj.reqSubpackageModel.issuedDateStart);
    console.log(this.checkRecordsObj.reqSubpackageModel.issuedDateEnd);
    if (this.checkRecordsObj.reqSubpackageModel.issuedDateStart == '1970-01-01' ||
      this.checkRecordsObj.reqSubpackageModel.issuedDateStart == 'NaN-NaN-NaN') {
      this.checkRecordsObj.reqSubpackageModel.issuedDateStart = '';
    }
    if (this.checkRecordsObj.reqSubpackageModel.issuedDateEnd == '1970-01-01' ||
      this.checkRecordsObj.reqSubpackageModel.issuedDateEnd == 'NaN-NaN-NaN') {
      this.checkRecordsObj.reqSubpackageModel.issuedDateEnd = '';
    }
    if (this.checkRecordsObj.reqSubpackageModel.issuedDateStart && this.checkRecordsObj.reqSubpackageModel.issuedDateEnd) {

      if (this.checkRecordsObj.reqSubpackageModel.issuedDateStart <= this.checkRecordsObj.reqSubpackageModel.issuedDateEnd) {
        this.checkFn();
      } else {
        this.isVisible = true;
        this.checkInvoiceTit = '开票时间的结束日期不能早于开始日期';
      }
    } else {
      this.checkFn();
    }
  }
  checkFn() {
    this.httpService.newGetPublicAttr(this.checkRecordsObj);
    if (this.checkRecordsObj.reqSubpackageModel.issuedDateStart == '1970-01-01' ||
      this.checkRecordsObj.reqSubpackageModel.issuedDateStart == 'NaN-NaN-NaN') {
      this.checkRecordsObj.reqSubpackageModel.issuedDateStart = '';
    }
    if (this.checkRecordsObj.reqSubpackageModel.issuedDateEnd == '1970-01-01' ||
      this.checkRecordsObj.reqSubpackageModel.issuedDateEnd == 'NaN-NaN-NaN') {
      this.checkRecordsObj.reqSubpackageModel.issuedDateEnd = '';
    }
    console.log(this.checkRecordsObj);
    const formData = new FormData();
    formData.append('headModel', JSON.stringify(this.checkRecordsObj));
    this.httpService.invoiceCheckoutRecords('/api/selectInvoiceCheckLateRecordPage', formData).subscribe(res => {
      console.log(JSON.parse(res.ResSubpackage).pageInfo);
      if (res.returnCode == '00000000') {
        this.listOfAllData = JSON.parse(res.ResSubpackage).pageInfo.list;
        if (this.listOfAllData.length == 0) {
          this.dataFlag = false;
        } else {
          this.dataFlag = true;
        }
        if (this.listOfAllData) {
          this.isSpinning = false;
        }
        this.allData = JSON.parse(res.ResSubpackage).pageInfo;
        this.total = this.allData.total;
        this.listOfDisplayData = this.listOfAllData;
        this.refreshStatus('');
        this.listOfAllData.map(item => {
          item.btnState = this.buttonItems;
          item.yzmSj = item.yzmSj.slice(0, 10);
          this.showButtonState(item);
          if (item.result == "1") {
            item.result = '存在发票';
            item.sjcyColor = 'titBlack';
          } else if (item.result == "3") {
            item.result = '不存在发票';
            item.sjcyColor = 'titRed';
          } else if (item.result == '0') {
            item.result = '等待查验';
            item.sjcyColor = 'titGary';
          } else if (item.result == '9') {
            item.result = '查验异常';
            item.sjcyColor = 'titRed';
          } // 等待查验 // 查验异常
          return item;
        });
        console.log(this.listOfAllData);
      } else if (res.returnCode == '00000002') {

        if (eval("app.ReLogin()")) {

        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }
  // 重新查验
  toCheck() {
    if (this.idsArray) {
      this.isSpinning = true;
      this.timer = setInterval(() => {
        eval("app.setCheckRemain()");
      }, 500);
      this.httpService.newGetPublicAttr(this.cxcyObj);
      console.log(this.cxcyObj);
      const cxcyData = new FormData();
      cxcyData.append('headModel', JSON.stringify(this.cxcyObj));
      this.httpService.invoiceCheckoutRecords('/api/recheckInvoice', cxcyData).subscribe(res => {
        console.log(res);
        if (res.returnCode == '00000000') {
          /*  console.log(JSON.parse(res.ResSubpackage).dataList);
           this.gfNameList = JSON.parse(res.ResSubpackage).dataList; */
          this.recordsCheckClick();
          this.residueNumber();
          console.log(eval("app.getClientRemainTimes()"));
          //     console.log(eval("app.setCheckRemain()"));


        } else if (res.returnCode == '00000002') {
          if (eval("app.ReLogin()")) {
            this.toCheck();
          }
        } else {
          this.returnCodeDispose(res.returnCode);
        }
      });
    } else {
      this.isVisible = true;
      this.checkInvoiceTit = '请选择查验数据';
    }

  }
  // 剩余次数
  residueNumber() {
    const resiObj = {
      "nsrsbh": eval("HostApp.getHostData('nsrsbh')"),
      "user_name": eval("HostApp.getHostData('user_name')"),
      "orgid": eval("HostApp.getHostData('orgid')"),
      "nsrmc": eval("HostApp.getHostData('nsrmc')"),
      "areaCode": eval("HostApp.getHostData('area_code')")
    };
    const resiData = new FormData();
    resiData.append('headModel', JSON.stringify(resiObj));
    this.httpService.invoiceCheckoutRecords('/api/getTimesLeft', resiData).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        this.resiNumber = JSON.parse(res.ResSubpackage).leftTimes;
        console.log(this.resiNumber);
        console.log(eval("app.getClientRemainTimes()"));
        if (this.resiNumber == eval("app.getClientRemainTimes()")) {
          clearInterval(this.timer);
        }
      }
    });

  }
  // 导出发票
  exportInvoiceBtn() {
    if (this.listOfAllData.length != 0) {
      this.httpService.newGetPublicAttr(this.checkRecordsObj);
      this.checkRecordsObj.reqSubpackageModel.cfcyChecked = '';
      this.checkRecordsObj.reqSubpackageModel.fgfChecked = '';
      this.checkRecordsObj.reqSubpackageModel.jbspChecked = '';
      this.checkRecordsObj.reqSubpackageModel.xfhmdChecked = '';
      this.checkRecordsObj.reqSubpackageModel.fxqyChecked = '';
      this.checkRecordsObj.reqSubpackageModel.sfName = '';
      this.checkRecordsObj.reqSubpackageModel.gfName = '';
      this.checkRecordsObj.reqSubpackageModel.result = '';
      console.log(this.checkRecordsObj);
      const formData = new FormData();
      formData.append('headModel', JSON.stringify(this.checkRecordsObj));
      this.http.post('/api/downloadInvoiceCheckLateRecord', formData, { 'responseType': 'blob' }).subscribe(res => {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        const fileName = '发票查验记录' + '.xlsx';

        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', fileName);
        a.click();
        URL.revokeObjectURL(objectUrl);

      });
    } else {
      this.isVisible = true;
      this.checkInvoiceTit = '请查询发票记录';
    }

  }
  // 点击发票号码
  fphmDetailClick(e) {
    console.log(e);
    const footerUrl = new Buffer(e.detailUrl, 'base64').toString();
    window.open(footerUrl, '_blank');
  }


  // 提示框确定
  handleOk(): void {
    // eval("app.SetWindowConver(false)");
    this.isVisible = false;
    this.isSpinning = false;
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
    this.isVisible = false;
  }

  // table


  // 表格 方法
  refreshStatus(e): void {
    console.log(e);
    console.log(this.listOfAllData);
    this.listOfAllData.forEach(item => {
      console.log(this.mapOfCheckedId[item.id]);

      if (this.mapOfCheckedId[item.id]) {
        item.mapOfCheckedId = true;
      } else {
        item.mapOfCheckedId = false;
      }
    });
    console.log(this.listOfAllData);
    this.idsArray = this.listOfAllData.filter(k => k.mapOfCheckedId);
    console.log(this.idsArray);
    this.cxcyObj.reqSubpackageModel.ids = [];
    this.idsArray.forEach(l => {
      console.log(l);
      this.cxcyObj.reqSubpackageModel.ids.push(l.id);
    });
    console.log(this.cxcyObj.reqSubpackageModel.ids);
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
  }

  checkAll(value: boolean): void {
    this.cxcyObj.reqSubpackageModel.ids = [];
    console.log(value);
    if (value) {
      this.listOfAllData.forEach(item => {
        item.mapOfCheckedId = true;
        this.cxcyObj.reqSubpackageModel.ids.push(item.id);
      });
    } else {
      this.listOfAllData.every(item => item.mapOfCheckedId = false);
      this.cxcyObj.reqSubpackageModel.ids = [];
    }
    console.log(this.cxcyObj);
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus('');
  }
  pageIndexChange(e) {
    console.log(e);
    this.checkRecordsObj.reqSubpackageModel.pageNo = e;
    this.checkFn();
  }



  butClick(e, cont) {
    console.log(e);
    this.cfcyBtn(e, cont);
    console.log(cont)

    // 风险企业
    if (e.bgColor == 'red') {
      if (e.name == '风险企业') {
        this.ventureBusinessFlag = true;
        this.vbObj.serviceId = 'ZXTPLCYFW_FXQYCX';
        this.vbObj.ReqSubpackage.invoiceId = cont.invoiceId;
        this.httpService.getPublicAttr(this.vbObj);
        console.log(this.vbObj);
        const fxqyObj = JSON.stringify(this.vbObj);
        const fxqyEncryptedJson = eval("app.EncryptReqJson(fxqyObj)");
        console.log(fxqyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', fxqyEncryptedJson).subscribe(fxqyRes => {
          console.log(fxqyRes);
          console.log(4444444);
          if (fxqyRes.returnCode == '00000000') {
            console.log(6666);
            const fxqyResres = JSON.stringify(fxqyRes);
            this.fxqyDecryptedResJson = eval("app.DecryptResJson(fxqyResres)");
            console.log(this.fxqyDecryptedResJson);
            this.endFxqyData = JSON.parse(JSON.parse(this.fxqyDecryptedResJson).ResSubpackage);
            console.log(this.endFxqyData);
          } else if (fxqyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';
            if (eval("app.ReLogin()")) { }
            this.butClick(e, cont)
          } else {
            this.returnCodeDispose(fxqyRes.returnCode);
          }

        });
      }
    } else {
      this.ventureBusinessFlag = false;
    }
  }

  // 重复查验按钮
  cfcyBtn(e, cont) {
    if (e.bgColor == 'red') {
      if (e.name == '重复查验') {
        this.repePopFlag = true;
        this.repeObj.serviceId = 'ZXTPLCYFW_CFCY';
        this.httpService.getPublicAttr(this.repeObj);
        if (cont) {
          this.repeObj.ReqSubpackage.fpdm = cont.fpdm;
          this.repeObj.ReqSubpackage.fphm = cont.fphm;
        }


        console.log(this.repeObj);
        const cfcyObj = JSON.stringify(this.repeObj);
        const cfcyEncryptedJson = eval("app.EncryptReqJson(cfcyObj)");
        console.log(cfcyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', cfcyEncryptedJson).subscribe(cfcyRes => {
          console.log(cfcyRes);
          console.log(333333);
          if (cfcyRes.returnCode == '00000000') {
            console.log(6666);

            this.cfcyFlag = false;
            const cfcyResres = JSON.stringify(cfcyRes);
            this.cfcyDecryptedResJson = eval("app.DecryptResJson(cfcyResres)");
            console.log(this.cfcyDecryptedResJson);
            this.endCfcyData = JSON.parse(JSON.parse(JSON.parse(this.cfcyDecryptedResJson).ResSubpackage).cfcyList);
            console.log(this.endCfcyData);
            this.endCfcyData.map(item => {
              if (item.cyfs == '1') {
                item.cyfsZn = '客户端';
              } else if (item.cyfs == '2') {
                item.cyfsZn = '手机端';
              } else if (item.cyfs == '3') {
                item.cyfsZn = '发票查验API';
              }
            })
          } else if (cfcyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';

          } else if (cfcyRes.returnCode == '00000002') {
            if (eval("app.ReLogin()")) {
              if (e) {
                this.cfcyBtn(e, cont);
              }
            } else {
              this.returnCodeDispose('00000003')
            }
          } else {
            this.returnCodeDispose(cfcyRes.returnCode);
          }

        });
      }
    } else {
      this.repePopFlag = false;
    }
  }

  // 风险企业弹框取消
  vbhandleCancel() {
    this.ventureBusinessFlag = false;
  }
  vbhandleOk() {
    this.ventureBusinessFlag = false;
  }
  // 重复查验弹框取消
  pophandleCancel(): void {
    this.repePopFlag = false;
  }
  // 重复查验弹框确定
  pophandleOk(): void {
    this.repePopFlag = false;
  }

  // 按钮
  showButtonState(item) {
    item.btnState = [
      { name: '发票正常', bgColor: 'gary', tip: '', },
      { name: '重复查验', bgColor: 'gary', tip: '', },
      { name: '购方查验', bgColor: 'gary', tip: '', },
      { name: '禁报查验', bgColor: 'gary', tip: '', },
      { name: '黑名单查验', bgColor: 'gary', tip: '', },
      { name: '风险企业', bgColor: 'gary', tip: '', },
    ];
    if (item.result == '1') {
      /*       if (item.sjcy == 0) {
              item.btnState[0].bgColor = 'red';
            } else {
              item.btnState[0].bgColor = 'blue';
            } */

      if (item.fpzt == 2) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '发票作废';
      } else if (item.fpzt == 3) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '发票红冲';
      } else if (item.fpzt == 7) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '部分红冲';
      } else if (item.fpzt == 8) {
        item.btnState[0].bgColor = 'red';
        item.btnState[0].name = '全额红冲';
      } else {
        item.btnState[0].bgColor = 'blue';
        item.btnState[0].name = '发票正常';
      }

      if (item.cfcy == 1) {
        item.btnState[1].bgColor = 'red';
      }
      if (item.cfcy == 0) {
        item.btnState[1].bgColor = 'blue';
      }

      if (item.gfcy == 1) {
        item.btnState[2].bgColor = 'blue';
      } else {
        item.btnState[2].bgColor = 'red';
        item.btnState[2].name = '购方查验、非本单位购方名称';
      }

      if (item.jbcy == 1) {
        item.btnState[3].bgColor = 'red';
      } else {
        item.btnState[3].bgColor = 'blue';
      }
      if (item.hmdcy == 1) {
        item.btnState[4].bgColor = 'red';
      } else {
        item.btnState[4].bgColor = 'blue';
      }
      if (item.fxqy == 1) {
        item.btnState[5].bgColor = 'red';
      } else {
        item.btnState[5].bgColor = 'blue';
      }
      return item;
    }
  }

  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    if (resDataTypeNumber == '02') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
    } else if (resDataTypeNumber == '03') {
      this.isVisible = true;
      this.checkInvoiceTit = '查无此票';
    } else if (resDataTypeNumber == '04') {
      this.isVisible = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
    } else if (resDataTypeNumber == '05') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    } else if (resDataTypeNumber == '06') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
    }
    this.isSpinning = false;
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    if (returnCodeNumber == '00000001') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票数据错误';
    } else if (returnCodeNumber == '00000002') {
      this.isVisible = true;
      this.checkInvoiceTit = '重新登录';
    } else if (returnCodeNumber == '00000003') {
      this.isVisible = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisible = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisible = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisible = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisible = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }
    this.isSpinning = false;
  }

  dateDo(timestamp, format) {
    let date = timestamp instanceof Date ? timestamp : new Date(timestamp);
    const o = {
      "y{4}": date.getFullYear(),
      "MM": date.getMonth() + 1,
      "dd": date.getDate(),
      "hh": date.getHours(),
      "mm": date.getMinutes(),
      "ss": date.getSeconds()
    };
    for (let i in o) {
      if (new RegExp('(' + i + ')').test(format)) {
        format = format.replace(RegExp.$1, o[i] < 10 ? '0' + o[i] : o[i]);
      }
    }
    return format;
  };
}
