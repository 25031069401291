import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-use-a-wizard',
  templateUrl: './use-a-wizard.component.html',
  styleUrls: ['./use-a-wizard.component.css']
})
export class UseAWizardComponent implements OnInit {
  syxdObj = {
    "version": "1.0",
    "nsrsbh": "",
    "user_name": "",
    "nsrmc": "",
    "orgid": ""
  };
  ewmsrc: any;
  dataObject: any;
  showQrcode: any;  //0默认 1不刷新 2刷新
  starRefresh:any;  //0用户点击刷新 1刷新结束
  constructor(
    private httpService: HttpService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.showQrcode = 0;
    this.starRefresh = 1;
    this.getUse();
  }
  // 获取
  getUse() {


    this.httpService.newGetPublicAttr(this.syxdObj);
    var checkData = {'nsrsbh':this.syxdObj.nsrsbh};


    this.httpService.useAWizard('/api/getUserWizardInfoCheck', checkData).subscribe(res => {
      console.log(res);
      if (res) {
        console.log(true);
        this.showQrcode = 1;
        this.getUserQrcode();
        }else{
          this.showQrcode = 2;

          console.log(false);

        }

      
    });






  }

  getUserQrcode(){
    this.starRefresh = 0;
    this.httpService.newGetPublicAttr(this.syxdObj);
    console.log(this.syxdObj);
    const jcbdData = new FormData();
    jcbdData.append('headModel', JSON.stringify(this.syxdObj));
    this.httpService.useAWizard('/api/getUserWizardInfo', jcbdData).subscribe(res => {
      console.log(res);
      this.showQrcode = 1;
      this.starRefresh = 1;
      if (res.returnCode == '00000000') {
        console.log(JSON.parse(res.ResSubpackage).dataObject);
        this.dataObject = JSON.parse(res.ResSubpackage).dataObject;
        this.ewmsrc = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/JPEG;base64,' + this.dataObject.imageInfo);
        console.log(this.ewmsrc);
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.getUse();
        }

      }
    });
  }
}
