import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UploadXHRArgs } from 'ng-zorro-antd';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { ConditionalExpr } from '@angular/compiler';
@Component({
  selector: 'app-excel-table',
  templateUrl: './excel-table.component.html',
  styleUrls: ['./excel-table.component.css']
})
export class ExcelTableComponent implements OnInit {
  @Input() excelData;
  @Input() file;
  @Output() excelUpChange: EventEmitter<any> = new EventEmitter<any>();
  excelDataList = [];
  excelDataTitList = [];
  matchList = [];
  isVisible = false;
  butTit = '';
  matchMapList: any;
  obj = {};
  totalCount: any;
  exIndex: any;
  excelObj = {
    "nsrsbh": "",
    "orgid": "",
    "user_name": "",
    "nsrmc": '',
    "areaCode": "",
    "reqSubpackageModel": {
      matchMap: {}
    }
  };
  optionList = [
    // { name: '请选择', value: '请选择', disable: false, },
    { name: '序号', value: '序号', disable: false, },
    { name: '发票代码', value: '发票代码', disable: false, },
    { name: '发票号码', value: '发票号码', disable: false, },
    { name: '开票日期', value: '开票日期', disable: false, },
    { name: '金额', value: '金额', disable: false, },
    { name: '校验码后六位', value: '校验码后六位', disable: false, },
    { name: '空', value: '空', disable: false, },
  ];
  butList = [
    { name: '序号', bgColor: 'gary', },
    { name: '发票代码', bgColor: 'gary', },
    { name: '发票号码', bgColor: 'gary', },
    { name: '开票日期', bgColor: 'gary', },
    { name: '金额', bgColor: 'gary', },
    { name: '校验码后六位', bgColor: 'gary', },
  ];
  xhFlag = false;
  fpdmFlag = false;
  fphmFlag = false;
  kprqFlag = false;
  jeFlag = false;
  jymFlag = false;
  excelisVisible = false;
  titList: any;
  valueArray = [];
  prevArr = [];
  filterUniqueList: any;

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.isVisible = false;
    console.log(this.excelData);
    this.excelDataTitList = this.excelData.titleList;
    this.excelDataList = this.excelData.dataList;
    this.matchList = this.excelData.matchList;
    this.totalCount = this.excelData.totalCount;
    console.log(this.matchList);
    console.log(this.excelData);
    console.log(this.excelDataTitList);
    if (this.matchList) {
    this.filterUniqueList = this.matchList.filter(i => this.matchList.indexOf(i) !== this.matchList.lastIndexOf(i));
    }
    console.log(this.filterUniqueList);
    this.butList.forEach(but => {
      this.matchList.forEach(match => {
        if (but.name == match) {
          but.bgColor = 'blue';
        }

      });

      this.filterUniqueList.forEach(f => {
        if (but.name == f) {
          but.bgColor = 'gary';

        }

      });
    });

    this.excelDataTitList.map((item, ind) => {
      console.log(item);
      console.log(ind);
      item.optionLists = this.optionList;
      item.selected = this.matchList[ind];
      item.temporaryAttribute = this.matchList[ind];
      this.filterUniqueList.forEach(p => {
        if (item.selected == p) {
          item.selected = '';
        }
      });
    });
    console.log(this.excelDataTitList);
    console.log(this.filterUniqueList);
    this.matchList.forEach(j => {
      this.disbaleFn(j);
    });



    console.log(this.excelDataTitList);
    console.log(this.excelData);
  }

  disbaleFn(e) {
    this.excelDataTitList.map((item) => {
      item['optionLists'].forEach(ele => {
        if (ele.value == e && ele.value != '空' && ele.value != '请选择') {
          ele.disable = true;
        }
        this.filterUniqueList.forEach(l => {
          if (ele.value == l) {
            ele.disable = false;
          }
        });
      });
      return item;
    });
  }


  // compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.value === o2.value : o1 === o2);
  selectChange(data, value, index) {
    console.log(data);
    console.log(value);
    this.valueArray.unshift(value);
    this.prevArr.unshift(index);
    console.log(this.valueArray);
    console.log(this.prevArr);
    console.log(this.valueArray[0]);
    this.excelDataTitList.forEach((item, i) => {
      item['optionLists'].forEach(ele => {
        if (this.prevArr[0] == this.prevArr[1] || this.prevArr.length == 1) {
          if (this.valueArray[0] != '空') {
            if (ele.value == data) {
              ele.disable = false;
              this.butList.forEach(q => {
                if (q.name == data) {
                  if (q.bgColor == 'blue') {
                    q.bgColor = 'gary';
                  }
                }
              });
            }
            if (value != '空') {
              if (ele.value == value) {
                ele.disable = true;
                this.butList.forEach(q => {
                  if (q.name == value) {
                    if (q.bgColor == 'gary') {
                      q.bgColor = 'blue';
                    }
                  }
                });
              }
            }
          } else {
            if (ele.value == data) {
              ele.disable = false;
              this.butList.forEach(q => {
                if (q.name == data) {
                  if (q.bgColor == 'blue') {
                    q.bgColor = 'gary';
                  }
                }
              });
            }
          }

        }

        if (this.prevArr[0] != this.prevArr[1]) {
          if (data == '' && value != '空') {
            if (ele.value == value) {
              ele.disable = true;
              this.butList.forEach(q => {
                if (q.name == value) {
                  if (q.bgColor == 'gary') {
                    q.bgColor = 'blue';
                  }
                }
              });
            }
          }
          if (data == '空' && value != '空') {
            if (ele.value == value) {
              ele.disable = true;
              this.butList.forEach(q => {
                if (q.name == value) {
                  if (q.bgColor == 'gary') {
                    q.bgColor = 'blue';
                  }
                }
              });
            }
          }
          if (data != '空' && value == '空') {
            if (ele.value == data) {
              ele.disable = false;
              this.butList.forEach(q => {
                if (q.name == data) {
                  if (q.bgColor == 'blue') {
                    q.bgColor = 'gary';
                  }
                }
              });
            }
          }
        }
        if (item.selected != item.temporaryAttribute) {
          item.temporaryAttribute = item.selected;
        }
      });

    });
    console.log(this.excelDataTitList);

    console.log(this.obj);

    console.log(this.excelData);

  }


  // 确定按钮
  excelConfirm() {
    console.log(this.excelDataTitList);
    this.excelDataTitList.forEach(w => {
      this.obj[w.name] = w.selected;
    });
    console.log(this.obj);

    if (this.butList[0].bgColor == 'gary') {
      this.isVisible = true;
      this.butTit = '序号未识别，请选择;';
      this.xhFlag = false;
      return;
    } else {
      this.xhFlag = true;
    }


    if (this.butList[1].bgColor == 'gary') {
      this.isVisible = true;
      this.butTit = '发票代码未识别，请选择;';
      this.fpdmFlag = false;
      return;
    } else {
      this.fpdmFlag = true;
    }



    if (this.butList[2].bgColor == 'gary') {
      this.isVisible = true;
      this.butTit = '发票号码未识别，请选择;';
      this.fphmFlag = false;
      return;
    } else {
      this.fphmFlag = true;
    }



    if (this.butList[3].bgColor == 'gary') {
      this.isVisible = true;
      this.butTit = '开票日期未识别，请选择;';
      this.kprqFlag = false;
      return;
    } else {
      this.kprqFlag = true;
    }




    if (this.butList[4].bgColor == 'gary') {
      /*       this.isVisible = true;
            this.butTit = '金额（不含税）未识别，请选择;'; */
      this.jeFlag = false;

    } else {
      this.jeFlag = true;
    }


    if (this.butList[5].bgColor == 'gary') {
      /*       this.isVisible = true;
            this.butTit = '校验码（后六位）未识别，请选择;'; */
      this.jymFlag = false;

    } else {
      this.jymFlag = true;
    }

    if (this.xhFlag && this.fpdmFlag && this.fphmFlag && this.kprqFlag) {

      if (this.jeFlag || this.jymFlag) {
        this.upExcel();
      } else {
        this.isVisible = true;
        this.butTit = '金额（不含税）未识别，请选择;校验码（后六位）未识别，请选择;';
      }
    }
  }

  upExcel() {
    this.excelObj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
    this.excelObj.orgid = eval("HostApp.getHostData('orgid')");
    this.excelObj.user_name = eval("HostApp.getHostData('user_name')");
    this.excelObj.nsrmc = eval("HostApp.getHostData('nsrmc')");
    this.excelObj.areaCode = eval("HostApp.getHostData('area_code')");
    this.excelObj.reqSubpackageModel.matchMap = this.obj;

    console.log(this.excelObj);
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('files', this.file as any);
    formData.append('headModel', JSON.stringify(this.excelObj));
    this.http.post('/api/uploadInvoiceExcelBatch', formData).subscribe(res => {
      console.log(res);
      if (res['returnCode'] == '00000000') {
        this.excelUpChange.emit(true);
        this.isVisible = true;
        this.butTit = '共导入发票' + this.totalCount + '张';
        setInterval(() => {
          this.excelUpChange.emit(false);
        }, 1000);
      } else if (res['returnCode'] == '00000001') {
        if (JSON.parse(JSON.parse(res['ResSubpackage'])[0].errorMsg).length > 0) {
          this.titList = JSON.parse(JSON.parse(res['ResSubpackage'])[0].errorMsg);
          console.log(this.titList);
          this.excelisVisible = true;
        }

      } else {
        this.isVisible = true;
        this.butTit = res['returnMessage'];
      }
    });
  }
  excelCancel() {
    this.excelUpChange.emit(false);
  }
  // 导入excel失败提示框
  excelTitHandleCancel() {
    this.excelisVisible = false;
  }
  excelTitHandleOk() {
    this.excelisVisible = false;
  }

  // 提示框
  handleCancel() {
    this.isVisible = false;
    this.butTit = '';
  }
  handleOk() {
    this.isVisible = false;
    this.butTit = '';
  }

}
