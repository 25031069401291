import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http/http.service';

@Component({
  selector: 'app-forbid-commodity',
  templateUrl: './forbid-commodity.component.html',
  styleUrls: ['./forbid-commodity.component.css']
})
export class ForbidCommodityComponent implements OnInit {

  dataSet = [
  ];
  nameValue: any;
  jcNameValue: any;
  tit = '禁报商品';
  isVisible = false;
  editFlag = false;
  delFlag = false;
  delList: any;
  nameEditValue: any;
  inputValue: any;
  isVisibleTip = false;
  checkInvoiceTit: any;
  jbspcxObj = {
    "ReqSubpackage": {
      "jbspId": null,
      "jbspmc": null,
      "spfl": null,
      "token": "",
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null,
      condition: '',
    },
    "version": "1.2.0.1803",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "",
    "areaCode": "500000",
    "lxfs": ""
  };
  jbspaddObj = {
    "ReqSubpackage": {
      "jbspId": null,
      "jbspmc": "",
      "spfl": "",
      "token": "",
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null
    },
    "version": "1.3.0.1811",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "",
    "areaCode": "500000",
    "lxfs": ""
  };
  jbspeditObj = {
    "ReqSubpackage": {
      "jbspId": "",
      "jbspmc": "",
      "spfl": "",
      "token": "",
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null
    },
    "version": "1.3.0.1811",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "",
    "jm": "1",
    "orgid": "",
    "areaCode": "500000",
    "lxfs": ""
  };
  jbspdelObj = {
    "ReqSubpackage": {
      "jbspId": "",
      "jbspmc": null,
      "spfl": null,
      "token": "",
      "RowNum": 0,
      "ResCode": null,
      "ResMessage": null
    },
    "version": "1.3.0.1811",
    "serviceId": "",
    "nsrsbh": "",
    "nsrmc": "",
    "user_name": "",
    "jm": "1",
    "orgid": "",
    "areaCode": "500000",
    "lxfs": ""
  };



  jbspList: any;
  jbspDecryptedResJson: any;
  constructor(
    private httpService: HttpService,
  ) { }
  ngOnInit() {
    this.getForbidCommodity();
  }
  // 获取列表
  getForbidCommodity() {
    this.jbspcxObj.serviceId = 'ZXTPLCYFW_JBSPCX';
    this.httpService.getPublicAttr(this.jbspcxObj);
    console.log(this.jbspcxObj);
    const jbspcxObjLs = JSON.stringify(this.jbspcxObj);
    const jbspcxEncryptedJson = eval("app.EncryptReqJson(jbspcxObjLs)");
    console.log(jbspcxEncryptedJson);
    this.httpService.salesBlacklists('/api/plfpcy.controller', jbspcxEncryptedJson).subscribe(res => {
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(111);
        const jbspRes = JSON.stringify(res);
        this.jbspDecryptedResJson = eval('app.DecryptResJson(jbspRes)');
        console.log(this.jbspDecryptedResJson);
        console.log(JSON.parse(JSON.parse(this.jbspDecryptedResJson).ResSubpackage).jbspList);
        this.jbspList = JSON.parse(JSON.parse(this.jbspDecryptedResJson).ResSubpackage).jbspList;
        this.dataSet = this.jbspList;
        // this.dataSet = this.xfhmdList;
        this.isVisible = false;

      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.getForbidCommodity();
        } else {
          this.returnCodeDispose('00000003');
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }

  // 搜索
  searchClick() {
    console.log(this.inputValue);
    this.jbspcxObj.ReqSubpackage.condition = this.inputValue;
    this.getForbidCommodity();
  }
  // 回车
  searchKeyUp(e) {
    console.log(e);
    console.log(this.inputValue);
    this.inputValue = this.inputValue.replace(/^\s+|\s+$/g, '');
    switch (this.inputValue) {
      case '%':
        this.jbspcxObj.ReqSubpackage.condition = '\\%';
        break;
      case '\'':
        this.jbspcxObj.ReqSubpackage.condition = '\\\'';
        break;
      case '_':
        this.jbspcxObj.ReqSubpackage.condition = '\\_';
        break;
      case '\\':
        this.jbspcxObj.ReqSubpackage.condition = '\\\\\\\\';
        break;
      default: this.jbspcxObj.ReqSubpackage.condition = this.inputValue;
    }
    this.getForbidCommodity();
    if (e.keyCode == 13) {
      console.log('回车');
      this.jbspcxObj.ReqSubpackage.condition = this.inputValue;
      this.getForbidCommodity();
    }

  }

  // 添加
  aliasAddClick() {
    this.nameValue = '';
    this.jcNameValue = '';
    this.isVisible = true;
    this.tit = '添加(禁报商品)';
    this.editFlag = false;
    this.delFlag = false;
  }
  // 修改
  nameEdit(e) {
    console.log(e);
    this.isVisible = true;
    this.tit = '修改(禁报商品)';
    this.nameValue = e.jbspmc;
    this.jcNameValue = e.spfl;
    this.nameEditValue = e;
    this.editFlag = true;
  }
  // 删除
  nameDel(e) {
    this.isVisible = true;
    this.tit = '删除(禁报商品)';
    this.delFlag = true;
    this.delList = e;
  }
  // add 方法
  addForbidCommodity() {
    this.jbspaddObj.serviceId = 'ZXTPLCYFW_JBSPTJ';
    this.httpService.getPublicAttr(this.jbspaddObj);
    this.jbspaddObj.ReqSubpackage.jbspmc = this.nameValue;
    this.jbspaddObj.ReqSubpackage.spfl = this.jcNameValue;
    console.log(this.jbspaddObj);
    // 加密请求json子节点 加密后的obj
    // 发票查验 add
    const jbspaddObjLs = JSON.stringify(this.jbspaddObj);
    const jbspaddEncryptedJson = eval("app.EncryptReqJson(jbspaddObjLs)");
    console.log(jbspaddEncryptedJson);
    // 使用加密后的json进行网络请求
    this.httpService.salesBlacklists('/api/plfpcy.controller', jbspaddEncryptedJson).subscribe(res => {
      // 解密响应的json
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(222);
        this.getForbidCommodity();
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.getForbidCommodity();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }
  // 修改
  editForbidCommodity() {
    this.jbspeditObj.serviceId = 'ZXTPLCYFW_JBSPXG';
    this.httpService.getPublicAttr(this.jbspeditObj);
    this.jbspeditObj.ReqSubpackage.jbspmc = this.nameValue;
    this.jbspeditObj.ReqSubpackage.spfl = this.jcNameValue;
    this.jbspeditObj.ReqSubpackage.jbspId = this.nameEditValue.jbspId;
    console.log(this.jbspaddObj);
    // 加密请求json子节点 加密后的obj
    // 发票查验 add
    const jbspeditObjLs = JSON.stringify(this.jbspeditObj);
    const jbspeditEncryptedJson = eval("app.EncryptReqJson(jbspeditObjLs)");
    console.log(jbspeditEncryptedJson);
    // 使用加密后的json进行网络请求
    this.httpService.salesBlacklists('/api/plfpcy.controller', jbspeditEncryptedJson).subscribe(res => {
      // 解密响应的json
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(333);
        this.getForbidCommodity();
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.getForbidCommodity();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }
  // 删除
  delForbidCommodity() {
    this.jbspdelObj.serviceId = 'ZXTPLCYFW_JBSPSC';
    this.httpService.getPublicAttr(this.jbspdelObj);
    this.jbspdelObj.ReqSubpackage.jbspId = this.delList.jbspId;
    console.log(this.jbspdelObj);
    // 加密请求json子节点 加密后的obj
    // 发票查验 add
    const jbspdelObjLs = JSON.stringify(this.jbspdelObj);
    const jbspdelEncryptedJson = eval("app.EncryptReqJson(jbspdelObjLs)");
    console.log(jbspdelEncryptedJson);
    // 使用加密后的json进行网络请求
    this.httpService.salesBlacklists('/api/plfpcy.controller', jbspdelEncryptedJson).subscribe(res => {
      // 解密响应的json
      console.log(res);
      if (res.returnCode == '00000000') {
        console.log(333);
        this.getForbidCommodity();
      } else if (res.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.getForbidCommodity();
        } else {
          this.returnCodeDispose('00000003');
        }
      } else {
        this.returnCodeDispose(res.returnCode);
      }
    });
  }
  //
  jbspKeyup() {
    this.nameValue = this.nameValue.replace(/^\s+|\s+$/g, '');
    if (this.nameValue.length > 20) {
      this.nameValue = this.nameValue.slice(0, 20);
    }
  }
  //
  jcKeyup() {
    this.jcNameValue = this.jcNameValue.replace(/^\s+|\s+$/g, '');
    if (this.jcNameValue.length > 20) {
      this.jcNameValue = this.jcNameValue.slice(0, 20);
    }
  }
  // 提示框确定
  handleOk(): void {

    console.log(this.delFlag);
    console.log(this.delList);
    if (this.delFlag) {
      this.delForbidCommodity();
    } else {
      if (this.nameValue != '') {
        if (this.jcNameValue != '') {
          console.log(this.dataSet);
          const res = this.dataSet.find(k => k.jbspmc == this.nameValue);
          const resres = this.dataSet.find(k => k.spfl == this.jcNameValue);
          console.log(res);
          console.log(resres);
          if (res != undefined && resres != undefined) {
            this.isVisibleTip = true;
            this.checkInvoiceTit = '禁报商品类型或名称已存在';
          } else {
            this.isVisibleTip = false;
            if (this.editFlag) {
              this.editForbidCommodity();
            } else {
              this.addForbidCommodity();
            }
          }
        } else {
          this.isVisibleTip = true;
          this.checkInvoiceTit = '请输入禁报商品类型/名称';
        }

      } else {
        this.isVisibleTip = true;
        this.checkInvoiceTit = '请输入禁报商品类型/名称';
      }
    }
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
  }
  tipHandleCancel() {
    this.isVisibleTip = false;
  }
  tipHandleOk() {
    this.isVisibleTip = false;
  }
  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    if (resDataTypeNumber == '02') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
    } else if (resDataTypeNumber == '03') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查无此票';
    } else if (resDataTypeNumber == '04') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
    } else if (resDataTypeNumber == '05') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查询失败，请重试';
    } else if (resDataTypeNumber == '06') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '查询失败，请重试';
    }
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    if (returnCodeNumber == '00000001') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '发票数据错误';
    } else if (returnCodeNumber == '00000002') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '重新登录';
    } else if (returnCodeNumber == '00000003') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisibleTip = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }
  }
}
