import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../http/http.service';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, NavigationEnd, ActivationStart } from '@angular/router';
import { Observable, from } from 'rxjs';
import { UploadXHRArgs } from 'ng-zorro-antd';
import { UploadFile } from 'ng-zorro-antd';
import { webSocket } from 'rxjs/webSocket';
// import { copyFileSync } from 'fs';
import { ElementRef } from '@angular/core';
import { Buffer } from 'buffer';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-invoice-checkout',
  templateUrl: './invoice-checkout.component.html',
  styleUrls: ['./invoice-checkout.component.css']
})
/**
 * 发票查验-单票查验
 */
export class InvoiceCheckoutComponent implements OnInit {
  checkObj = {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  handCheckObj = {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  telCheckObj :any= {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  pdfCheckObj:any = {
    name: 'pdf',
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  obj = {
    'ReqSubpackage': {
      'token': '',// token 客户端登录成功标志
      'info_id': '', // 批次id
      'fpdm': '', //发票代码
      'fphm': '', // 发票号码
      'kprq': '', // 开票日期
      'fplx': '', // 发票类型
      'fplx_mc': '', // 发票类型名称
      'fplx_jc': '', // 发票类型简称
      'jelx_mc': '',// 类型名称
      'je': '', // 金额不含税，发票类型是01，02，03则必输
      'jym': '', // 校验码后六位，发票类型是04，10，11则必输
      'invoice_id': '', // 发票标识
      'cyfs': '1', // 查验方式
      'resultType': null, // 查询结果: 02查询不一致
      'ResCode': '',
      'ResMessage': ''
    },
    'version': '1.2.0.1803', // 版本
    'serviceId': '', // 接口名称
    'nsrsbh': '', // 纳税人识别号
    'nsrmc': '', // 纳税人名称
    'user_name': '',
    'jm': '1', // 加密
    'orgid': '', // 企业标识
    'areaCode': '', // 地区编码
    'lxfs': '' // 联系方式
  };
  smqObj = {
    checkInvoiceCode: '',
    checkInvoiceDate: '',
    checkInvoiceNumber: '',
    checkInvoiceMoney: '',
    spanTit: '金额/校验码 ：',
    checkInvoiceType: '02',
    invoiceName: '增值税普通发票',
  };
  // 发票查验
  fpcyObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": "C739B1E82B1748FE9690BA038D4A8A0A",
      "pcClientBZ": null,
      "kprq": "2018-11-19",
      "jejym": "205293",
      "cyfs": null
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_DPCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 发票查验明细
  fpcymxObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": "C739B1E82B1748FE9690BA038D4A8A0A",
      "pcClientBZ": null,
      "kprq": null,
      "jejym": null,
      "cyfs": "1"
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_FPCYMX",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 手机
  telObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": "C739B1E82B1748FE9690BA038D4A8A0A",
      "pcClientBZ": null,
      "kprq": "2018-11-19",
      "jejym": "205293",
      "cyfs": null
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_DPCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 重复查验
  repeObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "fpdm": "",
      "fphm": ""
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_CFCY",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  //风险企业
  vbObj = {
    "ReqSubpackage": {
      "token": "ZmQyNWU0ZjQtMDlmZS00ZGM0LWIxMzMtODU2M2Y5YTNmYWQw",
      "invoiceId": "3A37CDD8DA8E4279814AEF7598CF8275"
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_FXQYCX",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };

  // 设备型号
  unitTypeObj = {
    "ReqSubpackage": {
      "token": "OWI2ZDQ3ZWMtOTMyYS00ZGQyLWFlMWEtZDdiYWM0YjJlMDcz",
      "devType": "2"
    },
    "version": "1.3.0.1811",
    "serviceId": "ZXTPLCYFW_SBXHJGM",
    "nsrsbh": "ZHANGWANPING201901",
    "nsrmc": "5byg5LiH5bmz5pyJ6ZmQ6LSj5Lu75YWs5Y+4",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "D91BCBB2AB4A47519D1F977F0BC002FA",
    "areaCode": "110",
    "lxfs": ""
  };
  // 次数
  numObj = {
    "ReqSubpackage": {
      "token": "MTgxYzJiZGYtMTQyNi00ODVjLWI3ZWYtM2Q4ZGY2NGQ3NDU0",
      "invoice_id": null,
      "pcClientBZ": null,
      "kprq": null,
      "jejym": null,
      "cyfs": null
    },
    "version": "1.2.0.1803",
    "serviceId": "ZXTPLCYFW_XFSQ",
    "nsrsbh": "500102010002163",
    "nsrmc": "5Y2H57qn54mI5rWL6K+V55So5oi3MjE2Mw==",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "4CD2CFCEEB2B45B5AA54870870413501",
    "areaCode": "500000",
    "lxfs": ""
  };
  // 验证码
  yzmObj = {
    "ReqSubpackage": {
      "token": "",
      "invoice_id": "",
      "pcClientBZ": null,
      "kprq": null,
      "jejym": null,
      "cyfs": null
    },
    "version": "1.3.0.1811",
    "serviceId": "ZXTPLCYFW_HQYZM",
    "nsrsbh": "ZHANGWANPING201901",
    "nsrmc": "5byg5LiH5bmz5pyJ6ZmQ6LSj5Lu75YWs5Y+4",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "D91BCBB2AB4A47519D1F977F0BC002FA",
    "areaCode": "110",
    "lxfs": ""
  }
  ifYzmObj = {
    "ReqSubpackage": {
      "yzm_id": "AB38DE4DB12D440596184F2E8DE7643A_2",
      "yzm_ts": null,
      "yzm_image": null,
      "token": "ODhiZDQ1Y2UtNDI1YS00MTQwLWE1MDAtN2NjYjdlOTNlZjI5",
      "invoice_id": "03F5350AACF546ACA47D355653581BAB",
      "yzm_info": "VkczSEtJ",
      "kprq": "2018-11-18",
      "jejym": "339210",
      "ResCode": null,
      "ResMessage": null,
      "key6": null
    },
    "version": "1.3.0.1811",
    "serviceId": "ZXTPLCYFW_TJYZM",
    "nsrsbh": "ZHANGWANPING201901",
    "nsrmc": "5byg5LiH5bmz5pyJ6ZmQ6LSj5Lu75YWs5Y+4",
    "user_name": "管理用户",
    "jm": "1",
    "orgid": "D91BCBB2AB4A47519D1F977F0BC002FA",
    "areaCode": "110",
    "lxfs": ""
  }
  buttonItems = [
    { name: '查验状态', bgColor: 'gary', bgImg: '', },
    { name: '发票正常', bgColor: 'gary', bgImg: '', },
    { name: '重复查验', bgColor: 'gary', bgImg: '', },
    { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
    { name: '禁报查验', bgColor: 'gary', bgImg: '', },
    { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
    { name: '风险企业', bgColor: 'gary', bgImg: '', },
  ];
  today = new Date();
  dateFormat = 'yyyy-MM-dd';
  decryptedResJson: any;
  fpcyDecryptedResJson: any;
  fpcymxDecryptedResJson: any;
  cfcyDecryptedResJson: any;
  sbxhDecryptedResJson: any;
  fxqyDecryptedResJson: any;
  yzmDecryptedResJson: any;
  yzmmxDecryptedResJson: any;
  endCfcyData: any;
  endSbxhData: {};
  url: '';
  isVisible = false;
  checkInvoiceCodeFlag = false;
  checkInvoiceDateFlag = false;
  checkInvoiceNumberFlag = false;
  checkInvoiceMoneyFlag = false;
  checkInvoiceTit: any;
  checkInvoiceType: any;
  dNew: any;
  myDateNew: any;
  aDate: any;
  bDate: any;
  day: any;
  navFlag = 0;
  endData = {};
  endYear: any;
  endMonth: any;
  endDay: any;
  repePopFlag = false;
  cfcyFlag = false;
  cfcyTit: any;
  fileList: any;
  pdfObj = {
    "nsrsbh": "",
    "orgid": "",
    "user_name": ""
  };
  pdfEndData: {};
  file: any;
  isSpinning = false;
  explainTit: any;
  explainFlag = false;
  numData: {};
  smqItem: any;
  disFlag = true;
  checkDisFlag = true;
  preDate: any;
  ws: WebSocket;
  xsdIndex;
  smqValue: any;
  smqText = "";
  ventureBusinessFlag = false;
  tabName: any;
  endFxqyData: {};
  tipFlag = false;
  yzmFlag = false;
  yamValue: any;
  ewmImgUlr: any;
  ewmsrc: any;
  ewmsrcTwo: any;
  fileListDel: [];
  yzmImg: any;
  imgColorTit: any;
  imgcolorYs: any;
  inputValue = '';
  markFlag = false;
  updateTime: any;
  constructor(
    private el: ElementRef,
    private httpService: HttpService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.router = router;
    console.log(router);
    // 订阅路由事件
    router.events.subscribe((event: NavigationEnd) => {
      // 判断路由的事件类型（也就是路由的生命周期）
      if (event instanceof ActivationStart) { // 当导航成功结束时执行

        this.ws.close();
      }
    });

  }





  ngOnInit() {

    // this.residueNumber();
    //   console.log(eval("app.ReLogin()"));
    // console.log("1111")
    this.connectWs();
    // 手机二维码图片处理
    const ewmimg = eval('HostApp.getHostData("PhoneCheckGuide")');
    this.ewmImgUlr = JSON.parse(ewmimg).xxList;
    console.log(this.ewmImgUlr);
    // this.ewmsrc = new Buffer(this.ewmImgUlr[0].imageInfo, 'base64').toString();
    this.ewmsrc = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/JPEG;base64,' + this.ewmImgUlr[0].imageInfo);
    console.log(this.ewmsrc);
    //
    this.ewmsrcTwo = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.ewmImgUlr[1].imageInfo);

    const curDate = new Date();
    const d = new Date(curDate.getTime() - 24 * 60 * 60 * 1000);
    this.preDate = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
      '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
    console.log(this.preDate);
    this.checkObj.checkInvoiceDate = this.preDate;
  }
  //扫描枪
  smqClick() {
    this.smqValue = '';
    this.smqItem = [];
    const resBoolean = eval('app.CheckScanningGun()');
    console.log(resBoolean);
    if (!resBoolean) {
      this.isVisible = true;
      this.checkInvoiceTit = '未检测到支持的扫描枪，请插入扫描枪或联系服务商解决';
    } else {
      this.isVisible = false;
      console.log('start scanning!');
      console.log('01,01,1100182130,13860411,87758.63,20180625,C62D\\r\\n');
      // this.smqItem.push(this.smqValue);
    }

    // const a = '01,01,2102173130,01184724,172.41,20181116,,DF9';

  }


  // 扫描枪
  setKeyCode(keycode: any) {
    switch (keycode) {
      case 8:
        this.smqText = this.smqText.substring(0, this.smqText.length - 1);
        break;
      case 48:
        this.smqText += "0";
        break;
      case 49:
        this.smqText += "1";
        break;
      case 50:
        this.smqText += "2";
        break;
      case 51:
        this.smqText += "3";
        break;
      case 52:
        this.smqText += "4";
        break;
      case 53:
        this.smqText += "5";
        break;
      case 54:
        this.smqText += "6";
        break;
      case 55:
        if (this.lastKeyCode == 16) {
          this.smqText += "&";
        } else {
          this.smqText += "7";
        }
        break;
      case 56:
        this.smqText += "8";
        break;
      case 57:
        this.smqText += "9";
        break;
      case 65:
        this.smqText += "A";
        break;
      case 66:
        this.smqText += "B";
        break;
      case 67:
        this.smqText += "C";
        break;
      case 68:
        this.smqText += "D";
        break;
      case 69:
        this.smqText += "E";
        break;
      case 70:
        this.smqText += "F";
        break;
      case 71:
        this.smqText += "G";
        break;
      case 72:
        this.smqText += "H";
        break;
      case 73:
        this.smqText += "I";
        break;
      case 74:
        this.smqText += "J";
        break;
      case 75:
        this.smqText += "K";
        break;
      case 76:
        this.smqText += "L";
        break;
      case 77:
        this.smqText += "M";
        break;
      case 78:
        this.smqText += "N";
        break;
      case 79:
        this.smqText += "O";
        break;
      case 80:
        this.smqText += "P";
        break;
      case 81:
        this.smqText += "Q";
        break;
      case 82:
        this.smqText += "R";
        break;
      case 83:
        this.smqText += "S";
        break;
      case 84:
        this.smqText += "T";
        break;
      case 85:
        this.smqText += "U";
        break;
      case 86:
        this.smqText += "V";
        break;
      case 87:
        this.smqText += "W";
        break;
      case 88:
        this.smqText += "X";
        break;
      case 89:
        this.smqText += "Y";
        break;
      case 90:
        this.smqText += "Z";
        break;
      case 188:
        this.smqText += ",";
        break;
      case 187:
        this.smqText += "=";
        break;
      case 110:
        this.smqText += ".";
        break;
      case 190:
        this.smqText += ".";
        break;
      case 189:
        if (this.lastKeyCode == 16) {
          this.smqText += "_";
        } else {
          this.smqText += "-";
        }
        break;
      case 191:
        if (this.lastKeyCode == 16) {
          this.smqText += "?";
        } else {
          this.smqText += "/";
        }
        break;
      case 186:
        this.smqText += ":";
        break;
      default:
        break;
    }
  }
  lastKeyCode = 0;
  // 扫描枪
  smqKeyUp(e) {

    if (e.keyCode != 13) {
      this.setKeyCode(e.keyCode);
      this.lastKeyCode = e.keyCode;
      console.log("key:" + e.keyCode + " char:" + e.charCode);
      console.log(this.smqText);
      this.smqValue = '';
    }
    else {
     // 深圳区块链发票
     if (/https:\/\/.*shenzhen.chinatax.gov.cn\//.test(this.smqText.toLowerCase())) {
      this.getQklInvoiceInfo(this.smqText.toLowerCase());
      return;
    }

      // alert("拿到结果：" + this.smqText + "\n开始单票查验");
      this.smqValue = '';
      // 去掉日期中的-
      this.smqText = this.smqText.replace(/-/g, "");
      this.smqItem = this.smqText.split(',');
      console.log(this.smqItem);
      if (this.smqItem.length >= 7) {
        this.smqObj.checkInvoiceCode = this.smqItem[2];
        const onedate = this.smqItem[5].slice(0, 4) + '-' + this.smqItem[5].slice(4, 6) + '-' + this.smqItem[5].slice(6, 8);
        // /  console.log( this.smqItem[5].slice(7, 2) + '-');
        console.log(onedate);
        console.log(this.smqItem[5]);
        this.smqObj.checkInvoiceNumber = this.smqItem[3];
        this.smqObj.checkInvoiceDate = onedate;
        this.codeChangeCheck(this.smqObj);
        if (this.smqObj.checkInvoiceType == '01' || this.smqObj.checkInvoiceType == '02' || this.smqObj.checkInvoiceType == '03'
          || this.smqObj.checkInvoiceType == '15' || this.smqObj.checkInvoiceType == '08' || this.smqObj.checkInvoiceType == '09') {
          this.smqObj.checkInvoiceMoney = this.smqItem[4];
        } else {
          this.smqObj.checkInvoiceMoney = this.smqItem[6].slice(this.smqItem[6].length - 6);
        }
        console.log(this.smqObj.checkInvoiceMoney);
        console.log(this.smqObj);
        this.checkObj = this.smqObj;
        this.smqValue = '';
        this.smqText = '';
        this.smqItem = [];
        this.dataTreating(this.smqObj);

      } else {
        this.isVisible = true;
        this.explainFlag = false;
        this.checkInvoiceTit = '文件解读失败，请重试';
      }

    }
  }

  getQklInvoiceInfo(url: string) {
    this.isSpinning = true;
    this.smqValue = "";
    this.smqText = "";
    const fpcyQklObj = {
      ReqSubpackage: btoa(JSON.stringify({ key6: url })),
      version: "1.2.0.1803",
      serviceId: "ZXTPLCYFW_QRCODE_SZ",
      nsrsbh: "",
      nsrmc: "",
      user_name: "",
      jm: "0",
      orgid: "",
      areaCode: "",
      lxfs: "",
      cyfs: "5",
    };
    this.httpService.newGetPublicAttr(fpcyQklObj);
    this.httpService.invCheck('/api/plfpcy.controller', JSON.stringify(fpcyQklObj)).subscribe((res) => {
      console.log(res);
      this.isSpinning = false
      if (res.returnCode == "00000000") {
        const smqObj = JSON.parse(res.ResSubpackage);
        smqObj.checkInvoiceCode = smqObj.fpdm;
        smqObj.checkInvoiceType = smqObj.fplx;
        smqObj.checkInvoiceNumber = smqObj.fphm;
        smqObj.checkInvoiceMoney = smqObj.je;
        smqObj.checkInvoiceDate = smqObj.kprq;
        this.checkObj = smqObj;
        this.dataTreating(smqObj);
      }
    });
  }
  // socket 连接
  connectWs() {
    if (this.ws != null) { this.ws.close() };
    const userID = eval("HostApp.getHostData('nsrsbh')");
    const user_name = eval("HostApp.getHostData('user_name')");
    console.log(userID);
    console.log(user_name);
    // 修改为域名访问 20200224   47.95.228.72 sjcy.dqyfapiao.com
    const socket = "ws://sjcy.dqyfapiao.com/api/websocketHandler?id=" + userID + "&userName=" + user_name;
    // const socket = "ws://172.18.0.115:8081/api/websocketHandler?id=" + userID + "&userName=" + user_name;
    // const socket = "ws://172.18.2.80:8080/api/websocketHandler?id=" + userID + "&userName=" + user_name;
    // const socket = "ws://172.18.0.147:8080/api/websocketHandler?id=" + userID + "&userName=" + user_name;


    
    this.ws = new WebSocket(socket);
    let that = this;
    this.ws.onopen = (e) => {
      //socket 开启后执行，可以向后端传递信息
      this.ws.send('sonmething');
      console.log('开启');
    }
    this.ws.onmessage = (event) => {
      //socket 获取后端传递到前端的信息
      that.ws.send('sonmething');
      console.log('获取xinxi');
      console.log(event);
      // socketType":"PHONE_CHECK
      const en = JSON.parse(event.data);
      const ev = JSON.parse(JSON.parse(event.data).ResSubpackage);
      console.log(en);
      console.log(ev);
      if (en['socketType'] == 'PHONE_CHECK') {
        console.log('shouji')
        this.telCheckObj.checkInvoiceCode = ev.fpdm;
        this.telCheckObj.checkInvoiceDate = ev.kprq;
        this.telCheckObj.checkInvoiceNumber = ev.fphm;
        this.telCheckObj.checkInvoiceType = ev.fplx;
        if(ev.fplx == 'S1'){
          this.telCheckObj.checkInvoiceMoney = ev.je;
          this.telCheckObj.jym = ev.jym;
        }else{
          this.telCheckObj.checkInvoiceMoney = ev.jejym
        }

        console.log(this.telCheckObj);
        this.dataTreating(this.telCheckObj);
      }
    }
    this.ws.onerror = (e) => {
      //socket error信息
    }
    this.ws.onclose = (event) => {
      //socket 关闭后执行
    }
  }
  // 置空obj
  emptyObjFn(e) {
    e = {
      checkInvoiceCode: '',
      checkInvoiceDate: '',
      checkInvoiceNumber: '',
      checkInvoiceMoney: '',
      spanTit: '金额/校验码 ：',
      checkInvoiceType: '02',
      invoiceName: '增值税普通发票',
    };
  }
  //点击tab
  navClick(e) {
    this.tabName = e;
    if (e.target.innerText == '手工查验') {
      this.markFlag = false;
      this.inputValue = '';
      this.fileListDel = [];
      this.emptyObjFn(this.checkObj);
      this.emptyObjFn(this.pdfCheckObj);
      this.emptyObjFn(this.telCheckObj);
      this.emptyObjFn(this.handCheckObj);
      this.checkObj = {
        checkInvoiceCode: '',
        checkInvoiceDate: this.preDate,
        checkInvoiceNumber: '',
        checkInvoiceMoney: '',
        spanTit: '金额/校验码 ：',
        checkInvoiceType: '02',
        invoiceName: '增值税普通发票',
      };
      this.handCheckObj = {
        checkInvoiceCode: '',
        checkInvoiceDate: this.preDate,
        checkInvoiceNumber: '',
        checkInvoiceMoney: '',
        spanTit: '金额/校验码 ：',
        checkInvoiceType: '02',
        invoiceName: '增值税普通发票',
      };
      this.buttonItems = [
        { name: '查验状态', bgColor: 'gary', bgImg: '', },
        { name: '发票正常', bgColor: 'gary', bgImg: '', },
        { name: '重复查验', bgColor: 'gary', bgImg: '', },
        { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
        { name: '禁报查验', bgColor: 'gary', bgImg: '', },
        { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
        { name: '风险企业', bgColor: 'gary', bgImg: '', },
      ];
      this.navFlag = 3;
      this.disFlag = true;
      this.endData = {};
      this.buttonItems.forEach(element => {
        element.bgColor = 'gary';
      });
    }
    if (e.target.innerText == 'PDF/OFD导入查验') {
      this.markFlag = false;
      this.inputValue = '';
      this.fileListDel = [];
      this.buttonItems = [
        { name: '查验状态', bgColor: 'gary', bgImg: '', },
        { name: '发票正常', bgColor: 'gary', bgImg: '', },
        { name: '重复查验', bgColor: 'gary', bgImg: '', },
        { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
        { name: '禁报查验', bgColor: 'gary', bgImg: '', },
        { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
        { name: '风险企业', bgColor: 'gary', bgImg: '', },
      ];
      this.emptyObjFn(this.checkObj);
      this.emptyObjFn(this.pdfCheckObj);
      this.emptyObjFn(this.telCheckObj);
      this.emptyObjFn(this.handCheckObj);
      this.navFlag = 2;
      this.endData = {};
    }
    if (e.target.innerText == '手机查验') {
      this.markFlag = false;
      this.inputValue = '';
      this.fileListDel = [];
      this.emptyObjFn(this.checkObj);
      this.emptyObjFn(this.pdfCheckObj);
      this.emptyObjFn(this.telCheckObj);
      this.emptyObjFn(this.handCheckObj);
      this.navFlag = 1;
      this.endData = {};
      this.buttonItems = [
        { name: '查验状态', bgColor: 'gary', bgImg: '', },
        { name: '发票正常', bgColor: 'gary', bgImg: '', },
        { name: '重复查验', bgColor: 'gary', bgImg: '', },
        { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
        { name: '禁报查验', bgColor: 'gary', bgImg: '', },
        { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
        { name: '风险企业', bgColor: 'gary', bgImg: '', },
      ];
    }
    if (e.target.innerText == '扫描枪/手工查验') {
      this.markFlag = false;
      this.inputValue = '';
      this.fileListDel = [];
      this.checkObj = {
        checkInvoiceCode: '',
        checkInvoiceDate: this.preDate,
        checkInvoiceNumber: '',
        checkInvoiceMoney: '',
        spanTit: '金额/校验码 ：',
        checkInvoiceType: '02',
        invoiceName: '增值税普通发票',
      };
      this.handCheckObj = {
        checkInvoiceCode: '',
        checkInvoiceDate: this.preDate,
        checkInvoiceNumber: '',
        checkInvoiceMoney: '',
        spanTit: '金额/校验码 ：',
        checkInvoiceType: '02',
        invoiceName: '增值税普通发票',
      };
      this.emptyObjFn(this.checkObj);
      this.emptyObjFn(this.pdfCheckObj);
      this.emptyObjFn(this.telCheckObj);
      this.emptyObjFn(this.handCheckObj);
      this.endData = {};
      this.checkDisFlag = true;
      this.buttonItems = [
        { name: '查验状态', bgColor: 'gary', bgImg: '', },
        { name: '发票正常', bgColor: 'gary', bgImg: '', },
        { name: '重复查验', bgColor: 'gary', bgImg: '', },
        { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
        { name: '禁报查验', bgColor: 'gary', bgImg: '', },
        { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
        { name: '风险企业', bgColor: 'gary', bgImg: '', },
      ];
      this.navFlag = 0;
    }

  }
  // 发票代码回车
  checkKeyUp(e) {
    this.checkSMBtnCanCheck(this.checkObj);
    this.checkObj.checkInvoiceCode = this.checkObj.checkInvoiceCode.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    if (e.keyCode == '13') {
      const oneEnter = document.getElementById('fphm');
      oneEnter.focus();
    }
  }
  // 发票号码回车
  checkTwoKeyUp(e) {
    this.checkSMBtnCanCheck(this.checkObj);
    this.checkObj.checkInvoiceNumber = this.checkObj.checkInvoiceNumber.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    if (e.keyCode == '13') {
      const twoEnter = document.getElementById('kprq');
      const inp = twoEnter.querySelector('input');
      inp.focus();
    }

  }
  // 日期回车
  checkThreeKeyUp(e) {
    if (!e) {
      const jejym = document.getElementById('jejym');
      jejym.focus();
    }

  }
  // 手工-发票代码 回车
  handfpdmEnter(e) {
    this.handCheckObj.checkInvoiceCode = this.handCheckObj.checkInvoiceCode.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    this.checkSMBtnCanCheck(this.handCheckObj);
    if (e.keyCode == '13') {
      const handfphm = document.getElementById('handfphm');
      handfphm.focus();
    }
  }
  // 手工-发票号码回车
  handfphmEnter(e) {
    this.handCheckObj.checkInvoiceNumber = this.handCheckObj.checkInvoiceNumber.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    this.checkSMBtnCanCheck(this.handCheckObj);

    if (e.keyCode == '13') {
      const handkprq = document.getElementById('handkprq');
      const inpu = handkprq.querySelector('input');
      inpu.focus();
    }
  }
  // 日期回车
  handkprqEnter(e) {
    console.log(e);
    if (!e) {
      const handjejym = document.getElementById('handjejym');
      handjejym.focus();
    }
  }
  // 不可选择的时间
  disabledDate = (current: Date) => {
    //  console.log(this.today);
    // Can not select days before today and today
    const pre = new Date();
     //  业务逻辑调整 可查询五年内数据 20200206
    pre.setFullYear(pre.getFullYear() - 5);

    return current > this.today || current < pre;
  }
  // 发票代码change校验
  codeChangeCheck(e) {
    if (e.checkInvoiceCode.length == 12) {
      if (e.checkInvoiceCode == '144031539110' || e.checkInvoiceCode == '131001570151' ||
        e.checkInvoiceCode == '133011501118' || e.checkInvoiceCode == '111001571071') {
        e.checkInvoiceType = '10';
      } else if (e.checkInvoiceCode.startsWith('0')) {
        if (e.checkInvoiceCode.substring(10) == '11') {
          e.checkInvoiceType = '10';
        } else if (e.checkInvoiceCode.substring(10) == '12')//通行费
        {
          e.checkInvoiceType = '14';
        } else if (e.checkInvoiceCode.substring(10) == '17')//二手车
        {
          e.checkInvoiceType = '15';
        } else if (e.checkInvoiceCode.substring(10) == '13')//增值税电子专票  需求修改标识 lusz--
        {
          e.checkInvoiceType = '08';
        }else if (e.checkInvoiceCode.substring(10) == '06' ||
          e.checkInvoiceCode.substring(10) == '07') {
          e.checkInvoiceType = '11';
        } else if (e.checkInvoiceCode.substring(10) == '04' ||
          e.checkInvoiceCode.substring(10) == '05') {
          e.checkInvoiceType = '04';
        }
      }  else if (e.checkInvoiceCode.startsWith("14403") && e.checkInvoiceCode.substring(8, 9) == "9") {
        e.checkInvoiceType = "S1";
      }else {
        if (e.checkInvoiceCode.substring(7, 8) == '2') {
          e.checkInvoiceType = '03';
        }
      }
    } else {
      if(e.checkInvoiceCode.length == 0 && e.checkInvoiceNumber.length == 20){
        e.checkInvoiceType = '09';
      }else{
        if (e.checkInvoiceCode.substring(7, 8) == '1' || e.checkInvoiceCode.substring(7, 8) == '5') {
          e.checkInvoiceType = '01';
        } else if (e.checkInvoiceCode.substring(7, 8) == '3' ||
          e.checkInvoiceCode.substring(7, 8) == '6') {
          e.checkInvoiceType = '04';
        } else if (e.checkInvoiceCode.substring(7, 8) == '2' ||
          e.checkInvoiceCode.substring(7, 8) == '7') {
          e.checkInvoiceType = '02';
        }
      }
    }

    if (e.checkInvoiceType == '01') {// 增值税专票// e.spanTit是前端文本框前的提示文字
      e.spanTit = '金额(不含税) ：';// 不含税
      e.invoiceName = '增值税专用发票';
    } else if (e.checkInvoiceType == '02') { // 增值税货物运输
      e.spanTit = '金额/校验码 ：'; // 含税
      e.invoiceName = '增值税普通发票';
    } else if (e.checkInvoiceType == '03') {// 机动车销售
      e.spanTit = '不含税价 ：';
      e.invoiceName = '机动车销售统一发票';
    } else if (e.checkInvoiceType == '15') {// 二手车
      e.spanTit = '车价合计 ：';
      e.invoiceName = '二手车销售统一发票';
    } else if (e.checkInvoiceType == '04') {// 增值税普票
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税普通发票';
    } else if (e.checkInvoiceType == '10') {// 增值税电子普票
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税电子普通发票';
    } else if (e.checkInvoiceType == '11') {// 增值税卷票
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税普通发票（卷票）';
    } else if (e.checkInvoiceType == '14') {// 通行费
      e.spanTit = '校验码后六位 ：';
      e.invoiceName = '增值税电子普通发票';
    } else if (e.checkInvoiceType == '08') {// 增值税电子专票  需求修改标识 lusz--
      e.spanTit = '金额(不含税)：';
      e.invoiceName = '增值税电子专用发票';
    } else if (e.checkInvoiceType == '09') {// 全电
      e.spanTit = '价税合计：';
      e.invoiceName = '电子发票';
    } else if (e.checkInvoiceType == "S1") {
      // 深圳电子普通发票
      e.spanTit = "金额 ：";
      e.invoiceName = "深圳电子普通发票"; // 发票种类名称变更
    }else {
      e.spanTit = '金额/校验码 ：';
      e.invoiceName = '增值税普通发票';
    }
  }


  // 发票代码校验
  checkCode(e) {
    if (e.checkInvoiceCode) {
      let codeReg = /^[0-9]*$/;
      if (codeReg.test(e.checkInvoiceCode)) {
        if (e.checkInvoiceCode.length == 10 || e.checkInvoiceCode.length == 12) {
          console.log(e.checkInvoiceCode)

          if (e.checkInvoiceType) {

            if (e.checkInvoiceType == '01' || //增值税专用发票
              e.checkInvoiceType == '03' || //机动车销售统一发票
              e.checkInvoiceType == '04' || //增值税普通发票
              e.checkInvoiceType == '10' || //增值税电子普通发票
              e.checkInvoiceType == '11' ||//增值税普通发票（卷票）
              e.checkInvoiceType == '14' || //通行费
              e.checkInvoiceType == '15' ||//二手车
              e.checkInvoiceType == '08'||//增值税电子专票  需求修改标识 lusz--
              e.checkInvoiceType == "09" || // 全电
              e.checkInvoiceType == "S1" // 深圳电子普通发票
            ){
              this.checkInvoiceCodeFlag = true;
            } else {
              this.checkInvoiceCodeFlag = false;
              this.isVisible = true;
              this.checkInvoiceTit = '暂不支持该类发票查验';
            }
          } else {
            this.checkInvoiceCodeFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '暂不支持该类发票查验';
          }
        } else {
          this.checkInvoiceCodeFlag = false;
          this.isVisible = true;
          this.checkInvoiceTit = '发票代码有误，请输入10位或者12位发票代码';
        }
      } else {
        this.checkInvoiceCodeFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '发票代码有误，请输入10位或者12位发票代码';
      }
    } else {
      this.checkInvoiceCodeFlag = true;

      // this.checkInvoiceCodeFlag = false;
      // this.isVisible = true;
      // this.checkInvoiceTit = '请输入发票代码';
    }


  }
  // 发票日期校验
  checkDate(e) {

    if (e.checkInvoiceDate) {
      console.log(e.checkInvoiceDate);
      // 开票日期 相差天数
      const d = new Date(e.checkInvoiceDate);
      this.dNew = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) +
        '-' + ((d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate()));
      const myDate = new Date();
      this.myDateNew = myDate.getFullYear() + '-' + ((myDate.getMonth() + 1) < 10 ? '0' + (myDate.getMonth() + 1) :
        (myDate.getMonth() + 1)) + '-' + ((myDate.getDate()) < 10 ? '0' + (myDate.getDate()) : (myDate.getDate()));


      console.log(this.dNew);
      // if (this.dNew == this.myDateNew) {
      //   this.checkInvoiceDateFlag = false;
      //   this.isVisible = true;
      //   this.checkInvoiceTit = '当天开具的发票，请在24小时后查验';

      // } else {
      //   this.aDate = new Date(this.dNew);
      //   this.bDate = new Date(this.myDateNew);
      //   this.day = (this.bDate - this.aDate) / (24 * 60 * 60 * 1000);
      //   if (this.day <= 365) {
      //     this.checkInvoiceDateFlag = true;
      //     e.checkInvoiceDate = this.dNew;
      //   } else {
      //     this.checkInvoiceDateFlag = false;
      //     this.isVisible = true;
      //     this.checkInvoiceTit = '仅支持一年内开具的发票查验';
      //     this.endData = {};
      //     this.buttonItems = [
      //       { name: '查验状态', bgColor: 'gary', bgImg: '', },
      //       { name: '发票正常', bgColor: 'gary', bgImg: '', },
      //       { name: '重复查验', bgColor: 'gary', bgImg: '', },
      //       { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
      //       { name: '禁报查验', bgColor: 'gary', bgImg: '', },
      //       { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
      //       { name: '风险企业', bgColor: 'gary', bgImg: '', },
      //     ];
      //   }
      // }

      this.aDate = new Date(this.dNew);
      this.bDate = new Date(this.myDateNew);
      this.day = (this.bDate - this.aDate) / (24 * 60 * 60 * 1000);
      //  业务逻辑调整 可查询五年内数据 20200206
      if (this.day <= 365*5) {
        this.checkInvoiceDateFlag = true;
        e.checkInvoiceDate = this.dNew;
      } else {
        this.checkInvoiceDateFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '仅支持五年内开具的发票查验';
        this.endData = {};
        this.buttonItems = [
          { name: '查验状态', bgColor: 'gary', bgImg: '', },
          { name: '发票正常', bgColor: 'gary', bgImg: '', },
          { name: '重复查验', bgColor: 'gary', bgImg: '', },
          { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
          { name: '禁报查验', bgColor: 'gary', bgImg: '', },
          { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
          { name: '风险企业', bgColor: 'gary', bgImg: '', },
        ];
      }

    } else {
      this.checkInvoiceDateFlag = false;
      this.isVisible = true;
      this.checkInvoiceTit = '请输入发票日期';
    }
  }

  // 发票号码校验
  checkNumber(e) {
    if (e.checkInvoiceNumber) {
  
    
      if(e.checkInvoiceCode.length == 0){
        e.checkInvoiceType = '09';
      }

      let numReg = /^[0-9]*$/;
      if (numReg.test(e.checkInvoiceNumber)) {

        if(e.checkInvoiceType == '09'){
          if (e.checkInvoiceNumber.length == 20 ) {
            this.checkInvoiceNumberFlag = true;
          } else {
            this.checkInvoiceNumberFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '发票号码有误，请输入20位发票号码';
          }

            e.spanTit = '价税合计：';
            e.invoiceName = '电子发票';

          
        }else{
          if (e.checkInvoiceNumber.length == 8 ) {
            this.checkInvoiceNumberFlag = true;
          } else {
            this.checkInvoiceNumberFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '发票号码有误，请输入8位发票号码';
          }
        }


      } else {
        this.checkInvoiceNumberFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '发票号码有误，请输入发票号码';
      }

    } else {
      this.checkInvoiceNumberFlag = false;
      this.isVisible = true;
      this.checkInvoiceTit = '请输入发票号码';
    }
  }

  // 金额、校验码等校验
  checkMoneyMore(e) {
    // let monReg = /^[0-9]*$/;
    let monReg = /^\d+(\.\d+)?$/;

    if (e.checkInvoiceType == '01' || e.checkInvoiceType == '02' || e.checkInvoiceType == '03' || e.checkInvoiceType == '15'
      || e.checkInvoiceType == '08' || e.checkInvoiceType == '09' ||
      e.checkInvoiceType == "S1") {  //lusz--
        console.log('e :>> ', e);
      if (e.checkInvoiceMoney) {
        if (monReg.test(e.checkInvoiceMoney)) {
          this.checkInvoiceMoneyFlag = true;
        } else {
          this.checkInvoiceMoneyFlag = false;
          this.isVisible = true;
          this.checkInvoiceTit = '请输入金额';
        }

      } else {
        this.checkInvoiceMoneyFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '请输入金额';
      }
    } else {
      if (e.checkInvoiceMoney) {

        if (monReg.test(e.checkInvoiceMoney)) {

          if (String(e.checkInvoiceMoney).length == 6) {
            this.checkInvoiceMoneyFlag = true;
          } else {

            this.checkInvoiceMoneyFlag = false;
            this.isVisible = true;
            this.checkInvoiceTit = '校验码位数有误，请输入校验码后6位';
          }
        } else {

          this.checkInvoiceMoneyFlag = false;
          this.isVisible = true;
          this.checkInvoiceTit = '校验码位数有误，请输入校验码后6位';
        }

      } else {

        this.checkInvoiceMoneyFlag = false;
        this.isVisible = true;
        this.checkInvoiceTit = '请输入校验码后6位';
      }
    }

  }
  // 发票代码change
  checkCodeChange() {
    // 011001700111
    this.codeChangeCheck(this.checkObj);
    this.checkSMBtnCanCheck(this.checkObj);

  }
  //发票号码 change
  checkNubChange(){
    this.codeChangeCheck(this.checkObj);    
    this.checkSMBtnCanCheck(this.checkObj);
  }

  handCheckNubChange(){
    this.codeChangeCheck(this.handCheckObj);    
     this.checkSMBtnCanCheck(this.handCheckObj);
  }

  // 手工查验发票代码change
  handworkCheckCodeChange() {
    this.codeChangeCheck(this.handCheckObj);   
     this.checkSMBtnCanCheck(this.handCheckObj);
  }
  // 响应数据状态处理
  resDataTypeDispose(resDataTypeNumber) {
    if (resDataTypeNumber == '02') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询不一致，请重新核对发票输入信息';
      this.isSpinning = false;
    } else if (resDataTypeNumber == '03') {
      this.isVisible = true;
      this.checkInvoiceTit = '查无此票';
      this.isSpinning = false;
    } else if (resDataTypeNumber == '04') {
      this.isVisible = true;
      this.checkInvoiceTit = '超过该张发票当日查验次数（请于次日再次查验）';
      this.isSpinning = false;
      this.endData = {};
      this.buttonItems = [
        { name: '查验状态', bgColor: 'gary', bgImg: '', },
        { name: '发票正常', bgColor: 'gary', bgImg: '', },
        { name: '重复查验', bgColor: 'gary', bgImg: '', },
        { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
        { name: '禁报查验', bgColor: 'gary', bgImg: '', },
        { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
        { name: '风险企业', bgColor: 'gary', bgImg: '', },
      ];
      this.fileList = [];
    } else if (resDataTypeNumber == '05') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
      this.isSpinning = false;
    } else if (resDataTypeNumber == '06') {
      this.isVisible = true;
      this.checkInvoiceTit = '查询失败，请重试';
      this.isSpinning = false;
    }
    this.fileListDel = [];
  }
  // returnCode 响应状态码处理
  returnCodeDispose(returnCodeNumber) {
    if (returnCodeNumber == '00000001') {
      this.isVisible = true;
      this.checkInvoiceTit = '处理失败';
    } else if (returnCodeNumber == '00000002') {
      // app.ReLogin()
      this.isVisible = true;
      this.checkInvoiceTit = '重新登录';

    } else if (returnCodeNumber == '00000003') {
      this.isVisible = true;
      this.checkInvoiceTit = '处理超时';
    } else if (returnCodeNumber == '00000004') {
      this.isVisible = true;
      this.checkInvoiceTit = '系统繁忙、请稍后再试';
    } else if (returnCodeNumber == '00000005') {
      this.isVisible = true;
      this.checkInvoiceTit = '数据重复';
    } else if (returnCodeNumber == '00000016') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要验证码';
    } else if (returnCodeNumber == '00000017') {
      this.isVisible = true;
      this.checkInvoiceTit = '验证码错误或失效';
    } else if (returnCodeNumber == '00000018') {
      this.isVisible = true;
      this.checkInvoiceTit = '您的授权已使用完，需要购买后才能继续使用';
    } else if (returnCodeNumber == '00000021') {
      this.isVisible = true;
      this.checkInvoiceTit = '发票代码有误';
    } else if (returnCodeNumber == '999999999') {
      this.isVisible = true;
      this.checkInvoiceTit = '需要弹出服务端信息';
    }
    this.fileListDel = [];
  }

  // 接口、数据处理
  dataTreating(e) {
    this.markFlag = false;
    this.checkCode(e);
    if (this.checkInvoiceCodeFlag) {
      this.checkNumber(e);
      if (this.checkInvoiceNumberFlag) {
        this.checkDate(e);
        if (this.checkInvoiceDateFlag) {
          this.checkMoneyMore(e);
          if (this.checkInvoiceMoneyFlag) {

            this.isSpinning = true;

            console.log(e);
            // 构建请求json
            this.obj.ReqSubpackage.fpdm = e.checkInvoiceCode;
            console.log(e.checkInvoiceDate);
            this.obj.ReqSubpackage.kprq = e.checkInvoiceDate;
            this.obj.ReqSubpackage.fphm = e.checkInvoiceNumber;
            this.obj.ReqSubpackage.fplx = e.checkInvoiceType;
            this.obj.ReqSubpackage.fplx_mc = e.invoiceName;
            this.obj.ReqSubpackage.jelx_mc = e.spanTit;
            //lusz--
            if (e.checkInvoiceType == '01' || e.checkInvoiceType == '02' || e.checkInvoiceType == '03' || e.checkInvoiceType == '15' || e.checkInvoiceType == '08'|| e.checkInvoiceType == '09') {
              this.obj.ReqSubpackage.je = e.checkInvoiceMoney;
              this.obj.ReqSubpackage.jym = '';
            } else if (e.checkInvoiceType == '04' || e.checkInvoiceType == '10' ||
              e.checkInvoiceType == '11' || e.checkInvoiceType == '14') {
              this.obj.ReqSubpackage.jym = e.checkInvoiceMoney;
              this.obj.ReqSubpackage.je = '';
            }else if (e.checkInvoiceType == "S1") {
              this.obj.ReqSubpackage.jym = e.jym;
              this.obj.ReqSubpackage.je = e.checkInvoiceMoney;
            }
            switch (e.checkInvoiceType) {
              case '01':
                this.obj.ReqSubpackage.fplx_jc = '增值税专票';
                break;
              case '02':
                this.obj.ReqSubpackage.fplx_jc = '货运专用发票';
                break;
              case '03':
                this.obj.ReqSubpackage.fplx_jc = '机动车发票';
                break;
              case '04':
                this.obj.ReqSubpackage.fplx_jc = '增值税普票';
                break;
              case '08':
                this.obj.ReqSubpackage.fplx_jc = '增值税电子专用发票';//lusz==
                break;
              case '09':
                this.obj.ReqSubpackage.fplx_jc = '电子发票';//lusz==
                break;
              case '10':
                this.obj.ReqSubpackage.fplx_jc = '增值税电子普票';
                break;
              case "S1":
                this.obj.ReqSubpackage.fplx_jc = "深圳电子普通发票";
                break;
              case '11':
                this.obj.ReqSubpackage.fplx_jc = '增值税卷式普票';
                break;
              case '14':
                this.obj.ReqSubpackage.fplx_jc = '通行费发票';
                break;
              case '15':
                this.obj.ReqSubpackage.fplx_jc = '二手车发票';
                break;
            }
            console.log(eval("HostApp.getHostData('CommunicationKey')"));
            this.obj.serviceId = 'ZXTPLCYFW_XZFP';
            this.httpService.getPublicAttr(this.obj);
            console.log(this.obj);

            // 加密请求json子节点 加密后的obj
            // 发票查验 add
            const objOne = JSON.stringify(this.obj);
            const encryptedJson = eval("app.EncryptReqJson(objOne)");
            console.log(encryptedJson);
            // 使用加密后的json进行网络请求
            this.httpService.invCheck('/api/plfpcy.controller', encryptedJson).subscribe(res => {
              // 解密响应的json
              console.log(res);
              if (res.returnCode == '00000000') {

                const resOne = JSON.stringify(res);
                this.decryptedResJson = eval('app.DecryptResJson(resOne)');
                console.log(this.decryptedResJson);
                if (JSON.parse(this.decryptedResJson).ResSubpackage == '') {


                  this.invoiceExamination(e);
                } else {


                  console.log(JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType)
                  if (JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType) {
                    if (JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType == '00' ||
                      JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType == '01') {


                      this.invoiceExamination(e);

                    } else {
                      console.log(JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType);
                      if (JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType == '02' ||
                        JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType == '03' ||
                        JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType == '04'
                      ) {


                        this.residueNumber();
                      }
                      this.resDataTypeDispose(JSON.parse(JSON.parse(this.decryptedResJson).ResSubpackage).resultType);
                    }
                  } else {
                    console.log('-----------lv-----06');

                    this.invoiceExamination(e);
                  }



                }

              } else if (res.returnCode == '00000002') {
                if (eval("app.ReLogin()")) {
                  this.dataTreating(e);
                } else {
                  this.returnCodeDispose('00000003')
                }
              } else {
                this.returnCodeDispose(res.returnCode);
                this.isSpinning = false;

              }
            });

          }
        }
      }
    }
  }
  // 发票查验
  invoiceExamination(e) {
    // 发票查验
    console.log(JSON.parse((JSON.parse(this.decryptedResJson)).ResSubpackage).invoice_id)
    this.fpcyObj.ReqSubpackage.invoice_id = JSON.parse((JSON.parse(this.decryptedResJson)).ResSubpackage).invoice_id;
    this.fpcyObj.ReqSubpackage.kprq = e.checkInvoiceDate;
    this.fpcyObj.ReqSubpackage.jejym = e.checkInvoiceMoney;
    this.fpcyObj.serviceId = 'ZXTPLCYFW_DPCY';
    this.httpService.getPublicAttr(this.fpcyObj);
    console.log(this.fpcyObj);
    const fpcyObjTwo = JSON.stringify(this.fpcyObj);
    const fpcyEncryptedJson = eval('app.EncryptReqJson(fpcyObjTwo)');
    console.log(fpcyEncryptedJson);
    this.httpService.invCheck('/api/plfpcy.controller', fpcyEncryptedJson).subscribe(fpcyRes => {

      this.isSpinning = false;

      if (fpcyRes.returnCode == '00000000') {
        console.log(fpcyRes);
        console.log(222);
        const fpcyResTwo = JSON.stringify(fpcyRes);
        console.log(fpcyObjTwo);
        // this.finRemark(this.fpcyObj);
        this.fpcyDecryptedResJson = eval(("app.DecryptResJson(fpcyResTwo)"));
        console.log(this.fpcyDecryptedResJson);
        console.log(JSON.parse(this.fpcyDecryptedResJson))
        console.log(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage == '');
        if (JSON.parse(this.fpcyDecryptedResJson).ResSubpackage == '') {
          this.detailInvoice();
          console.log(666);
        } else {
          console.log(777);
          console.log(JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType)
          if (JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType) {
            if (JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType == '00' ||
              JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType == '01') {
              console.log(233);
              this.detailInvoice();
            } else {
              if (JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType == '02' ||
                JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType == '03' ||
                JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType == '04'
              ) {

                this.residueNumber();
              }
              this.resDataTypeDispose(JSON.parse(JSON.parse(this.fpcyDecryptedResJson).ResSubpackage).resultType);
            }
          } else {
            this.detailInvoice();
          }

        }
        // 验证码处理
      } else if (fpcyRes.returnCode == '00000016') {
        this.verificationCode()
      } else if (fpcyRes.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.invoiceExamination(e);
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(fpcyRes.returnCode);
      }
    });


  }

  // 发票明细
  detailInvoice() {
    // 发票查验明细
    this.fpcymxObj.ReqSubpackage.invoice_id = JSON.parse((JSON.parse(this.decryptedResJson)).ResSubpackage).invoice_id;
    this.fpcymxObj.serviceId = 'ZXTPLCYFW_FPCYMX';
    this.httpService.getPublicAttr(this.fpcymxObj);
    const fpcymxObjThree = JSON.stringify(this.fpcymxObj);
    const fpcymxEncryptedJson = eval("app.EncryptReqJson(fpcymxObjThree)");
    console.log(fpcymxEncryptedJson);
    this.httpService.invCheck('/api/plfpcy.controller', fpcymxEncryptedJson).subscribe(fpcymxRes => {
      console.log(fpcymxRes);
      if (fpcymxRes.returnCode == '00000000') {
        this.markFlag = true;
        this.finRemark(this.fpcymxObj);




        console.log(333);
        const fpcymxResThree = JSON.stringify(fpcymxRes);
        this.fpcymxDecryptedResJson = eval("app.DecryptResJson(fpcymxResThree)");
        console.log(this.fpcymxDecryptedResJson);
        var str = "碶"
        console.log(this.fpcymxDecryptedResJson.indexOf(str)); // true
        if(this.fpcymxDecryptedResJson.indexOf(str) != -1){
          this.fpcymxDecryptedResJson=this.fpcymxDecryptedResJson.replace(/碶\\/g, str)
        }
        console.log(this.fpcymxDecryptedResJson);
        this.endData = JSON.parse(JSON.parse(this.fpcymxDecryptedResJson).ResSubpackage);

        if(this.navFlag == 0){
          if (this.endData['fplx'] == '31') {
            this.checkObj.invoiceName = "电子发票（增值税专用发票）";  
          }
          if (this.endData['fplx'] == '32') {
            this.checkObj.invoiceName = "电子发票（普通发票）";
          }
      
        }else if(this.navFlag == 1){
          if (this.endData['fplx'] == '31') {
            this.telCheckObj.invoiceName = "电子发票（增值税专用发票）";  
          }
    
          if (this.endData['fplx'] == '32') {

            this.telCheckObj.invoiceName = "电子发票（普通发票）";
          }
        }else if(this.navFlag == 2){
          if (this.endData['fplx'] == '31') {
            this.pdfCheckObj.invoiceName = "电子发票（增值税专用发票）";  
          }
    
          if (this.endData['fplx'] == '32') {
            this.pdfCheckObj.invoiceName = "电子发票（普通发票）";
          }
        }else if(this.navFlag == 3){
          if (this.endData['fplx'] == '31') {
            this.handCheckObj.invoiceName = "电子发票（增值税专用发票）";  
          }
    
          if (this.endData['fplx'] == '32') {
            this.handCheckObj.invoiceName = "电子发票（普通发票）";
          }
        }
        if (this.endData["fplx"] == "S1") {
          this.checkObj.invoiceName = "深圳电子普通发票";
        }
         

  




        this.isSpinning = false;
        this.showButtonState();
        if (!this.checkDisFlag) {
          this.checkDisFlag = true;
        }
        if (!this.disFlag) {
          this.disFlag = true;
        }
        this.residueNumber();


      } else if (fpcymxRes.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.detailInvoice();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(fpcymxRes.returnCode);
      }
      if (this.endData && this.yzmFlag) {
        this.yzmFlag = false;
        this.isSpinning = false;
      }

    });
  }
  // 剩余次数
  residueNumber() {
    this.httpService.getPublicAttr(this.numObj);
    this.numObj.serviceId = 'ZXTPLCYFW_XFSQ';
    console.log(this.numObj);
    const csObj = JSON.stringify(this.numObj);
    const csEncryptedJson = eval("app.EncryptReqJson(csObj)");
    this.httpService.invCheck('/api/plfpcy.controller', csEncryptedJson).subscribe(csRes => {
      console.log(csRes);
      const csResres = JSON.stringify(csRes);
      const csDecryptedResJson = eval("app.DecryptResJson(csResres)");
      console.log(csDecryptedResJson);
      this.numData = JSON.parse(JSON.parse(csDecryptedResJson).ResSubpackage);
      console.log(this.numData);
      console.log(this.numData['authlavetime']);
      const time = this.numData['authlavetime'];
      console.log(time);
      if (time) {
        eval("app.setCheckRemain()");
      }
      // authleavetime
      //app.setCheckRemain(45)
    });
  }
  // 验证码数据处理
  verificationCode() {
    this.yzmFlag = true;
    //  this.verificationCode();
    this.yzmObj.serviceId = 'ZXTPLCYFW_HQYZM';
    this.httpService.getPublicAttr(this.yzmObj);
    this.yzmObj.ReqSubpackage.invoice_id = JSON.parse((JSON.parse(this.decryptedResJson)).ResSubpackage).invoice_id;
    console.log(this.yzmObj);
    const yzmjmObj = JSON.stringify(this.yzmObj);
    const yzmEncryptedJson = eval('app.EncryptReqJson(yzmjmObj)');
    console.log(yzmEncryptedJson)
    this.httpService.invCheck('/api/plfpcy.controller', yzmEncryptedJson).subscribe(yzmRes => {
      console.log(yzmRes);
      if (yzmRes.returnCode == '00000000') {
        const yzmResres = JSON.stringify(yzmRes);
        this.yzmDecryptedResJson = eval(("app.DecryptResJson(yzmResres)"));
        console.log(this.yzmDecryptedResJson);
        console.log(JSON.parse((JSON.parse(this.yzmDecryptedResJson)).ResSubpackage).YZM_TS);
        const img = JSON.parse((JSON.parse(this.yzmDecryptedResJson)).ResSubpackage).YZM_IMAGE;
        console.log(img)
        this.yzmImg = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + img);
        this.ifYzmObj.ReqSubpackage.key6= JSON.parse((JSON.parse(this.yzmDecryptedResJson)).ResSubpackage).key6;
        this.imgcolorYs = JSON.parse((JSON.parse(this.yzmDecryptedResJson)).ResSubpackage).YZM_TS;
        console.log(this.yamValue);
        if (this.imgcolorYs == '00') {
          this.imgColorTit = '黑色';
        } else if (this.imgcolorYs == '01') {
          this.imgColorTit = '红色';
        } else if (this.imgcolorYs == '02') {
          this.imgColorTit = '黄色';
        } else if (this.imgcolorYs == '03') {
          this.imgColorTit = '蓝色';
        } else {
          this.imgColorTit = '黑色';
        }


      } else if (yzmRes.returnCode == '00000002') {
        if (eval("app.ReLogin()")) {
          this.verificationCode();
        } else {
          this.returnCodeDispose('00000003')
        }
      } else {
        this.returnCodeDispose(yzmRes.returnCode);
      }
    })
  }
  // 验证码刷新
  yzmImgClick() {
    this.verificationCode();
  }
  // 查验按钮
  checkButClick() {
    // 011001700111
    // 1400162130
    this.dataTreating(this.checkObj);
  }
  // 手工查验按钮
  handworkCheckButClick() {
    // this.buttonItems['bgColor'] = 'gary';

    this.dataTreating(this.handCheckObj);
  }
  // 金额输入中
  jeInput() {
    console.log(this.checkObj.checkInvoiceMoney);
    this.checkObj.checkInvoiceMoney = this.checkObj.checkInvoiceMoney.replace(/[^0-9.]/g, '');
    this.checkSMBtnCanCheck(this.checkObj);
  }
  // sao/shou 回车功能
  keyUp(e) {
    this.checkSMBtnCanCheck(this.checkObj);
    this.checkObj.checkInvoiceMoney = this.checkObj.checkInvoiceMoney.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    console.log(this.checkObj.checkInvoiceType);
    if (this.checkObj.checkInvoiceType == '01' || this.checkObj.checkInvoiceType == '02' ||
      this.checkObj.checkInvoiceType == '03' || this.checkObj.checkInvoiceType == '15'|| this.checkObj.checkInvoiceType == '08'|| this.checkObj.checkInvoiceType == '09') {
      console.log('金額')
      this.checkObj.checkInvoiceMoney =
        this.checkObj.checkInvoiceMoney.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    } else if (this.checkObj.checkInvoiceType == "S1") {
    }else {
      console.log('驗證碼');
      if (this.checkObj.checkInvoiceMoney.length > 6) {
        this.checkObj.checkInvoiceMoney = this.checkObj.checkInvoiceMoney.slice(0, 6);
      }
    }
    console.log(this.checkObj.checkInvoiceMoney);
    if (e.keyCode == 13) {
      console.log('回车');
      this.checkButClick();
    }
  }
  //手工金额输入中
  jeHandInput() {
    this.handCheckObj.checkInvoiceMoney = this.handCheckObj.checkInvoiceMoney.replace(/[^0-9.]/g, '');
  }
  // 手工查验 回车功能
  keyUpHand(e) {
    this.checkSMBtnCanCheck(this.handCheckObj);
    this.handCheckObj.checkInvoiceMoney = this.handCheckObj.checkInvoiceMoney.replace(/^\s+|\s+$/g, '').replace(/[^0-9.]/g, '');
    console.log(this.handCheckObj.checkInvoiceType);
    if (this.handCheckObj.checkInvoiceType == '01' || this.handCheckObj.checkInvoiceType == '02' ||
      this.handCheckObj.checkInvoiceType == '03' || this.handCheckObj.checkInvoiceType == '15'|| this.handCheckObj.checkInvoiceType == '08' || this.handCheckObj.checkInvoiceType == '09' ) {
      console.log('金額')
      this.handCheckObj.checkInvoiceMoney =
        this.handCheckObj.checkInvoiceMoney.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    } else {
      console.log('驗證碼');
      if (this.handCheckObj.checkInvoiceMoney.length > 6) {
        this.handCheckObj.checkInvoiceMoney = this.handCheckObj.checkInvoiceMoney.slice(0, 6);
      }
    }
    if (e.keyCode == 13) {
      console.log('回车2');
      this.handworkCheckButClick();
    }
  }

  // 按钮
  showButtonState() {
    if (this.endData['sjcy'] == 1) {
      this.buttonItems[0].bgColor = 'blue';
    } else {
      this.buttonItems[0].bgColor = 'red';
    }

    if (this.endData['fpzt'] == 2) {
      this.buttonItems[1].bgColor = 'red';
      this.buttonItems[1].name = '发票作废';
    } else if (this.endData['fpzt'] == 3) {
      this.buttonItems[1].bgColor = 'red';
      this.buttonItems[1].name = '发票红冲';
    } else if (this.endData['fpzt'] == 7) {
      this.buttonItems[1].bgColor = 'red';
      this.buttonItems[1].name = '部分红冲';
    } else if (this.endData['fpzt'] == 8) {
      this.buttonItems[1].bgColor = 'red';
      this.buttonItems[1].name = '全额红冲';
    } else {
      this.buttonItems[1].bgColor = 'blue';
      this.buttonItems[1].name = '发票正常';
    }

    if (this.endData['cfcy'] == 1) {
      this.buttonItems[2].bgColor = 'yellow';
    } else {
      this.buttonItems[2].bgColor = 'blue';
    }

    if (this.endData['gfcy'] == 1) {
      this.buttonItems[3].bgColor = 'blue';
    } else {
      this.buttonItems[3].bgColor = 'line-Higth';
      this.buttonItems[3].gfcyTit = '非本单位购方名称';
    }

    if (this.endData['jbcy'] == 1) {
      this.buttonItems[4].bgColor = 'red';
    } else {
      this.buttonItems[4].bgColor = 'blue';
    }
    if (this.endData['hmdcy'] == 1) {
      this.buttonItems[5].bgColor = 'red';
    } else {
      this.buttonItems[5].bgColor = 'blue';
    }
    if (this.endData['fxqy'] == 1) {
      this.buttonItems[6].bgColor = 'yellow';
    } else {
      this.buttonItems[6].bgColor = 'blue';
    }

  }
  // 重复查验按钮
  cfcyBtn(e) {
    if (this.buttonItems[2].bgColor == 'yellow') {
      if (e.name == '重复查验') {
        this.repePopFlag = true;
        this.repeObj.serviceId = 'ZXTPLCYFW_CFCY';
        this.httpService.getPublicAttr(this.repeObj);
        console.log(this.handCheckObj);
        console.log(this.checkObj);
        if (this.handCheckObj.checkInvoiceNumber) {
          this.repeObj.ReqSubpackage.fpdm = this.handCheckObj.checkInvoiceCode;
          this.repeObj.ReqSubpackage.fphm = this.handCheckObj.checkInvoiceNumber;
        }

        if (this.checkObj.checkInvoiceNumber) {
          this.repeObj.ReqSubpackage.fpdm = this.checkObj.checkInvoiceCode;
          this.repeObj.ReqSubpackage.fphm = this.checkObj.checkInvoiceNumber;

        }

        if (this.pdfCheckObj.checkInvoiceNumber) {
          this.repeObj.ReqSubpackage.fpdm = this.pdfCheckObj.checkInvoiceCode;
          this.repeObj.ReqSubpackage.fphm = this.pdfCheckObj.checkInvoiceNumber;

        }

        if (this.telCheckObj.checkInvoiceNumber) {
          this.repeObj.ReqSubpackage.fpdm = this.telCheckObj.checkInvoiceCode;
          this.repeObj.ReqSubpackage.fphm = this.telCheckObj.checkInvoiceNumber;

        }



        console.log(this.repeObj);
        const cfcyObj = JSON.stringify(this.repeObj);
        const cfcyEncryptedJson = eval("app.EncryptReqJson(cfcyObj)");
        console.log(cfcyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', cfcyEncryptedJson).subscribe(cfcyRes => {
          console.log(cfcyRes);
          console.log(333333);
          if (cfcyRes.returnCode == '00000000') {
            console.log(6666);

            this.cfcyFlag = false;
            const cfcyResres = JSON.stringify(cfcyRes);
            this.cfcyDecryptedResJson = eval("app.DecryptResJson(cfcyResres)");
            console.log(this.cfcyDecryptedResJson);
            this.endCfcyData = JSON.parse(JSON.parse(JSON.parse(this.cfcyDecryptedResJson).ResSubpackage).cfcyList);
            console.log(this.endCfcyData);
            this.endCfcyData.map(item => {
              if (item.cyfs == '1') {
                item.cyfsZn = '客户端';
              } else if (item.cyfs == '2') {
                item.cyfsZn = '手机端';
              } else if (item.cyfs == '3') {
                item.cyfsZn = '发票查验API';
              }
            })
          } else if (cfcyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';
          }

        });
      }
    } else {
      this.repePopFlag = false;
    }
  }
  butClick(e) {
    console.log(e);
    // this.cfcyFlag = false;
    this.cfcyBtn(e);

    // 风险企业
    if (this.buttonItems[6].bgColor == 'yellow') {
      if (e.name == '风险企业') {
        this.ventureBusinessFlag = true;
        this.vbObj.serviceId = 'ZXTPLCYFW_FXQYCX';
        this.vbObj.ReqSubpackage.invoiceId = JSON.parse((JSON.parse(this.decryptedResJson)).ResSubpackage).invoice_id;
        this.httpService.getPublicAttr(this.vbObj);
        console.log(this.vbObj);
        const fxqyObj = JSON.stringify(this.vbObj);
        const fxqyEncryptedJson = eval("app.EncryptReqJson(fxqyObj)");
        console.log(fxqyEncryptedJson);
        this.httpService.invCheck('/api/plfpcy.controller', fxqyEncryptedJson).subscribe(fxqyRes => {
          console.log(fxqyRes);
          console.log(4444444);
          if (fxqyRes.returnCode == '00000000') {
            console.log(6666);
            const fxqyResres = JSON.stringify(fxqyRes);
            this.fxqyDecryptedResJson = eval("app.DecryptResJson(fxqyResres)");
            console.log(this.fxqyDecryptedResJson);
            this.endFxqyData = JSON.parse(JSON.parse(this.fxqyDecryptedResJson).ResSubpackage);
            console.log(this.endFxqyData);
          } else if (fxqyRes.returnCode == '00000001') {
            this.cfcyFlag = true;
            this.cfcyTit = '查询失败';
          }

        });
      }
    } else {
      this.ventureBusinessFlag = false;
    }
  }


  // 设备型号及购买
  unitTypeClick() {
    this.unitTypeObj.serviceId = 'ZXTPLCYFW_SBXHJGM';
    this.httpService.getPublicAttr(this.unitTypeObj);

    const sbxhObj = JSON.stringify(this.unitTypeObj);
    const sbxhEncryptedJson = eval("app.EncryptReqJson(sbxhObj)");
    console.log(sbxhEncryptedJson);
    this.httpService.invCheck('/api/plfpcy.controller', sbxhEncryptedJson).subscribe(sbxhRes => {
      console.log(sbxhRes);
      if (sbxhRes['returnCode'] == '00000000') {
        console.log(333);
        const sbxhResres = JSON.stringify(sbxhRes)
        this.sbxhDecryptedResJson = eval("app.DecryptResJson(sbxhResres)");
        console.log(this.sbxhDecryptedResJson);
        this.endSbxhData = JSON.parse(JSON.parse(this.sbxhDecryptedResJson).ResSubpackage);
        console.log(this.endSbxhData['address']);
        window.open(this.endSbxhData['address'], '_blank')

      } else if (sbxhRes['returnCode'] == '00000002') {

        if (eval("app.ReLogin()")) {
          this.unitTypeClick();
        } else {
          this.returnCodeDispose('00000003')
        }
      }

    });

  }
  customReq = (item: UploadXHRArgs) => {

    // 构建一个 FormData 对象，用于存储文件或其他参数
    this.pdfObj.nsrsbh = eval("HostApp.getHostData('nsrsbh')");
    this.pdfObj.orgid = eval("HostApp.getHostData('orgid')");
    this.pdfObj.user_name = eval("HostApp.getHostData('user_name')");
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('file', item.file as any);
    formData.append('headModel', JSON.stringify(this.pdfObj));
    console.log(item.action)
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress: true,
      withCredentials: true
    });
    // 始终返回一个 `Subscription` 对象，nz-upload 会在适当时机自动取消订阅
    return this.http.request(req).subscribe((event: HttpEvent<{}>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total > 0) {
          // tslint:disable-next-line:no-any
          (event as any).percent = event.loaded / event.total * 100;
        }
        // 处理上传进度条，必须指定 `percent` 属性来表示进度
        item.onProgress(event, item.file);
      } else if (event instanceof HttpResponse) {
        // 处理成功

        item.onSuccess(event.body, item.file, event);
        console.log(event.body);
        console.log(item.file);
        console.log(event);
        if (event.body['returnCode'] == '00000000') {
          this.pdfEndData = JSON.parse(event.body['ResSubpackage'])

          this.pdfCheckObj.checkInvoiceCode = this.pdfEndData['fpdm'];
          console.log(this.pdfEndData['fpdm']);
          this.pdfCheckObj.checkInvoiceDate = this.pdfEndData['kprq'];
          this.pdfCheckObj.checkInvoiceNumber = this.pdfEndData['fphm'];
          this.pdfCheckObj.checkInvoiceType = this.pdfEndData['fplx'];
        console.log(' this.pdfEndData :>> ',  this.pdfEndData);
          if (this.pdfEndData["fplx"] == "S1") {
            this.pdfCheckObj.checkInvoiceMoney = this.pdfEndData["je"];
            this.pdfCheckObj.jym = this.pdfEndData["jym"];
          } else {
            this.pdfCheckObj.checkInvoiceMoney = this.pdfEndData["jym"];
          }
          console.log("--------lv--------");
          console.log(this.pdfCheckObj.checkInvoiceType);
          console.log("--------lv--------");

          console.log(this.pdfCheckObj);
          // if (this.pdfCheckObj.checkInvoiceCode) {

            this.dataTreating(this.pdfCheckObj);
            console.log('pdf发票');
            // this.showButtonState();
          // }

        } else if (event.body['returnCode'] == '00000001') {
          // this.el.nativeElement.querySelector('.ant-upload-list-item-name').style.display = 'block';
          this.isVisible = true;
          this.fileListDel = [];
          this.checkInvoiceTit = '该文件非标准发票格式，无法导入';
          this.isSpinning = false;
          this.endData = {};
          this.emptyObjFn(this.pdfCheckObj);
          this.buttonItems.forEach(element => {
            element.bgColor = 'gary';
          });
        } else if (event.body['returnCode'] == '00000002') {
          if (eval("app.ReLogin()")) {

          } else {
            this.returnCodeDispose('00000003')
          }
        } else {
          this.returnCodeDispose(event.body['returnCode']);
        }



      }
    }, (err) => {
      // 处理失败
      item.onError(err, item.file);
    });
  }

  // 清除按钮
  clearButClick() {
    this.endData = {};
    this.buttonItems = [
      { name: '查验状态', bgColor: 'gary', bgImg: '', },
      { name: '发票正常', bgColor: 'gary', bgImg: '', },
      { name: '重复查验', bgColor: 'gary', bgImg: '', },
      { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
      { name: '禁报查验', bgColor: 'gary', bgImg: '', },
      { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
      { name: '风险企业', bgColor: 'gary', bgImg: '', },
    ];
    this.buttonItems.forEach(element => {
      element.bgColor = 'gary';
    });
    this.smqValue = '';
    this.smqItem = [];
    this.checkDisFlag = true;
    console.log('清除按钮');
    this.checkObj = {
      checkInvoiceCode: '',
      checkInvoiceDate: '',
      checkInvoiceNumber: '',
      checkInvoiceMoney: '',
      spanTit: '金额/校验码 ：',
      checkInvoiceType: '02',
      invoiceName: '增值税普通发票',

    };
    this.inputValue = '';//清空标注
    console.log(this.checkObj);
  }
  // 手工 清除
  handworkClearButClick() {
    this.smqValue = '';
    this.smqItem = [];
    this.buttonItems = [
      { name: '查验状态', bgColor: 'gary', bgImg: '', },
      { name: '发票正常', bgColor: 'gary', bgImg: '', },
      { name: '重复查验', bgColor: 'gary', bgImg: '', },
      { name: '购方查验', bgColor: 'gary', bgImg: '', gfcyTit: '' },
      { name: '禁报查验', bgColor: 'gary', bgImg: '', },
      { name: '黑名单查验', bgColor: 'gary', bgImg: '', },
      { name: '风险企业', bgColor: 'gary', bgImg: '', },
    ];
    this.buttonItems.forEach(element => {
      element.bgColor = 'gary';
    });
    this.endData = {};
    this.handCheckObj = {
      checkInvoiceCode: '',
      checkInvoiceDate: '',
      checkInvoiceNumber: '',
      checkInvoiceMoney: '',
      spanTit: '金额/校验码 ：',
      checkInvoiceType: '02',
      invoiceName: '增值税普通发票',

    };
    this.disFlag = true;
    this.inputValue = '';//清空标注
  }


  // 说明
  explainClick() {
    this.explainTit = eval("HostApp.getHostData('Notice')");
    //  eval("app.SetWindowConver(true)");
    console.log(this.explainTit);
    this.isVisible = true;
    this.explainFlag = true;
    this.checkInvoiceTit = this.explainTit;
  }

  // 提示框确定
  handleOk(): void {
    // eval("app.SetWindowConver(false)");
    this.isVisible = false;
    this.explainFlag = false;
  }
  // 提示框取消
  handleCancel(): void {
    this.isVisible = false;
    this.explainFlag = false;
  }
  // 重复查验弹框取消
  pophandleCancel(): void {
    this.repePopFlag = false;
    this.explainFlag = false;
  }
  // 重复查验弹框确定
  pophandleOk(): void {
    this.repePopFlag = false;
    this.explainFlag = false;
  }
  // 风险企业弹框取消
  vbhandleCancel() {
    this.ventureBusinessFlag = false;
  }
  vbhandleOk() {
    this.ventureBusinessFlag = false;
  }
  // 验证码取消
  yzmhandleCancel() {
    this.yzmFlag = false;
  }
  // 验证码确定
  yzmhandleOk() {
    //  this.yzmFlag = false;
    this.isSpinning = true;
    if (this.yamValue) {
      if (this.yzmImg) {
        console.log('有验证码且输入了验证码');
        if (JSON.parse(this.yzmDecryptedResJson).ResSubpackage) {
          // 收到验证码图片--> 请求接口 --> 验证验证码 --> 发票明细查验

          console.log(this.yamValue);
          //验证码

          this.ifYzmObj.serviceId = 'ZXTPLCYFW_TJYZM';
          this.ifYzmObj.ReqSubpackage.yzm_id = JSON.parse(JSON.parse(this.yzmDecryptedResJson).ResSubpackage).YZM_ID;
          this.ifYzmObj.ReqSubpackage.kprq =this.fpcyObj.ReqSubpackage.kprq;
          this.ifYzmObj.ReqSubpackage.jejym =this.fpcyObj.ReqSubpackage.jejym;
          this.httpService.getPublicAttr(this.ifYzmObj);
          this.ifYzmObj.ReqSubpackage.invoice_id = JSON.parse((JSON.parse(this.decryptedResJson)).ResSubpackage).invoice_id;
          // 转为base64
          const encode = encodeURI(this.yamValue);
          const base64 = btoa(encode);
          console.log(base64);
          this.ifYzmObj.ReqSubpackage.yzm_info = base64;
          const yzmxqmObj = JSON.stringify(this.ifYzmObj);
          const yzmxqEncryptedJson = eval('app.EncryptReqJson(yzmxqmObj)');
          console.log(yzmxqEncryptedJson)
          this.httpService.invCheck('/api/plfpcy.controller', yzmxqEncryptedJson).subscribe(yzmxqRes => {
            console.log(yzmxqRes);
            if (yzmxqRes.returnCode == '00000000') {
              const yzmmxResres = JSON.stringify(yzmxqRes);
              this.yzmmxDecryptedResJson = eval(("app.DecryptResJson(yzmmxResres)"));
              console.log(this.yzmmxDecryptedResJson);
              if (JSON.parse(JSON.parse(this.yzmmxDecryptedResJson).ResSubpackage).resultType) {
                if (JSON.parse(JSON.parse(this.yzmmxDecryptedResJson).ResSubpackage).resultType == '00' ||
                  JSON.parse(JSON.parse(this.yzmmxDecryptedResJson).ResSubpackage).resultType == '01') {
                  this.detailInvoice();
                } else {
                  this.resDataTypeDispose(JSON.parse(JSON.parse(this.yzmmxDecryptedResJson).ResSubpackage).resultType);
                }
              }
            } else if (yzmxqRes.returnCode == '00000002') {
              if (eval("app.ReLogin()")) {
                this.yzmhandleOk();
              } else {
                this.returnCodeDispose('00000003')
              }
            } else {
              this.returnCodeDispose(yzmxqRes.returnCode);
            }
          })

        }
      }
    } else {
      this.isVisible = true;
      this.explainFlag = false;
      this.checkInvoiceTit = '请输入验证码';
    }




  }
  // 悬停
  hoverTip() {
    this.tipFlag = true;
  }
  outTip() {
    this.tipFlag = false;
  }

  markChange(e) {
    this.updateTime = '';
    this.inputValue = this.inputValue.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im, '').replace(/[, ]/g, '');
  }
  blurMark() {
    console.log(this.obj)
    //^[\u4E00-\u9FA5A-Za-z0-9]+$ 或 ^[\u4E00-\u9FA5A-Za-z0-9]{2,20}$
    // 中文、英文、数字但不包括下划线等符号
    let object = {
      "nsrsbh": this.obj['nsrsbh'],
      "user_name": this.obj['user_name'],
      "orgid": this.obj['orgid'],
      "ReqSubpackageModel": {
        "remark": this.inputValue ? this.inputValue : '',
        "id": this.obj['ReqSubpackage']['info_id'],
        "invoiceId": this.obj['ReqSubpackage']['invoice_id'],
        "fpdm": this.obj['ReqSubpackage']['fpdm'],//021021900104
        "fphm": this.obj['ReqSubpackage']['fphm'],//00171727
        "jejym": this.obj['ReqSubpackage']['jym'],//846624
      },
      "nsrmc": this.obj['nsrmc'],
      "areaCode": this.obj['areaCode'],
    }

    if (this.markFlag) {
      const data = new FormData();
      data.append('headModel', JSON.stringify(object));
      this.httpService.markSet('/api/updateInvoiceRecord', data).subscribe(yzmxqRes => {
        console.log(yzmxqRes)
        this.finRemark(this.fpcymxObj);
      })
    }


  }

  finRemark(e) {
    console.log(e)
    let object = {
      "nsrsbh": e['nsrsbh'],
      "user_name": e['user_name'],
      "orgid": e['orgid'],
      "ReqSubpackageModel": {
        "fpdm": this.obj.ReqSubpackage.fpdm,
        "fphm": this.obj.ReqSubpackage.fphm,
      },
      "nsrmc": e['nsrmc'],
      "areaCode": e['areaCode'],
    }

    const data = new FormData();
    data.append('headModel', JSON.stringify(object));
    this.httpService.markSet('/api/selectInvoiceRecord', data).subscribe(yzmxqRes => {
      let obj = JSON.parse(yzmxqRes.ResSubpackage);
      this.inputValue = obj['remark'];
      if (obj['updateDate']) {
        this.updateTime = this.dateFormatFun(obj['updateDate'], 'yyyy-MM-dd hh:mm');
      }

    })
  }

  dateFormatFun(timestamp, format) {
    let date = timestamp instanceof Date ? timestamp : new Date(timestamp);
    const o = {
      "y{4}": date.getFullYear(),
      "MM": date.getMonth() + 1,
      "dd": date.getDate(),
      "hh": date.getHours(),
      "mm": date.getMinutes(),
      "ss": date.getSeconds()
    };
    for (let i in o) {
      if (new RegExp('(' + i + ')').test(format)) {
        format = format.replace(RegExp.$1, o[i] < 10 ? '0' + o[i] : o[i]);
      }
    }
    return format;
  };


  //扫描
  checkSMBtnCanCheck(e: any) {
    console.log(' checkSMBtnCanCheck e :>> ', e);
    if (e.checkInvoiceNumber.length > 0 && e.checkInvoiceMoney.length > 0) {
      if (
        (e.checkInvoiceNumber.length == 8 && (e.checkInvoiceCode.length == 10 || e.checkInvoiceCode.length == 12)) ||
        (e.checkInvoiceNumber.length == 20 && (e.checkInvoiceCode.length == 0 || !e.checkInvoiceCode))
      ) {
        if (e.checkInvoiceType == "S1") {
          if (e.jym) {
            this.checkDisFlag = false;
          } else {
            this.checkDisFlag = true;
          }
        } else {
          this.checkDisFlag = false;
        }
      } else {
        this.checkDisFlag = true;
      }
    } else {
      this.checkDisFlag = true;
    }

    console.log('this.checkDisFlag :>> ', this.checkDisFlag);
  }




}
