import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Buffer } from 'buffer';
@Component({
  selector: 'app-invoice-type',
  templateUrl: './invoice-type.component.html',
  styleUrls: ['./invoice-type.component.css']
})
export class InvoiceTypeComponent implements OnInit {
  endData: {};
  endYear: '';
  endMonth: '';
  endDay: '';
  endListData: [];
  endList: {};
  listData: {};
  constructor() { }
  @Input() objOne;
  @Input() objTwo;

  ngOnInit() {
    this.endData = this.objTwo;
    console.log(this.endData);

  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.objTwo.currentValue['fpdm']) {
      this.endData = changes.objTwo.currentValue;
      this.endYear = this.endData['kprq'].substring(0, 4);
      this.endMonth = this.endData['kprq'].substring(5, 7);
      this.endDay = this.endData['kprq'].substring(8, 10);
      if (this.endData['fplx'] == '04') {
        this.endListData = this.endData['ppqdList'];
      }
      if (this.endData['fplx'] == '01') {
        this.endListData = this.endData['zpList'];
      }
      if (this.endData['fplx'] == '10' || this.endData['fplx'] == 'S1') {
        this.endListData = this.endData['dzspList'];
      }
      if (this.endData['fplx'] == '14') {
        this.endListData = this.endData['txfList'];
      }
      if (this.endData['fplx'] == '08') {
        this.endListData = this.endData['zzsdzfpqdList'];
      }
      if (this.endData['fplx'] == '11') {
        this.endListData = this.endData['jpList'];
      }
      if (this.endData['fplx'] == '31') {
        this.endListData = this.endData['qdzpfpqdList'];
      }

      if (this.endData['fplx'] == '32') {
        this.endListData = this.endData['qdppfpqdList'];
      }


      console.log(this.endListData);

      // 专票：zpList
      // 普票：ppqdList
      // 通行费 txfList
      // 电子普票 dzspList
    } else {
      this.endData = {};
      this.endListData = [];
      this.endList = {};
      this.endYear = '';
      this.endMonth = '';
      this.endDay = '';
    }
  }
  // 清单 url
  detailListClick() {
    const headUrl = eval("HostApp.getHostData('serviceUrl')");
    console.log(headUrl);
    const ind = headUrl.lastIndexOf('/');
    console.log(ind);
    const url = headUrl.substring(0, ind - 3);
    console.log(url);
    const footerUrl = new Buffer(this.endData['url'], 'base64').toString();
    console.log(footerUrl);
    console.log(url + footerUrl);
    window.open(url + footerUrl, '_blank');

  }
}
